<template>
  <div class="globalContainer">
    <!--  <router-view name="HomePage" /> -->
    <!--    <router-view name="InventaryNavBar" /> -->

    <div class="viewLoader">
      <!--     <router-view /> -->

 
      <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition || 'fade'" mode="out-in">
           
            <component :is="Component"  @refresh="sync" />
        
        </transition>
      </router-view>

      
    </div>
    <div class="toolBar">
      <div class="loadingInfo" v-if="Synchronizing"><LoadSpin  :color="'#fff'" :size="'15px'" /> <span> Sincronizando... </span></div>
        <div class="syncInfo" v-if="!Synchronizing">
          <font-awesome-icon :icon="['fa', 'cloud']" @click="sync" /><small>  Todo al día. Ultima sincronización:  {{lastSync}}</small>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isHome: false,
      Synchronizing:false,
      date: new Date(),
      lastSync:""
    };
  },
  methods: {
    async sync() {
        this.lastSync = new Date()
        localStorage.setItem("lastSync", this.lastSync)
        localStorage.setItem("refresh",true)
        this.Synchronizing = true

        await this.refreshInfo()
        this.Synchronizing = false
      
      

    }
  },
  mounted() {
    this.lastSync = localStorage.getItem("lastSync");
    
  }



};
</script>

 
<style src="./css/transitions.css"></style>
<style src="./css/style.css"></style>
 

 