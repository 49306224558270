<template>
    <div class="startConfig">
        <h2>Información del negocio</h2>
        <div class="form">
            <form onsubmit='return false' class='form'>
                <div class="form__cols l-ll">
                    <div class="form__control">
                        <label for="">Nombre del negocio</label>
                        <div class="inputAddons">
                            <span>
                                <font-awesome-icon :icon="['fas', 'signature']" />
                            </span>
                            <input type="text" v-model="name" placeholder="..." class="form__input" />
                        </div>
                    </div>

                    <div class="form__control">
                        <label for="">Descripción</label>
                        <div class="inputAddons">
                            <span>
                                <font-awesome-icon :icon="['fas', 'signature']" />
                            </span>
                            <input type="text" v-model="description" placeholder="..." class="form__input" />
                        </div>
                    </div>

                </div>

                <div class="form__cols l-ll">

                    <div class="form__control">
                        <label for="">Ubicación</label>
                        <div class="inputAddons">
                            <span>
                                <font-awesome-icon :icon="['fas', 'location-dot']" />
                            </span>
                            <input type="text" v-model="location" placeholder="..." class="form__input" />
                        </div>
                    </div>
                </div>


                <div class="form__cols l-l-l-l">
                    <div class="form__control">
                        <label for="">Tipo de negocio</label>
                        <div class="inputAddons">
                            <span>
                                <font-awesome-icon :icon="['fa', 'building']" />
                            </span>
                            <select v-model="businessType">
                                <option disabled value="">Elija una opción</option>
                                <option value="1">Restaurante</option>
                                <option value="2">Tienda de articulos</option>
                                <!--  <option value="3">Saco</option>
                                <option value="4">Caja</option> -->
                            </select>
                        </div>
                    </div>

                    <div class="form__control">
                        <label for="">Contacto</label>
                        <div class="inputAddons">
                            <span>
                                <font-awesome-icon :icon="['fas', 'phone']" />
                            </span>
                            <input type="text" v-model="contact" placeholder="..." class="form__input" />
                        </div>
                    </div>


                </div>
                <h3>Horario</h3>
                <div class="form__cols  ">

                    <div class="weekSelectorContainer">
                        <div class="weekSelect">
                            <input type="checkbox" data-index="0" @change="changeSchedule" :checked="schedule[0].open"
                                :value="schedule[0].value">
                            <label for="">{{ schedule[0].value }}</label>
                        </div>
                        <div class="weekSelect">
                            <input type="checkbox" data-index="1" @change="changeSchedule" :checked="schedule[1].open"
                                :value="schedule[1].value">
                            <label for="">{{ schedule[1].value }}</label>
                        </div>
                        <div class="weekSelect">
                            <input type="checkbox" data-index="2" @change="changeSchedule" :checked="schedule[2].open"
                                :value="schedule[2].value">
                            <label for="">{{ schedule[2].value }}</label>
                        </div>
                        <div class="weekSelect">
                            <input type="checkbox" data-index="3" @change="changeSchedule" :checked="schedule[3].open"
                                :value="schedule[3].value">
                            <label for="">{{ schedule[3].value }}</label>
                        </div>
                        <div class="weekSelect">
                            <input type="checkbox" data-index="4" @change="changeSchedule" :checked="schedule[4].open"
                                :value="schedule[4].value">
                            <label for="">{{ schedule[4].value }}</label>
                        </div>
                        <div class="weekSelect">
                            <input type="checkbox" data-index="5" @change="changeSchedule" :checked="schedule[5].open"
                                :value="schedule[5].value">
                            <label for="">{{ schedule[5].value }}</label>
                        </div>
                        <div class="weekSelect">
                            <input type="checkbox" data-index="6" @change="changeSchedule" :checked="schedule[6].open"
                                :value="schedule[6].value">
                            <label for="">{{ schedule[6].value }}</label>
                        </div>
                    </div>

                </div>
                <div class="form__cols l-ll">

                    <div class="form__control">
                        <label for="">Abierto desde:</label>
                        <div class="inputAddons">
                            <span>
                                <font-awesome-icon :icon="['fa', 'clock']" />
                            </span>
                            <DatePicker locale="do" placeholder="00:00" time-picker disable-time-range-validation
                                :is24="false" :enable-time-picker="true" v-model="opens" />
                        </div>
                    </div>

                    <div class="form__control">
                        <label for="">Hasta</label>
                        <div class="inputAddons">
                            <span>
                                <font-awesome-icon :icon="['fa', 'clock']" />
                            </span>
                            <DatePicker locale="do" placeholder="00:00" time-picker disable-time-range-validation
                                :is24="false" :enable-time-picker="true" v-model="closes" />
                        </div>
                    </div>

                </div>
                <div class="controls">
                    <button :disabled="saving" class="btn btn-outline-blue " @click="addBusinessInfo">
                        <LoadSpin v-if="saving" :color="'blue'" :size="'30px'" />
                        <span>{{ saving ? "Guardando..." : "Guardar" }}</span>
                    </button>

                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
    name: 'StartConfig',
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    data() {
        return {
            name: "",
            description: "",
            businessType: "",
            location: "",
            contact: "",
            opens: "",
            closes: "",
            schedule: {
                0: {
                    value: "lunes",
                    open: true
                },
                1: {
                    value: "martes",
                    open: true
                },
                2: {
                    value: "miercoles",
                    open: true
                },
                3: {
                    value: "jueves",
                    open: true
                },
                4: {
                    value: "viernes",
                    open: true
                },
                5: {
                    value: "sábado",
                    open: true
                },
                6: {
                    value: "domingo",
                    open: false
                },
            },
            saving: false,
        }
    },
    methods: {
        changeSchedule(e) {
            var index = e.target.dataset.index
            this.schedule[index].open = e.target.checked ? true : false

        },
        addBusinessInfo() {
            this.saving = true
            this.http.post(`${this.url}/addBusinessInfo`, {
                params: {
                    name: this.name,
                    description: this.description,
                    type: this.businessType,
                    location: this.location,
                    contact: this.contact,
                    opens: JSON.stringify(this.opens),
                    closes: JSON.stringify(this.closes),
                    schedule: JSON.stringify(this.schedule),
                }
            }).then((res) => {
                this.saving = false
                if (res.data.status == true) {
                    this.$router.push({ name: 'Home' })
                    this.toast.success(
                        "La información ha sido guardada",
                        this.toastOptions
                    );
                }
                //console.log(res.data);
            })
        },
        checkBusinessInfo() {
            this.http.get(`${this.url}/checkBusinessInfo`, ).then((res) => {
               
                if(res.data != false)
                {
                   // this.$router.push({ name: 'Home' })
                }
             
            })
        }

    },
    mounted() {
        this. checkBusinessInfo()

    },updated(){
        this. checkBusinessInfo()
    }
}
</script>

 