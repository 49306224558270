import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
    faUserSecret,
    faTableCellsLarge,
    faCalendarDays,
    faBoxesStacked,
    faReceipt,
    faSackDollar,
    faArrowLeftLong,
    faPlus,
    faEnvelope,
    faSignature,
    faMobile,
    faLocationDot,
    faBuilding,
    faBarcode,
    faBoxesPacking,
    faTag,
    faTags,
    faPerson,
    faBox,
    faSquarePlus,
    faArrowLeft,
    faFloppyDisk,
    faList,
    faWeightScale,
    faGaugeHigh,
    faCircleExclamation,
    faDollarSign,
    faArrowDownShortWide,
    faCaretDown,
    faCaretUp,
    faTruckRampBox,
    faClock,
    faBurst,
    faStar,
    faArrowRight,
    faCalendarDay,
    faSquareMinus,
    faPercent,
    faRightLeft,
    faFilter,
    faMagnifyingGlass,
    faPrint,

    faRotateLeft,
    faICursor,

    faPhone,
    faAddressCard,
    faKeyboard,
    faImage,
    faTrashCan,
    faQuestion,
    faStarOfLife,
    faEllipsisVertical,
    faCheck,
    faUpload,
    faCloud



} from "@fortawesome/free-solid-svg-icons";

library.add(
    faUserSecret,
    faTableCellsLarge,
    faCalendarDays,
    faBoxesStacked,
    faReceipt,
    faSackDollar,
    faArrowLeftLong,
    faPlus, faEnvelope,
    faSignature,
    faMobile,
    faLocationDot,
    faBuilding,
    faBarcode, faBoxesPacking,
    faTag,
    faTags,
    faPerson,
    faBox,
    faSquarePlus,
    faArrowLeft,
    faFloppyDisk,
    faList, faWeightScale,
    faGaugeHigh,
    faCircleExclamation,
    faDollarSign,
    faArrowDownShortWide,
    faCaretDown,
    faCaretUp,
    faTruckRampBox,
    faClock,
    faBurst,
    faStar,
    faArrowRight,
    faCalendarDay,
    faSquareMinus,
    faPercent,
    faRightLeft,
    faFilter,
    faMagnifyingGlass,
    faPrint,
    faRotateLeft,
    faICursor,
    faPhone,
    faAddressCard,
    faKeyboard,
    faImage,
    faTrashCan,
    faQuestion, faStarOfLife,
    faEllipsisVertical,
    faCheck,
    faUpload,
    faCloud




);


export { FontAwesomeIcon }