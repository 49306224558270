import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)
import SupplierSelect from './components/supliers/SuppliersSelect.vue' //This is the component used to get suppliers list
import { router } from "./router.js"
import "vue-toastification/dist/index.css";
import VueSweetalert2 from 'vue-sweetalert2'; /* Alertas */
import 'sweetalert2/dist/sweetalert2.min.css';
const axios = require('axios').default;
import { //Datatables cols config
    dtOptions,
    checkoutOpt,
    suppliersCols,
    inventaryCol,
    specialsCols,
    lowStock,
    checkoutCols,
    invoices,
    clients,
    returns,
    DataTable
} from "./datatable.conf"
import { FontAwesomeIcon } from "./Icons.conf" //All icons configs
import { Toast, toastConfig, toastOptions } from "./Toast.conf" //All icons configs

import VueDatePicker from '@vuepic/vue-datepicker';
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import overLay from "./components/overlay.vue"
import '@vuepic/vue-datepicker/dist/main.css'
import { encode, decode } from 'js-base64';
import { gsap } from "gsap";
import mitt from "mitt"
import VueApexCharts from "vue3-apexcharts";


import { options, sparkOptions } from "./chart.conf"
import { session, Cookies } from './session'
import { uploadFiles, loadFiles } from './firebase-storage'
import { checkNetwork } from './checkNetwork'
import './sw.js'
import { apiUri, staticscUri } from "./config"
import { refreshInfo } from "./refresh"


var domain = "http://localhost:8080";
//axios.defaults.headers.common['Authorization'] = Cookies.get("dspuid");
session();
checkNetwork()
    /* setInterval(()=>{
        refreshInfo()

    },100) */

/* Se usa app.config.globalProperties.[propiedad] para declarar una propiedad de forma global.
   En este bloque están declaradas todas las propiedades globales
   que se estarán utlizando en distintos componentes.*/

app.config.globalProperties.http = axios
app.config.globalProperties.url = apiUri;
app.config.globalProperties.staticUrl = staticscUri;
app.config.globalProperties.refreshInfo = refreshInfo;
app.config.globalProperties.domain = domain;
app.config.globalProperties.encode = encode
app.config.globalProperties.decode = decode
app.config.globalProperties.dtOptions = dtOptions; //Datatable options
app.config.globalProperties.checkoutOpt = checkoutOpt; //Datatable options
app.config.globalProperties.dtCols = inventaryCol //Datatable columns from inventary
app.config.globalProperties.dtColsSuppliers = suppliersCols //Datatable columns from suppliers table
app.config.globalProperties.specialsCols = specialsCols //Datatable columns from inspecials table
app.config.globalProperties.lowStock = lowStock //Datatable columns from inspecials table
app.config.globalProperties.dtInvoices = invoices //Datatable columns from bills table
app.config.globalProperties.checkoutCols = checkoutCols //Datatable columns from checkout table
app.config.globalProperties.clientsCols = clients //Datatable columns from clients table
app.config.globalProperties.returnCols = returns //Datatable columns from clients table
app.config.globalProperties.toastOptions = toastOptions;
app.config.globalProperties.animation = gsap;
app.config.globalProperties.emitter = mitt();
app.config.globalProperties.uploadFiles = uploadFiles;
app.config.globalProperties.loadFiles = loadFiles;
app.config.globalProperties.sendMail = async(mail, recipient, subj, body) => {

    try {
        return axios.post(`${apiUri}/sendMail`, {
            params: {

                email: mail,
                name: recipient,
                subj: subj,
                body: body,

            },
        })



    } catch (Err) {
        console.log(Err);
    }

};
app.config.globalProperties.dateFormater = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}
app.config.globalProperties.Cookies = Cookies

app.config.globalProperties.currency = (values) => {
    return new Intl.NumberFormat('es-DO', { style: 'currency', currency: 'DOP' }).format(values)
};
app.config.globalProperties.chartOptions = options;
app.config.globalProperties.chartSparkOptions = sparkOptions;

app.component('FontAwesomeIcon', FontAwesomeIcon)
app.component("DataTable", DataTable)
app.component("DatePicker", VueDatePicker)

app.use(VueSweetalert2);
app.component("SupplierSelect", SupplierSelect)
app.component("LoadSpin", MoonLoader)
app.component("OverLay", overLay)
app.use(Toast, toastConfig);

app.use(router)
app.use(VueApexCharts);

app.mount('#app');