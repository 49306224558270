<template>
  <div class="RegisterNew">
    <div class="modalNav">
      <h1>{{ title }} especial</h1>

      <div class="modalControl">
        <button class="btn bg-red-light" @click="deleteSpecial">Eliminar especial</button>

        <button class="btn btn-outline-blue" @click="this.$router.push({ name: 'Especials' })">
          <font-awesome-icon :icon="['fas', 'arrow-left-long']" /> Regresar
        </button>

      </div>
    </div>
    <h3>{{ prodName }}</h3>
    <form class="form" @submit.prevent="onSubmit">
      <div class="form__cols l-ll">
        <div class="form__control">
          <label for="">Código del producto</label>
          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fas', 'barcode']" />
            </span>
            <input @keyup.space="getProduct(false)" type="text" v-model="barcode" placeholder="..." class="form__input" />
          </div>
        </div>
        <div class="form__control">
          <label for="">Nombre del Producto</label>
          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fas', 'boxes-packing']" />
            </span>
            <input type="text" v-model="prodName" placeholder="..." class="form__input" disabled />
          </div>
        </div>
      </div>

      <!-- Para elegir el tipo de oferta y  el descuento -->
      <div class="form__cols l-l-l">
        <div class="form__control l-ll">
          <label for="">Seleccione el tipo de oferta</label>
          <div class="inputAddons">
            <select v-model="specialType">
              <option value="0" disabled>Elija el tipo de oferta</option>
              <option value="1">Descuento</option>
              <option value="2">Precio por x unidades</option>
              <option value="3">Descuento por x unidades</option>
            </select>
          </div>
        </div>

        <div v-if="specialType == 1 || specialType == 3" class="form__control">
          <label for="">Descuento a aplicar</label>
          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fa', 'tag']" />
            </span>
            <input type="number" v-model="discount" placeholder="% " class="form__input" />
          </div>
        </div>

        <div class="form__control">
          <label for="">Precio de venta</label>
          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fa', 'tag']" />
            </span>
            <input type="number" v-model="sellPrice" placeholder="RD $" class="form__input" />
          </div>
        </div>
      </div>
      <!-- Para elegir el precio de oferte -->
      <div class="form__cols l-l-l">

        <div v-if="specialType == 2 || specialType == 3" class="form__control">
          <label for="">Compra mínima</label>
          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fa', 'tag']" />
            </span>
            <input type="number" v-model="minBuy" placeholder="---" class="form__input" />
          </div>
        </div>
        <div v-if="specialType == '2'" class="form__control">
          <label for="">Precio de oferta</label>
          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fa', 'tag']" />
            </span>
            <input type="number" v-model="offerPrice" placeholder="RD $" class="form__input" />
          </div>
        </div>
      </div>

      <div class="form__cols l-l-l">
        <!-- Fechas -->
        <div class="form__control">
          <label for="">Desde</label>
          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fas', 'calendar-days']" />
            </span>

            <DatePicker placeholder="dd-mm-yyyy" :enable-time-picker="false" v-model="date_from" />
          </div>
        </div>

        <div class="form__control">
          <label for="">Hasta</label>
          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fas', 'calendar-days']" />
            </span>

            <DatePicker placeholder="dd-mm-yyyy" :enable-time-picker="false" v-model="date_until" />
          </div>
        </div>
      </div>

      <button class="btn btn-blue" @click="save">Guardar</button>
    </form>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
  name: "newProduct",
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  data() {
    return {
      title: "Crear",
      barcode: "",
      prodName: "",
      sellPrice: "",
      minBuy: "",
      discount: 0,
      offerPrice: 0,
      specialType: "0",
      date_from: "",
      date_until: "",
      prod_id: "",
      offerId: "",
    };
  },

  methods: {
    getProduct(id) {
      var item = id ? id : this.barcode;
      //console.log(item);
      this.http
        .get(`${this.url}/getProductInfo/${item}`)

        .then((res) => {
          if (res.data != false) {
            this.prodName = res.data.name;
            this.sellPrice = res.data.sellPrice;
            this.prod_id = res.data.id;
            this.barcode = res.data.barcode;

          } else {
            this.toast.info(
              "Este producto no existe, revisa el código de barras",
              this.toastOptions
            );
          }
        });
    },

    getSpecialInfo(id) {
      this.http
        .get(`${this.url}/getSpecialInfo/${id}`)

        .then((res) => {
          //   console.log(res);
          if (res.data != false) {
            this.date_from = res.data.date_from;
            this.date_until = res.data.date_until;
            this.minBuy = res.data.buycant;
            this.offerPrice = res.data.newPrice;
            this.prod_id = res.data.prod_id;
            this.discount = res.data.discount;
            this.specialType = res.data.specialType;
            this.getProduct(this.prod_id);
          } else {
            this.toast.info(
              "Este producto no existe, revisa el código de barras",
              this.toastOptions
            );
          }
        });
    },

    createSpecial() {
      //se pone un producto en especial
      // console.log(this.prod_id);
      this.http
        .post(`${this.url}/createSpecial`, {
          params: {
            minBuy: this.minBuy,
            offerPrice: this.offerPrice ? this.offerPrice : this.sellPrice,
            regPrice: this.sellPrice,
            specialType: this.specialType,
            discount: this.discount,
            date_from: this.date_from,
            date_until: this.date_until,
            prod_id: this.prod_id,
          },
        })

        .then((res) => {
          console.log(res.data);
          if (res.data.status == true) {
            this.toast.success(
              "El producto ha sido puesto en oferta",
              this.toastOptions
            );
            this.$router.push("/inventary/specials");
          }

          if (res.data.error[1] == 1062) {
            this.toast.info(
              "Este producto ya está en oferta",
              this.toastOptions
            );
          }
        });
        localStorage.setItem("refresh", true)
    },

    saveChanges() {
      //Se guardan los cambios realizados a un producto puesto en especial
      this.http
        .post(`${this.url}/editSpecial`, {
          params: {
            minBuy: this.minBuy,
            offerPrice: this.offerPrice,
            date_from: this.date_from,
            date_until: this.date_until,
            prod_id: this.prod_id,
            id: this.offerId,
          },
        })

        .then((res) => {
          //console.log(res.data);
          this.$emit("refresh")
          if (res.data.status == true) {
            this.toast.success("La oferta ha sido editada", this.toastOptions);
            this.$router.push("/inventary/specials");
          }

          if (res.data.error[1] == 1062) {
            this.toast.info(
              "Este producto ya está en oferta",
              this.toastOptions
            );
          }
        });
        localStorage.setItem("refresh", true)
    },

    save() {
      if (!this.offerId) {
        this.createSpecial();
      } else {
        this.saveChanges();
      }
    },
    deleteSpecial() {

      this.$swal({
        title: "Está a punto de eliminar esta oferta",
        text: "¿Desea continuar?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.http
            .get(`${this.url}/deleteSpecial/${this.offerId}`,{
          headers:{
              'Refresh-state':'true'
          }

        })
            .then((res) => {
              //console.log(res.data);
              if (res.data.status == true) {
                this.toast.success("La oferta fue eliminada", this.toastOptions);
                this.$router.push({ name: 'Especials' });
              }


            });
        }
        this.$emit("refresh")
        localStorage.setItem("refresh", true)
      });

    }
  }, //methods end

  beforeMount() {
    //Extrae un producto que está puesto en especial
    this.offerId = this.$router.currentRoute._value.params.id;
    if (this.offerId) {
      this.title = "Editar";
      this.getSpecialInfo(this.offerId);
    }

    //this.getSpecialInfo()
  },
};
</script>

 
 