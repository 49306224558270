<template>
    <div class="startSystem">

        <div class="startInfo">
            <div class="starting__img">
                <img src="../icons/logo.png" alt="">
            </div>

            <div class="loadingLabel">Iniciando el sistema...</div>
       <!--      <small>{{ inventary }} <font-awesome-icon v-if="inventaryLoaded" :icon="['fa', 'check']" /></small>
            <small>{{ clients }} <font-awesome-icon v-if="clientsLoaded" :icon="['fa', 'check']" /></small>
            <small>{{ invoices }} <font-awesome-icon v-if="invoicesLoaded" :icon="['fa', 'check']" /></small> -->

            <div class="spinnerContainer">
                <LoadSpin  v-if="loaded" :color="'#0c7eca'" :size="'50px'" />
            </div>
 

        </div>
    </div>
</template>

<script>
export default {
    name: "StartSystem",
    data() {
        return {
            inventary: "Cargando inventario",
            clients: "Cargando clientes",
            invoices: "Cargando facturas",
            inventaryLoaded: false,
            clientsLoaded: false,
            invoicesLoaded: false,
            loaded:true,
            loadText: ""
        }
    },
    methods: {
       
    },

   async mounted() {
       // this.loadInventaryInfo()
  
       if( await this.refreshInfo() == true){
        this.loaded = false
       }
      /*  this.emitter.on("inventaryLoaded", this.inventaryLoaded = true)
       this.emitter.on("clientsLoaded",this.clientsLoaded = true) */
       //this.emitter.on("invoicesLoaded",this.invoicesLoaded = true)
    }
}
</script>

<style>
.startSystem {
    position: relative;
    height: 100vh;
}

.startInfo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.spinnerContainer {
    width: 15%;
}

.starting__img img {
    display: block;
    width: 100%;
    margin: auto;
}

small {
    margin-bottom: 15px;
}

.loadingLabel {
    font-size: 20px;
    text-align: center;
    padding: 20px;
    padding-bottom: 10px;
}

.starting__img {
    width: 20%;
    margin: auto;

}
</style>