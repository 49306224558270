import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { initializeApp } from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyCZmbDQUDk2yvMNEj7P4lEqgQz2Fu34qBc",
    authDomain: "dotsell-pos-v2.firebaseapp.com",
    projectId: "dotsell-pos-v2",
    storageBucket: "dotsell-pos-v2.appspot.com",
    messagingSenderId: "944122962468",
    appId: "1:944122962468:web:8030cb69e20f379eeb6eb1",
    measurementId: "G-CH5GJ509Z6"
}




const uploadFiles = (file) => {

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);


    // Initialize Cloud Storage and get a reference to the service
    const storage = getStorage(app);
    const storageRef = ref(storage, `img/${file.name}`)
    //const spaceRef = ref(storage, `img/${file.name}`)

    return uploadBytes(storageRef, file).then((e) => {
        //console.log(e);
        return e
    })
}


function loadFiles(img) {
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    return getDownloadURL(ref(storage, `img/${img}`))
}



export { loadFiles, uploadFiles } 