<template>
  <div class="modal">
    <OverLay />

    <div class="paymethod cashBalancing__container">
      <button @click="this.$router.push({ name: 'Checkout' })" :disabled="this.processing" class="close btn">
        Cerrar
      </button>
      <h2>Cuadre de caja</h2>
      <div class="cashBalancing">
        <p>Información de efectivo</p>
        <div class="sold__info">

          <div class="info__block">
            <div class="info">
              <span>Efectivo inicial</span>
              <span>{{ this.currency(startCash) }}</span>
            </div>
            <div class="info">
              <span>Efectivo actual</span>
              <span>{{ this.currency(currentCash ) }}</span>
            </div>
            <div class="info">
              <span>Balance Total</span>
              <span>{{ this.currency(total) }}</span>
            </div>
          </div>
          <p>Transacciones</p>

          <div class="info__block">
            <div class="info">
              <span title="Efectivo"  >Efectivo </span>
              <span>{{ this.currency(cash) }}</span>
            </div>
            <div class="info">
              <span>Transferencias</span>
              <span>{{ this.currency(transfers) }}</span>
            </div>
            <div class="info">
              <span>Tarjetas</span>
              <span>{{ this.currency(cards) }}</span>
            </div>
          </div>

        </div>

        <div class="cashBalancing_controls">
          <button class="btn bg-blue-light process" :disabled="this.processing" @click="closeCheckout">
            {{ !processing ? "Cerrar caja" : "Cerrando caja..." }}
            <LoadSpin v-if="processing" :color="'#FFF'" :size="'30px'" />
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import OverLay from "../overlay";
export default {
  name: "PayMethod",
  components: {
    OverLay,
  },
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  data() {
    return {
      processing: false,
      startCash: 0,
      total: 0,
      recived: 0,
      refound: 0,
      currentCash:0,
      cash: 0,
      cards: 0,
      transfers: 0,
      startInfoId: "",

    };
  },
  methods: {
    cashBalancing() {
      ///this.processing = true;
      this.http
        .get(`${this.url}/cashBalancing`)
        .then((res) => {

         // console.log(res.data);
          this.startCash = res.data.startInfo.startCash
          this.startInfoId = res.data.startInfo.id
          this.total = res.data.cashBalancing.total
          this.recived = res.data.cashBalancing.recived
          this.refound = res.data.cashBalancing.refounds

          /* Current cash */

          this.currentCash = eval(( this.recived  - this.refound) + parseFloat(this.startCash)) 
          


          this.cash = res.data.cashBalancing.cash
          this.cards = res.data.cashBalancing.cards
          this.transfers = res.data.cashBalancing.transfers
          /* if (res.status) {
            this.$router.push({ name: "Checkout" });
            localStorage.setItem("chkSt", true);
          } */
        });
    },
    closeCheckout() {

      this.$swal
        .fire({
          title: "Desea continuar?",
          text: `La información de cuadre se reiniciará después de esta acción`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          cancelButtonText: "No",
          confirmButtonText: "Cerrar caja",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.processing = true;

            this.http
              .post(`${this.url}/closeCheckout`, {
                params: {
                  id: this.startInfoId
                }
              })

              .then((res) => {
                if (res.data.status == true) {
                  this.$router.push({ name: "Home" })
                }

              });
          }
        });


    },
  },
  mounted() {
    this.cashBalancing()
  }
};
</script>

 