<template>
    <div class="startSystem">

        <div class="startInfo">
            <div class="starting__img">
                <img src="../icons/logo.png" alt="">
            </div>

            <div class="loadingLabel">Sin conexión a internet</div>
            <small>Intentando en {{ retry }}</small>
            <button class="btn btn-outline-blue">Reintentar</button>


            <div class="spinnerContainer">
                <!--    <LoadSpin :color="'#0c7eca'" :size="'50px'" /> -->
            </div>


        </div>
    </div>
</template>

<script>
export default {
    name: "NoConnection",
    data() {
        return {
            retry: 30,

        }
    },
    methods: {
        /* check() {


        } */
    },

    mounted() {
         
        setInterval(() => {
            if (navigator.onLine) {
                this.$router.go(-1)
                //console.log("No se detectó ninguna conexión a internet");
            } else {
                if (this.retry > 0){
                    this.retry--
                }else{
                    location.reload()
                     
                }
            }

        }, 1000)
    }
}
</script>

<style>
.startSystem {
    position: relative;
    height: 100vh;
}

.startInfo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.spinnerContainer {
    width: 15%;
}

.starting__img img {
    display: block;
    width: 100%;
    margin: auto;
}

small {
    margin-bottom: 15px;
}

.loadingLabel {
    font-size: 20px;
    text-align: center;
    padding: 20px;
    padding-bottom: 10px;
}

.starting__img {
    width: 20%;
    margin: auto;

}
</style>