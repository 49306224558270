<template>
  <div class="dailyRegister">
    <div class="section__nav">
      <div class="section__nav__title">Registro diario</div>
      <div class="section__nav__controls">
        <router-link to="register/new">
        <button class="btn btn-outline-blue">Nuevo registro</button>
        </router-link>
      </div>
    </div>

    <div class="sectionView">
      <div class="table__container">
        <table id="table" class="display">
          <thead>
            <tr>
              <th>Id</th>
              <th>Producto</th>
              <th>Extraido</th>
              <th>Vendido</th>
              <th>Restante</th>
              <th>Fecha</th>
              <th>-</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Data</td>
              <td>Data</td>
              <td>Data</td>
              <td>Data</td>
              <td>Feb 12 2023</td>
              <td><button class="btn btn-outline-blue">Completar</button></td>
            </tr>
          
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "datatables.net-dt";
 
export default {
  name: "DailyRegister",
  data() {
    return {};
  },
  methods: {
    refreshTable: function () {
       new DataTable("#table",{
           "scrollY":400,
           "destroy":true
       });
    },
  },
  mounted() {
      this.refreshTable();
  },
  beforeUnmount(){
     new DataTable("#table").destroy();
  }
  
};
</script>

 