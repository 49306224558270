

import { router } from "./router.js"
const checkNetwork = () => {
 
   
    if (router.currentRoute.value.name != "NoConnection") {

        setInterval(() => {

            if (!navigator.onLine) {
                
                router.push({ name: "NoConnection" })
              //  console.log("No se detectó ninguna conexión a internet");
            } else {  


                //console.log(router.currentRoute.value);
                 

            }
        }, 3000)
    }

}

export {
    checkNetwork
}