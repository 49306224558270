<template>
    <div class="soldReport">
        <div class="control_nav">
            <div class="checkout__title">
                <h2>Reporte de ventas</h2>

            </div>

            <div class="control_nav_options">
                <!--   <button class="btn bg-blue-light" @click="searchAll">Quitar filtro</button> -->

                <button class="btn   btn-outline-blue " @click="leftReport">
                    <font-awesome-icon :icon="['fa', 'arrow-left']" />
                    Salir
                </button>
            </div>
            <!-- control_nav_options -->
        </div>

        <div class="soldInfo">
            <!-- <h3>Información del dia</h3> -->
            <div class="cards">
                <div class="card">
                    <span class="card__action"><font-awesome-icon :icon="['fa', 'ellipsis-vertical']" /></span>
                    <span class="card__info color-green-light">{{ this.currency(sold) }}</span>
                    <span class="card__title">Venta </span>
                    <apexchart width="100%" height="100px" type="line" :options="this.chartSparkOptions" :series="series">
                    </apexchart>

                    <span class="card__icon"><img src="../icons/sold.png"></span>
                </div>
                <div class="card">
                    <span class="card__action"><font-awesome-icon :icon="['fa', 'ellipsis-vertical']" /></span>
                    <span class="card__info color-red-light">{{ this.currency(discounts) }}</span>
                    <span class="card__title">Descuentos</span>
                    <apexchart width="100%" height="100px" type="line" :options="this.chartSparkOptions"
                        :series="discountSeries"></apexchart>

                    <span class="card__icon"><img src="../icons/discount.png"></span>
                </div>
                <div class="card">
                    <span class="card__action"><font-awesome-icon :icon="['fa', 'ellipsis-vertical']" /></span>
                    <span class="card__info">{{ this.currency(totalItbis) }}</span>
                    <span class="card__title">Itbis</span>
                    <span class="card__icon"><img src="../icons/tax.png"></span>
                    <apexchart width="100%" height="100px" type="line" :options="this.chartSparkOptions"
                        :series="itbisSeries"></apexchart>
                </div>
                <div class="card">
                    <span class="card__action"><font-awesome-icon :icon="['fa', 'ellipsis-vertical']" /></span>
                    <span class="card__info color-red-light">{{ this.currency(totalReturns) }}</span>
                    <span class="card__title">Devoluciones</span>
                    <apexchart width="100%" height="100px" type="line" :options="this.chartSparkOptions"
                        :series="returnsSeries"></apexchart>

                    <span class="card__icon"><img src="../icons/exchange.png"></span>
                </div>
            </div>

            <div class="cards">
                <div class="card">
                    <span class="card__action"><font-awesome-icon :icon="['fa', 'ellipsis-vertical']" /></span>
                    <span class="card__info">{{ this.currency(totalSoldCash) }}</span>
                    <span class="card__title">Efectivo</span>
                    <span class="card__icon"><img src="../icons/cash.png"></span>
                </div>
                <div class="card">
                    <span class="card__action"><font-awesome-icon :icon="['fa', 'ellipsis-vertical']" /></span>
                    <span class="card__info">{{ this.currency(totalSoldCard) }}</span>
                    <span class="card__title">Tarjeta</span>
                    <span class="card__icon"><img src="../icons/credit-cards.png"></span>
                </div>
                <div class="card">
                    <span class="card__action"><font-awesome-icon :icon="['fa', 'ellipsis-vertical']" /></span>
                    <span class="card__info">{{ this.currency(totalSoldTrans) }}</span>
                    <span class="card__title">Transferencias</span>
                    <span class="card__icon"><img src="../icons/transfers.png"></span>
                </div>
            </div>

            <div class="cards">
                <div class="card">
                    <span class="card__action"><font-awesome-icon :icon="['fa', 'ellipsis-vertical']" /></span>
                    <span class="card__info">{{ undSold }}</span>
                    <span class="card__title">Productos vendidos</span>
                    <span class="card__icon"><img src="../icons/right-arrow.png"></span>
                </div>
                <div class="card" >
                    <span class="card__action"><font-awesome-icon :icon="['fa', 'ellipsis-vertical']" /></span>
                    <span class="card__info">{{ topSales.prod_name }}</span>
                    <span>{{ topSales.barcode }}</span>

                    <span class="card__title">Producto más vendido con {{ sumTopSales.totalUndsSold }} unidades</span>
                    <span class="card__icon"><img src="../icons/right-arrow.png"></span>
                </div>

            </div>
            <div class="cards">



                <div class="card">
                    <span class="card__action"><font-awesome-icon :icon="['fa', 'ellipsis-vertical']" /></span>
                    <span class="card__info color-green-light">{{ this.currency(totalMonth) }}</span>
                    <span class="card__title ">Total este mes</span>
                    <span class="card__icon"><img src="../icons/salary.png"></span>
                </div>
                <div class="card">
                    <span class="card__action"><font-awesome-icon :icon="['fa', 'ellipsis-vertical']" /></span>
                    <span class="card__info">{{ this.currency(earnings) }}</span>
                    <span class="card__title">Ganancias</span>
                    <span class="card__icon"><img src="../icons/earning.png"></span>
                </div>

            </div><!-- cards -->
            <div class="chartInfo">
                <div class="chartInfo--sold">
                    <h3>Comparativa de ventas</h3>
                    <div class="soldCharts">
                        <apexchart width="100%" height="250px" type="line" :options="this.chartOptions" :series="series">
                        </apexchart>
                    </div><!-- soldCharts -->
                </div>
                <div class="chartInfo--sold">
                    <h3>Comparativa de ganancias</h3>
                    <div class="soldCharts">
                        <apexchart width="100%" height="250px" type="line" :options="this.chartOptions"
                            :series="earningSeries"></apexchart>
                    </div><!-- soldCharts -->
                </div>

            </div>

        </div>


    </div>
</template>

<script>


export default {
    name: "SoldReport",
    data() {
        return {


            series: [{
                name: 'Total vendido',
                data: []
            }],
            earningSeries: [{
                name: 'Total ganado',
                data: []
            }],

            itbisSeries: [{
                name: 'Total itbis cobrado',
                data: []
            }],
            discountSeries: [{
                name: 'Total itbis cobrado',
                data: []
            }],
            returnsSeries: [{
                name: 'Total itbis cobrado',
                data: []
            }],
            undSold: 0,
            sold: 0,
            total: 0,
            itbis: 0,
            discounts: 0,
            earnings: 0,
            totalSoldCash: 0,
            totalSoldCard: 0,
            totalSoldTrans: 0,
            totalSold: 0,
            totalMonth: 0,
            totalItbis: 0,
            totalReturns: 0,
            topSales: "",
            sumTopSales: 0,
            businessType: 1,

        }
    },

    methods: {

        leftReport() {
            this.$router.push({ name: 'Home' })
        },
        getInfo() {
            this.http.get(`${this.url}/salesReport`).then((res) => {
                console.log(res);
                var invoinceInfo = res.data
                var prodInfo = res.data.prodInfo;
                this.undSold = prodInfo.undSold
                this.sold = prodInfo.sold
                this.discounts = prodInfo.discounts
                this.totalItbis = prodInfo.totalItbis
                this.totalSoldCash = invoinceInfo.cash
                this.totalSoldCard = invoinceInfo.card
                this.totalSoldTrans = invoinceInfo.transfer
                this.earnings = prodInfo.earnings
                this.totalSold = invoinceInfo.total
                this.totalReturns = invoinceInfo.totalReturns == null ? 0 : invoinceInfo.totalReturns
                this.topSales = invoinceInfo.topSales
                this.sumTopSales = invoinceInfo.sumTopSales
                this.totalMonth = (parseFloat(this.sold) + parseFloat(this.discounts)) + (parseFloat(this.totalItbis) + parseFloat(this.totalReturns))

                //SRCI -> sold report chart Info
           
                this.series[0].data = Object.values(res.data.chartInfo)
                this.earningSeries[0].data = Object.values(res.data.chartEarnings)
                this.itbisSeries[0].data = Object.values(res.data.chartItbis)
                this.discountSeries[0].data = Object.values(res.data.discountsChart)
                this.returnsSeries[0].data = Object.values(res.data.returnsChart)


            });
        }
    },
    beforeMount() {
        this.getInfo()


    }

}
</script>

 