<template>
  <div class="recipt_container">
    <div class="recipt__logo">
      <img
        :src="photoURL ? this.staticUrl + '/uploads/' + photoURL : ''"
        class="logoPreview"
        alt=""
      />
    </div>
    <div class="recipt">
      <div class="recipt__info__bussiness">
        <span class="bussiness__name">
          {{ business_name }}
        </span>
        <span class="bussiness__dir">
          {{ location }}
        </span>
        <span class="bussiness__contact"> Tel. {{ contact }} </span>
        <span class="bussiness__rnc"> RNC {{ rnc }} </span>
      </div>
    </div>

    <hr />

    <h4>Factura de consumo</h4>

    <div class="recipt__info">
      <span class=""> Cliente </span>
      <span class=""> RNC </span>
      <span class=""> Fecha </span>
      <hr />
    </div>

    <span class=""> Le atendió: {{ name }} </span>
    <hr />

    <h4>Productos</h4>

    <div class="recipt__prods">
      
      <div class="prod" v-for="prod in prods" :key="prod.key">
        <span class=""> {{prod.barcode}}</span>
        <span class=""> {{prod.name}}</span>
        <span class=""> RD ${{prod.price}}</span>
        <span class=""> x {{prod.unds}} </span>
        <span class=""> itbis: RD ${{prod.itbis}} </span>
        <span v-if="prod.discount>0" class=""> Desc: -RD ${{ prod.discount }}</span>
      </div>
     
    </div>
    <hr>
   
 
    <div class="recipt__total">
      <div class="recipt__total__info">
        <span class=""> SubTotal: {{ this.currency(subTotal) }}</span>
        <span class=""> Itbis: {{ this.currency(itbis) }}</span>
        <span class=""> Desc. {{ this.currency(discount) }}</span>
        <span class="total"> Total:  {{ this.currency(total) }}</span>
      </div>
      <hr>
      <div class="recipt__total__info">
        <span class=""> Efectivo </span>
        <span class=""> Cambio</span>
         
      </div>
    </div>
    <button @click="printRecipt">Imprimir</button>
  </div>
</template>

<script>
 

export default {
  name: "InvoiceTemplate",

  data() {
    return {
      name: "",
      lastName: "",
      contact: "",
      business_name: "",
      description: "",
      location: "",
      rnc: "",
      logo: "",
      photoURL: "",
      /* Recipt */
      prods:"",
      totalUnds: 0,
      subTotal: 0,
      itbis: 0,
      discount: 0,
      total: 0,
      recived: 0,
      payment_method: "",
    };
  },

  methods: {
    getInfo() {
      this.http
        .get(`${this.url}/getUserInfo`)
        .then((res) => {
          var user = res.data;

          this.name = user.name;
          this.lastName = user.lastName;
          this.contact = user.contact;
          this.rnc = user.rnc;
          this.business_name = user.business_name;

          this.location = user.location;

          this.photoURL = user.photoURL;
          //console.log(user);
          //console.log(Object.values(JSON.parse(user.schedule)));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getItems() {
      this.http.get(`${this.url}/getCheckoutProducts`).then((res) => {
        //console.log(res.data);
        this.prods = res.data.prods;
        console.log(this.prods);
        this.totalUnds = res.data.sumary.totalUnds;
        this.subTotal = res.data.sumary.subTotal;
        this.itbis = res.data.sumary.totalItbis;
        this.discount = res.data.sumary.discount;
        this.total =
          this.prods.length > 0
            ? parseFloat(this.subTotal) -
              parseFloat(this.discount) +
              parseFloat(this.itbis)
            : 0;

        this.lastAddedCode = localStorage.getItem("lastAddedCode");
      });
    },
    /** 
     * Metodo usada para recibir los datos de caja y luego generar una plantilla de impresión
     * @function printRecipt() @return  void
     *
     * 
    */
    printRecipt(){
      window.print()
    }
  },
  mounted() {
    this.getInfo();
    
    this.getItems()
    
  },
};
</script>
