import Cookies from 'js-cookie'
import { router } from "./router.js"
const axios = require('axios').default;
import mitt from "mitt"
var emitter = mitt()
    /* 
    import { storeConfig } from './store'
    import { createStore } from 'vuex'

    const store = createStore(storeConfig)
      */



//console.log(typeof store.state.token);

function session() {
    /*    setInterval(() => {
       //console.log(store.state.token == Cookies.get("dspuid"));
       
        if (store.state.token != Cookies.get("dspuid") || store.state.token == 'null' || Cookies.get("dspuid") == 'null' ) {
            router.push({ name: 'LoginStart' })
            Cookies.set("dspuid", null)
            store.commit("setToken",null)
         
        }
    }, 500)   
    
 */


    axios.defaults.headers.common['Authorization'] = `${Cookies.get("dspuid")}`;
    //Se encarga de intereceptar las peticiones y realizar alguna acción con la respuesta antes de que sean manejadas


    axios.interceptors.response.use(

        function(res) {
            //console.log(res.data);
            if (res.data.logged == true) {
                router.push({ name: 'Home' })
                Cookies.set("dspuid", res.data.token)
            }
            return res;

        },


        function() {

            //console.log(error);
            /*  if ( err.response.status >= 500 || err.response.status >= 400) {
                router.push({ name: 'Login' })
                Cookies.set("dspuid", null)
            }
 */
            //return Promise.reject(err);
        });

    axios.interceptors.request.use(
        function(req) {
            emitter.emit("refresh")
            if (req.method == "post") {
                //emitter.emit("refresh")
            }


            return req;

        },

    )

}








export {

    session,
    Cookies
}