<template>
  <div class="sections_cols">
    <InventaryNavBar />
    <div class="container">



      <router-view :key="Math.random()" v-slot="{ Component }">
        <transition name="slide-fade" mode="out-in">
                  <component :is="Component" />
 
           
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import InventaryNavBar from "./InventaryNavBar.vue";

/* eslint-disable */

export default {
  name: "InventaryView",
  components: {
    InventaryNavBar,
  },


};
</script>

 