const options = {
    chart: {
        id: 'vuechart-example',
        dropShadow: {
            enabled: true,
            enabledOnSeries: undefined,
            top: 0,
            left: 0,
            blur: 3,
            color: '#000',
            opacity: 0.35
        },

    },
    xaxis: {
        categories: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
    }


}

/**
 * Este objeto de opciones es para ser usado en los graficos de lineas
 * @Object
 */
const sparkOptions = {
    chart: {

        dropShadow: {
            enabled: true,
            enabledOnSeries: undefined,
            top: 3,
            left: 1,
            blur: 3,
            color: '#000',
            opacity: 0.2
        },
        sparkline: {
            enabled: true
        },
    },
    xaxis: {
        categories: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
    }
}

export {
    options,
    sparkOptions
}