<template>
    <div class="confirmEmail">
        <div class="clouds__container">
            <div class="  banner__img--clouds">
                <img src="../../icons/cloud2.png" alt="">
            </div>
            <div class="  banner__img--clouds">
                <img src="../../icons/cloud2.png" alt="">
            </div>
            <div class="  banner__img--clouds">
                <img src="../../icons/cloud2.png" alt="">
            </div>
            <div class="  banner__img--clouds">
                <img src="../../icons/cloud2.png" alt="">
            </div>
        </div>

        <div class="banner__img">
            <img src="../../icons/boost.png" alt="">
        </div>

        <div class="confirm__container">
            <h2>Hemos enviado un código de 6 dígitos para confirmar su correo</h2>
            <div class="confirm__inputs">
                <input maxlength="1" type="text" class="input" v-model="digit1"><input maxlength="1" type="text"
                    class="input" v-model="digit2"><input maxlength="1" type="text" class="input" v-model="digit3"><input
                    maxlength="1" type="text" class="input" v-model="digit4"><input maxlength="1" type="text" class="input"
                    v-model="digit5"><input maxlength="1" type="text" class="input" v-model="digit6">
            </div>
            <button class="btn ui-btn" @click="verifyMail">
                <LoadSpin v-if="verifing" :color="'#FFF'" :size="'30px'" /> <span v-if="!verifing">Confirmar</span>
            </button>
            <div class="noCode">
                <p>No recibiste el código?</p>
                <span @click="resendCode">Reenviar código</span>
            </div>
        </div>



    </div>
</template>


<script>
import { useToast } from "vue-toastification";
export default {
    name: "ConfirmEmail",
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    data() {
        return {
            digit1: "",
            digit2: "",
            digit3: "",
            digit4: "",
            digit5: "",
            digit6: "",
            allDigit: "",
            token: "",
            verifing: false
        }


    },

    methods: {
        async sendSuccessConfirmation(userInfo) {
            var subj = "Verificación éxitosa"


            var body = `    <div style='font-family: Arial, sans-serif; background-color: #f3f3f3; margin: 0; padding: 0;'>
                            <div style='max-width: 600px; margin: 30px auto; background-color: #ffffff; padding: 20px; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);'>
                                <h1 style='color: #555555; text-align: center;'>Bienvenido(a) a Nuestra Plataforma</h1>
                                <p style='color: #777777; line-height: 1.6;'>
                                Estimado(a) ${userInfo.name} ${userInfo.lastName},
                                </p>
                                <p style='color: #777777; line-height: 1.6;'>
                                Su cuenta ha sido verificada correctamente, Ahora puede acceder a sistema Dotsell Pos.
                                </p>
                            
                            
                                <p style='color: #777777; line-height: 1.6;'>
                                Atentamente,<br>
                                El equipo de Dotsell Solutions
                                </p>
                                <br>
                                <br>
                            
                            </div>
                            </div>
                        `

            var res = await this.sendMail(userInfo.email, userInfo.name, subj, body)
            if (res.data.substr(0, 4) == 'true') {

                this.$router.push({ name: 'Login' })
            }
        },
        async sendConfirmMail(data) {
            var subj = "Confirmacion de cuenta"


            var body = `<div style='font-family: Arial, sans-serif; background-color: #f3f3f3; margin: 0; padding: 0;'>
  <div style='max-width: 600px; margin: 30px auto; background-color: #ffffff; padding: 20px; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);'>
    <h1 style='color: #555555; text-align: center;'>Bienvenido(a) a Nuestra Plataforma</h1>
    <p style='color: #777777; line-height: 1.6;'>
      Estimado(a) ${data.userInfo.name} ${data.userInfo.lastName},
    </p>
    <p style='color: #777777; line-height: 1.6;'>
      ¡Gracias por utilizar Dotsell Pos!
    </p>
    <p style='color: #777777; line-height: 1.6;'>
     Este es su código de verificación:  ${data.newCode}
    </p>
    <p style='color: #777777; line-height: 1.6;'>
      Este código expirará en 30 minutos
    </p>
    <p style='color: #777777; line-height: 1.6;'>
      Atentamente,<br>
      El equipo de Dotsell Solutions
    </p>
    <br>
    <br>
    <br>
    <br>
    <p style='color: #777777; line-height: 1.6;'>
     Si usted no solicitó está creación de cuenta, presione el botón de inferior.
    </p>
    <a style='display: block; width: 150px; margin: 20px auto; padding: 10px; background-color: #007bff; color: #ffffff; text-align: center; text-decoration: none; border-radius: 5px;' href='${this.domain}'>No fuí yo</a>

  </div>
</div>
`

            var res = await this.sendMail(data.userInfo.email, data.userInfo.name, subj, body)
            if (res.data.substr(0, 4) == 'true') {
                this.toast.success("Se ha enviado un nuevo código de verificación", this.toastOptions);
                //this.clearDraftForm()
                //this.$router.push({ name: 'ConfirmMail', params: { id: this.encode(this.uid) } })
            }
        },

        verifyMail() {
            this.allDigit = this.digit1 + this.digit2 + this.digit3 + this.digit4 + this.digit5 + this.digit6;
            this.verifing = true;
            this.http
                .post(`${this.url}/checkCode/${this.token}`, { params: { code: this.allDigit }  })
                   
              

                .then((res) => {
                   console.log(res.data);

                    if (res.data.confirmation == true) {
                        this.toast.success("Su correo ha sido verificado", this.toastOptions);
                        this.sendSuccessConfirmation(res.data.userInfo)
                        this.Cookies.set('dspuid',"")

                    } else {
                        this.toast.error("Este código es inválido!", this.toastOptions);
                        this.verifing = false;
                    }



                });

        },
        resendCode() {
            this.allDigit = this.digit1 + this.digit2 + this.digit3 + this.digit4 + this.digit5 + this.digit6;
            
            this.http
                .post(`${this.url}/resendCode/${this.token}`, {
                    params: { code: this.allDigit }
                })

                .then((res) => {
                    console.log(res.data);

                    if (res.data.resend == true) {
                        this.Cookies.set("dspuid",res.data.token)
                        this.token = res.data.token
                        this.sendConfirmMail(res.data)
                         

                    } else {
                        console.log(res.data);
                        this.toast.error("Este código es inválido!", this.toastOptions);
                        this.verifing = false;
                    }



                });

        }
    },
    mounted() {
        this.token = this.Cookies.get("dspuid")
         
    }
}
</script>
 