<template>
  <!-- Suplidor -->
  <div class="form__control">
    <label for="">Suplidor</label>
    <div class="inputAddons">
      <span>
        <font-awesome-icon :icon="['fas', 'person']" />
      </span>
      <select v-if="suppliersList" :disabled="edition">
        <option v-if="selected" v-bind:value="selected.ID">
          {{ selected.name }}({{ selected.company }})
        </option>
        <option value="0">Sin suplidor</option>
        <option
          v-for="supplier in suppliersList"
          :key="supplier.ID"
          :value="supplier.ID"
        >
          {{ supplier.name }}
          ({{ supplier.company }})
        </option>
      </select>
      <router-link to="/suppliers/new">
        <button class="addSupplier">+</button></router-link
      >
    </div>
    <!-- supplier end -->
  </div>
</template>

<script>
export default {
  name: "SupplierSelect",
  data() {
    return {
      selected: [],
      suppliersList: [],
      supplier: "",
      
    };
  },
  props: {
    productSupplier: Boolean,
    supplierId: String,
    edition:Boolean
  },
  beforeMount() {
    this.supplier = localStorage.getItem("spId");
  },

  mounted() {
     
    this.http.get(`${this.url}/getSuppliers`).then((res) => {
      this.suppliersList = res.data;
    });

    if (this.supplier != "") {
      this.http
        .get(`${this.url}/getSupplierInfo/${this.supplier}`)
        .then((res) => {
          this.selected = res.data;
        });
    }
  },
};
</script>

 