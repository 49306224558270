<template>
  <div class="suppliers_container">
    <!--   <SuplierNav :supplierList="suppliers" /> -->
    <div class="container">
      <div class="section__nav">
        <!-- <div class="section__nav__title">Suplidores registrados</div> -->
        <h2>Suplidores</h2>
        <div class="section__nav__controls">

          <button @click="this.$router.go(-1)" class="btn btn-outline-blue">
            <font-awesome-icon :icon="['fa', 'arrow-left']" /> Volver {{ goBack }}
          </button>

        </div>
      </div>

      <div class="supplier_list">
        <DataTable class="display" :columns="this.dtColsSuppliers" :data="suppliers" :options="this.dtOptions">
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
/* import SuplierNav from "./SuplierNavBar.vue"; */
 
import { useToast } from "vue-toastification";
export default {
  name: "SuplierList",

  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  data() {
    return {
      filters: [],
      suppliersTotal: 0,
      suppliers: [],
      meta: this.$router.options.history,
      goBack: ''
    };
  },

  components: {
    /*  SuplierNav, */
  },
  methods: {
    getSuppliers() { 
       
      var preload = localStorage.getItem("preload")
      var refresh = localStorage.getItem("refresh")
      if (preload && refresh == "false") {
        var supplier = JSON.parse(preload);
        this.suppliers = supplier[1]['data'];
        this.suppliersTotal = this.suppliers.length
      } else {
        console.log(true);
        this.http.get(`${this.url}/getSuppliers`).then((res) => {
          this.suppliers = res.data;
          this.suppliersTotal = this.suppliers.length;
 
        });
      }


    },
  },

  mounted() {

    this.goBack = this.meta.state.goBack

    this.getSuppliers()

    document.addEventListener("click", (e) => {
      if (e.target.classList.contains("show_supplier_info")) {
        let id = e.target.dataset.id;
        this.$router.push(`/suppliers/view/${id}`);
      }

      if (e.target.classList.contains("call")) {
        window.location.href = `tel:${e.target.dataset.number}`

      }
      if (e.target.classList.contains("mail")) {
        window.location.href = `mailto:${e.target.dataset.mail}`

      }

      if (e.target.id == "setFavorite") {
        let id = e.target.dataset.id;
        let isfavorite = e.target.dataset.isfavorite;
        /*   this.$router.push(`/suppliers/view/${id}`); */
        this.http
          .post(`${this.url}/setFavorite`, {
            params: {
              supplierId: id,
              favorite: isfavorite == "1" ? false : true,
            },
          })
          .then(() => {
            this.toast.success(
              `${isfavorite == "1"
                ? "El suplidor se quitó de los favoritos"
                : "El suplidor se marcó como favorito"
              }`,
              this.toastOptions
            );
            this.getSuppliers()
          });
      }
    });
  },
};
</script>

 