import { createRouter, createWebHistory } from "vue-router";
import RegisterForm from "./components/session_forms/RegisterForm.vue";
import LoginForm from "./components/session_forms/LoginForm.vue";
import ConfirmEmail from "./components/session_forms/ConfirmEmail.vue";
import TermsConditions from "./components/session_forms/TermsConditions.vue";
import WelcomePage from "./components/start/Welcome";
import StartConfig from "./components/start/StartConfig";
import HomePage from "./components/HomePage.vue";
import StartSystem from "./components/StartSystem.vue";
import NoConnection from "./components/noConnection.vue";
//Register components
import DailyRegister from "./components/dailyRegister/DailyRegister.vue";
import RegisterNew from "./components/dailyRegister/RegisterNew.vue";

//Sell Components
import CheckOut from './components/checkout/CheckOut'
import InvoiceTemplate from './components/checkout/InvoiceTemplate.vue'
import PayMethod from './components/checkout/PayMethod'
import CreditInvoice from './components/checkout/CreditInvoice'
import Remove from './components/checkout/Remove'
import StartCheckout from './components/checkout/StartCheckout'
import CashBalancing from './components/checkout/CashBalancing'

//Inventary components
import GeneralInfo from "./components/inventary/GeneralInfo.vue";
import InventaryView from "./components/inventary/InventaryView.vue";
import InventaryControl from "./components/inventary/Inventary-control.vue";
import ProdActions from "./components/inventary/ProdActions.vue";
import NewProductSpecial from "./components/inventary/inspecial/NewProductSpecial.vue";
//Invoice
import Invoices from "./components/invoices/RegistredInvoices.vue";
import ViewInvoice from "./components/invoices/ViewInvoice.vue";
import ReturnItem from "./components/invoices/ReturnItem.vue";
import SoldReport from "./components/sold/SoldReport.vue";
//Returns
import ReturnsItems from "./components/returns/ReturnsItems.vue";
//Client
import ClientsInfo from "./components/client/ClientsInfo.vue";
import AddInfo from "./components/client/AddInfo.vue";
/* Supliers */
import SuplierList from "./components/supliers/SupplierList.vue";
import NewSuplier from "./components/supliers/NewSupplier.vue";
import EditSupplier from "./components/supliers/EditSupplier.vue";
import SystemConfig from "./components/config/SystemConfig.vue";



const routes = [

    /* {
    name:"Login",
    path:"/login",
    component:LoginForm
    
    },   */
    {
        name: "Register",
        path: "/signup",
        component: RegisterForm,
        meta: { transition: 'none' },

    },
    {
        name: "Login",
        path: "/signin",
        component: LoginForm,
        meta: { transition: 'none' },

    },
    {
        name: "ConfirmMail",
        path: "/confirm-email/:id",
        component: ConfirmEmail,
        meta: { transition: 'fade' },

    },
    {
        name: "Terms",
        path: "/terms",
        component: TermsConditions,
        meta: { transition: 'fade' },

    },

    {
        //start config
        name: "welcome",
        path: "/welcome",
        component: WelcomePage
    },
    {
        //start config
        name: "Start",
        path: "/start-config",
        component: StartConfig
    },


    {
        //Home
        name: "Home",
        path: "/panel",
        component: HomePage,
        meta: {
            transition: "slide-fade"
        }
    },
    {
        //Home
        name: "LoginStart",
        path: "/",
        redirect: "/signin",
        component: LoginForm
    },

    {
        //Start System
        name: "StartSystem",
        path: "/starting",
        component: StartSystem
    },
    {
        //No connection
        name: "NoConnection",
        path: "/disconected",
        component: NoConnection
    },



    //checkout
    {
        path: '/checkout',
        name: 'Checkout',
        component: CheckOut,
        children: [{
                name: "StartCheckout",
                path: "start",
                component: StartCheckout,
            },
            {
                name: "PayMethod",
                path: "paymethod",
                component: PayMethod,
            },
            {
                name: "CreditInvoice",
                path: "credit",
                component: CreditInvoice
            },
            {
                name: "NewClientCheckout",
                path: "new-client",
                component: AddInfo,
                meta: { action: "viewFromCheckout" }
            },
            {
                name: "Remove",
                path: "remove",
                component: Remove,
            },
            {
                name: "cashBalancing",
                path: "balancing",
                component: CashBalancing,
            },

        ]

    },
    {
        name: "InvoiceTemplate",
        path: "/invoice-info",
        component: InvoiceTemplate,
    },





    {
        path: "/home",
        component: HomePage
    },
    //register
    {
        path: "/register",
        component: DailyRegister
    },
    {
        path: "/register/new",
        component: {
            RegisterNew
        }
    },
    //Inventary

    {
        path: "/inventary",
        name: "Inventary",
        component: InventaryView,
        children: [
            { path: 'general', name: "General", component: GeneralInfo, /*  meta: { transition: 'slide-fade' }, */ },
            { path: 'all', name: "All", component: InventaryControl, /*  meta: { transition: 'slide-fade' }, */ },
            { path: 'specials', name: "Especials", component: InventaryControl, /*  meta: { transition: 'slide-fade' }, */ },
            { path: 'lowstock', name: "LowStock", component: InventaryControl, /*  meta: { transition: 'slide-fade' }, */ },
            { path: 'descontinued', name: "Discontinued", component: InventaryControl, /*  meta: { transition: 'slide-fade' }, */ },
            { path: 'expiresoon', name: "ExpireSoon", component: InventaryControl, /*  meta: { transition: 'slide-fade' }, */ },

        ],
        meta: { transition: 'slide-fade' }

    },
    {
        path: "/inventary/new",
        component: ProdActions,
        meta: { action: "registerNew" }
    },
    {
        path: '/inventary/lowstock/add/:id+',
        name: 'AddLowStock',
        component: ProdActions,
        meta: { action: "addUnds" }
    },
    {
        path: "/inventary/edit/:id+",
        name: "EditProd",
        component: ProdActions,
        meta: { action: "edit" }



    },
    // Inventary -- productsSpecials

    {
        path: "/inventary/specials/create",
        name: 'CreateSpecial',
        component: NewProductSpecial

    },
    {
        path: "/inventary/specials/edit/:id",
        name: "EditSpecial",
        component: NewProductSpecial,
        meta: { action: "viewSpecial" }


    },

    //Invoices
    {
        name: "Invoices",
        path: "/invoices",
        component: Invoices,
        children: [{
                name: "ViewInvoice",
                path: "view/:id",
                component: ViewInvoice

            },
            {
                name: "ViewClient",
                path: "view-client/:cid",
                meta: { action: 'viewFromInvoices' },
                component: AddInfo
            }
        ],
        meta: {
            transition: "slide-fade"
        }
    },

    {
        name: "ViewInvoice",
        path: "/invoice/view/:id",
        component: ViewInvoice,
        children: [

            {
                name: "ReturnItem",
                path: "return/:barcode",
                component: ReturnItem

            }
        ]

    },


    {
        name: "Sold",
        path: "/sold",
        component: SoldReport

    },

    //Returns
    {
        name: 'Returns',
        path: "/returns",
        component: ReturnsItems,

    },


    //Clients 
    {
        name: "ClientInfo",
        path: "/clients",
        component: ClientsInfo,
        children: [{
                name: "AddInfo",
                path: "info",
                component: AddInfo,
                meta: { action: "viewFromClients" }
            },
            {
                name: "Edit",
                path: "edit/:cid",
                component: AddInfo,
                meta: { action: "viewFromClients" }
            }
        ]
    },

    //Suppliers
    {
        name: "Suppliers",
        path: "/suppliers",
        component: SuplierList
    },
    //Suppliers - new
    {
        path: "/suppliers/new",
        component: NewSuplier
    },
    //Suppliers - view
    {
        name: 'ViewSupplier',
        path: "/suppliers/view/:id",
        component: EditSupplier
    },

    {
        //config
        name: "Config",
        path: "/config",
        component: SystemConfig
    },


]

export const router = createRouter({
    routes,
    history: createWebHistory(),
    linkActiveClass: 'active-link',
    linkExactActiveClass: 'exact-active-link',
})