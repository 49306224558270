//Data table
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5.mjs';

import select from 'datatables.net-select';
import dtBTN from 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-scroller-dt';
import 'datatables.net-responsive';
import 'datatables.net-fixedcolumns-dt';

const currency = (values) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(values)
}
var special_img = "../icons/price-tag.png";
var edit_img_path = "../dt_icons/edit.png";
var phoneCall = "../dt_icons/phone-call.png";
var mail = "../dt_icons/mail.png";
var invoice = "../dt_icons/invoice.png";
var calendar = "../dt_icons/calendar.png";
var view = "../dt_icons/eye.png";
var img_path = (row) => { return row.favorite == 1 ? "../dt_icons/favorite.png" : "../dt_icons/unfavorite.png"; }
 

 



function checkAvalibleImg(path) {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            resolve(true); // La imagen se cargó correctamente
        };
        img.onerror = () => {
            resolve(false)
        };
        img.src = path;
    });
}

checkAvalibleImg('../../dt_icons/eye.png')
    .then((correct) => {
        if (!correct) {
            edit_img_path = "../../dt_icons/edit.png";
            invoice = "../../dt_icons/invoice.png";
            view = "../../dt_icons/eye.png";
            calendar = "../../dt_icons/calendar.png";
        }
    });


const checkoutOpt = {


    select: false,
    scrollY: "100%",
    paginate: false,

    /* scrollX: true, */
    stateSave: false,
    responsive: true,
    filter: false,
    searchPanes: {
        show: false
    },
    language: {
        emptyTable: "No hay articulos en caja"
    }




};



const dtOptions = {


    select: false,
    scrollY: '360',
    /* scrollX: true, */
    stateSave: true,
    responsive: true,
    language: {
        url: '../config/dt-spanish.json',
    },

    /*    dom: 'Bfrtip',
           buttons: [
               'colvis'
           ]
     */




};



const checkoutCols = [
    { sortable: false, data: 'barcode', title: 'Código de barras' },
    { sortable: false, data: 'name', title: 'Producto' },
    {
        sortable: false, title: 'Precio',
        render: function (d, t, r) {
            t
            r
            return currency(r.price);


        },
    },
    { sortable: false, data: 'unds', title: 'Unds' },
    {
        sortable: false, title: 'Valor',
        render: function (d, t, r) {
            return currency(parseFloat(r.price) * parseFloat(r.unds))

        }
    },
    {
        sortable: false, title: 'Itbis',
        render: function (d, t, r) {
            return currency(r.itbis)

        }
    },
    {
        sortable: false, title: 'Desc.',
        render: function (d, t, r) {


            return `-${r.discount != 0 ? r.discount + "%" : "N/A"}`


        }
    },
    {
        sortable: false, title: 'total',
        render: function (d, t, r) {

            var subtotal = parseFloat(r.price) * parseFloat(r.unds) + parseFloat(r.itbis)
            var total = subtotal - (subtotal * r.discount / 100)
            return currency(total);


        }
    },
    {
        sortable: false, title: '',
        render: function (d, t, r) {
            d, t, r
            if (r.special == 1) {
                return `    
                <img title="Este producto tiene un especial aplicado" src="${special_img}" data-id="${r.id}">
            `

            } else {
                return ''
            }


        }
    },



]

const inventaryCol = [
    {
        title: '#',

        render:   function (d, t, r) {
            // var img_path = "../dt_icons/calendar-outline.png";
            d
            t
            r
            

            return `<span class="image_label">${r.id} </span>`









        }
    },
    {
        title: 'Código',
        data: 'barcode'

    },
    { data: 'name', title: 'Nombre' },
    {
        title: 'Descripción', render: function (data, type, row) {
            // var img_path = "../dt_icons/calendar-outline.png";


            return `<span class="label description-label">
         ${row.description ? row.description : "Sin descripción"}</span>`
        }
    },
    {
        title: 'Compra',

        render: function (data, type, row) {
            // var img_path = "../dt_icons/calendar-outline.png";


            return `<span class="label price-label">$${row.buyPrice != 0 ? row.buyPrice : 'N/A'}</span>`
        }
    },
    {
        title: 'Venta',
        render: function (data, type, row) {
            // var img_path = "../dt_icons/calendar-outline.png";


            return `<span class="label price-label">$${row.sellPrice}</span>`
        }
    },
    /*   {   title: 'Mayoreo',  render: function (data, type, row) {
          // var img_path = "../dt_icons/calendar-outline.png";
   
   
           return `<span class="label price-label">$${row.wholesale_price}</span>`
       } }, */

    {
        title: "itbis",
        sortable: false,
        render: function (data, type, row) {

            data
            type
            row
            return `
           
                 ${row.itbis}%
              
    
             
            
            `
        }
    },
    {
        title: 'Restante',
        render: function (data, type, row) {
            // var img_path = "../dt_icons/calendar-outline.png";


            return `<span class="label price-label">${row.buyPrice != 0 ? row.unds : 'N/A'}</span>`
        }
    },
    { data: 'supplier', title: 'Suplidor' },
    /*   { //Fecha de exp
          title: 'Expiración',
  
  
          render: function (data, type, row) {
              var img_path = "../dt_icons/calendar-outline.png";
  
  
              return `<span class="label date-label"><img src="${img_path}"/>${row.expire_date != "0000-00-00" ? row.expire_date : 'No expira'}</span>`
          }
      }, */

    {
        title: "",
        sortable: false,
        render: function (data, type, row) {

            data
            type
            return `
           
                 <img class="editItem"  data-id="${row.id}" data-supplierId="${row.supplierId}"   src="${edit_img_path}">  
              
    
             
            
            `
        }
    },


]
const lowStock = [
    { data: 'barcode', title: 'Código de barras' },
    { data: 'name', title: 'Nombre' },
    { data: 'description', title: 'Descripción' },

    { data: 'unds', title: 'Restante' },
    { data: 'supplier', title: 'Suplidor' },

    {
        title: "",
        sortable: false,
        "render": function (data, type, row) {


            var img_path = "../dt_icons/plus.png";
            data
            type
            return ` 
                        <img src="${img_path}" class='  addItems ' data-id="${row.id}" data-supplierId="${row.supplierId}">
                    `
        }
    }

]



const invoices = [
    { data: 'fact_id', title: 'Número de factura' },
    {
        title: 'Cliente',
        "render": function (data, type, row) {
            data
            type

            return `
                <div class='dt_client_info'>

                  <span>  ${row.name == null ? "N/A" : row.name + " " + row.lastname}</span> <img class="${row.name != null ? "viewClient" : ''}"  data-cid="${row.cid}"   src="${view}"> 

                </div>
            
            `

        }
    },
    {
        title: 'Valor de factura',
        "render": function (data, type, row) {

            return `RD ${currency(row.total)}`
        }
    },

    {
        title: 'Fecha',

        "render": function (d, t, r) {
            return `  <div class='dt_client_info'>

            <span> ${r.date}   </span> <img src="${calendar}">   

      </div>`
        }
    },

    { data: 'seller', title: 'Vendido por' },
    {
        title: '',

        sortable: false,
        "render": function (data, type, row) {
            data
            type

            return `
            <img class="viewInvoice"  data-id="${row.id}" data-fact_id="${row.fact_id}"   src="${invoice}">  
         `
        }
    }



]
const clients = [
    { data: 'cid', title: 'Cédula' },
    { data: 'name', title: 'Nombre' },
    { data: 'lastname', title: 'Apellido' },

    { data: 'birthdate', title: 'Fecha de nacimiento' },

    {
        title: 'Dirección',
        render: function (d, t, r) {
            return `

            <div class="table_label" title="ubicación del cliente" >
                ${r.location ? r.location : "--- --- ----"}
              
            </div>

        `
        }

    },
    {
        title: 'Celular',
        render: function (d, t, r) {
            return `

        <div class="table_label" title="Número de contacto" >
            ${r.cel ? r.cel : "--- --- ----"}
          
        </div>

    `
        }
    },
    { data: 'fidelitycard', title: 'Código de cliente' },
    {
        title: '',

        sortable: false,
        "render": function (data, type, row) {
            data
            type

            return `
            <img class="viewClient"  data-id="${row.id}" data-cid="${row.cid}"   src="${edit_img_path}">  
         `
        }
    }



]


const suppliersCols = [
    {
        sortable: false,
        title: "#",
        render: (data, type, row) => {


            return `${row.ID}`
        }

    },
    {
        sortable: false,
        title: 'fav', render: (data, type, row) => {

            data
            type
            return `
       
             <img id="setFavorite" data-isfavorite="${row.favorite}"  data-id="${row.ID}" "   src="${img_path(row)}">  
          

         
        
        `
        }
    },
    {
        title: 'Nombre', render: (data, type, row) => {
            return `${row.name} ${row.lastName}`
        }
    },

    {
        title: 'Contacto',

        render: function (d, t, r) {
            return `

                <div class="table_label" title="Llamar al cliente" >
                    ${r.contact ? r.contact : "--- --- ----"}
                    <img class="editItem call"  data-number="${r.contact}"    src="${phoneCall}">  
                </div>

            `
        }

    },
    { data: 'company', title: 'Empresa' },
    { data: 'location', title: 'Ubicación' },
    {
        title: 'Correo electronico',
        render: function (d, t, r) {
            return `

            <div class="table_label" title="Enviar correo" >
                ${r.email ? r.email : "-------------"}
                <img class="editItem mail"  data-mail="${r.email}"    src="${mail}">  
            </div>

        `
        }
    },
    { data: 'date', title: 'Registrado el' },
    {
        title: "",
        sortable: false,
        "render": function (data, type, row) {
            data
            type

            return `
            <img class="editItem show_supplier_info"  data-id="${row.ID}" data-supplierId="${row.supplierId}"   src="${edit_img_path}">  
         `
        }
    }



]

const specialsCols = [
    { data: 'id', title: 'Id' },
    { data: 'prodName', title: 'Producto' },
    { data: 'buycant', title: 'cantidad mínima' },
    { data: 'newPrice', title: 'Precio especial' },
    {
        title: 'Tipo de oferta',
        "render": function (data, type, row) {

            data
            type
            var offerType = "";
            switch (row.specialType) {
                case "1": offerType = "Descuento"; break;
                case "2": offerType = "Precio x cantidad"; break;
                case "3": offerType = "Descuento x cantidad"; break;
            }
            return offerType

        }
    },
    { data: 'discount', title: '%  descuento' },
    { data: 'date_from', title: 'Desde' },
    { data: 'date_until', title: 'Hasta' },
    {
        title: "",
        sortable: false,
        "render": function (data, type, row) {

            data
            type
            return ` 
                        <img src="${edit_img_path}" class="show_special_product" data-id="${row.id}">
                    `
        }

    }



]

const returns = [
    { data: 'id', title: 'Id' },
    {
        title: 'Número de factura',
        render: function (d, t, r) {
            return `

                <div class="table_label" title="Ver factura" >
                    ${r.fact_id} <img class="viewFact"  data-fid="${r.fact_id}"   src="${view}"> 
                </div>

            `
        }
    },
    {
        title: 'Código de devolución',
        render: function (d, t, r) {
            return `

                <div class="table_label" title="Este código se usará para realizar la deducción del total de la nueva factura del cliente" >
                    ${r.returnCode}
                </div>

            `
        }
    },
    {
        title: 'Código de p.',
        render: function (d, t, r) {
            return `

                <div class="table_label table_label--ncr " title="Ver este producto en el inventario" >
                    ${r.prod_id}
                </div>

            `
        }
    },
    {
        title: 'Producto',
        render: function (d, t, r) {
            return `

                <div class="table_label table_label--ncl prod_name  " title="Ver este producto en el inventario" >
                    ${r.prod_name}  <img class="viewProd"  data-prodid="${r.prod_id}"   src="${view}"> 
                </div>

            `
        }
    },
    {
        title: 'Número de factura',
        render: function (d, t, r) {
            return `

                <div   >
                    ${currency(r.buy_price)}
                </div>

            `
        }
    },
    {
        title: 'Número de factura',
        render: function (d, t, r) {
            return `

                <div  >
                    ${currency(r.price)}
                </div>

            `
        }
    },
    { data: 'unds', title: 'Unds' },
    {
        title: 'Estado',
        render: function (d, t, r) {
            var state = "";
            var lb_class = "";

            switch (r.status) {
                case "1": lb_class = "lb_green"; state = "Intacto"; break;
                case "2": lb_class = "lb_orange"; state = "Aperturado"; break;
                case "3": lb_class = "lb_red"; state = "Mal estado"; break;
            }
            return `

                <div  class="table_label table_label--${lb_class} ">
                    ${state}

                </div>

            `
        }
    },
    { data: 'reason', title: 'Razón' },
    {
        title: 'Cliente',
        render: function (d, t, r) {
            return `

                <div  >
                    ${r.client_id == 0 ? 'N/A' : r.client_id}  <img class="viewProd"  data-cid="${r.prod_id}"   src="${r.client_id == 0 ? "" : view}"> 
                </div>

            `
        }
    },
    {
        title: 'Cobrado',
        render: function (d, t, r) {
            return `

                <div  >
                 ${r.collected == 0 ? "No" : "Si"}
                </div>

            `
        }
    },
    {
        title: "",
        sortable: false,
        "render": function (data, type, row) {

            data
            type
            return ` 
                        <img src="${edit_img_path}" class="show_special_product" data-id="${row.id}">
                    `
        }

    }



]

DataTable.use(DataTablesCore)
DataTable.use(select)
DataTable.use(dtBTN)
export {
    dtOptions,
    checkoutOpt,
    inventaryCol,
    suppliersCols,
    specialsCols,
    lowStock,
    checkoutCols,
    invoices,
    clients,
    returns,
    DataTable
}
//app.component('DataTable', DataTable)
