const axios = require('axios').default;

import { apiUri } from "./config"
import { router } from "./router.js"



import { Cookies } from './session'
axios.defaults.headers.common['Authorization'] = Cookies.get("dspuid");
/**
 * @constant
 * @function
 * @async  
 * This functions used for the recurrent update 
 * of the information, avoiding constants request to the server
 */




const refreshInfo = async() => {
    const preload = []

    const urls = [
        `loadInventary`,
        `getSuppliers`,
        `getReturned`,
        `getClients`,
        //`salesReport`,
        `searchInvoices`
    ];

    //console.log(typeof localStorage.getItem("refresh"));



    for (const path of urls) {
        try {
            const res = await axios.get(`${apiUri}/` + path);
            preload.push({ path, data: res.data })


        } catch (error) {
            console.error(`Error al cargar los datos de ${path}:`, error);
        }
    }
    localStorage.setItem("preload", JSON.stringify(preload))
    localStorage.setItem("refresh", false)
    if (router.currentRoute.value.path == "/starting") router.push({ name: 'Home' });
    return true;
}

export {
    refreshInfo
}