<template>
  <div class="col-row">
    <div class="section__nav">
      <div class="section__nav__title"><span style="color:var(--main-color)">Inventario</span> > {{ sectionName }}</div>

      <div class="section__nav__controls">
        <router-link :to="{ name: 'CreateSpecial' }">
          <button v-if="specials" class="btn btn-blue">
            <font-awesome-icon :icon="['fa', 'plus']" /> Crear especial
          </button>
        </router-link>
        <router-link to="/home">
          <button class="btn btn-outline-blue">
            <font-awesome-icon :icon="['fa', 'arrow-left']" /> Salir del
            inventario
          </button>
        </router-link>
      </div>
    </div>

    <div class="sectionView">
      <div class="table__container">
        <!--   <OverLay /> -->
       <DataTable id="datatables" :data="products" :columns="this.cols" :options="this.dtOptions" class="display">
        </DataTable>

      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "Inventary-control",

  data() {
    return {
      products: [],
      unds: 0,
      sectionName: "",
      section: "",
      mainSec: "/inventary/",
      cols: [],
      dataSection:{},
      specials: false,

    };
  },

  methods: {
    /* Para hacer que se actualicen las vistas sin tener que cargar otros componentes
      se le pasa una propiedad dinamica al routerView con un Math.random() para que en este
      componente el watcher pueda detectar ese cambio y refrescar la sección, porque de 
      contrario vue no recargará el componente ya cargado
    */
    checkCurrentSection(route) {
      //Verifica la vista que se requiere cargar acorde a la ruta
      //console.log(this.$route.path);
      this.specials = false;
      this.checkPreload( );

      switch (route) {
        case `/inventary/all`:
          this.section = "getProducts";
          this.sectionName = "Todos los productos";
          this.cols = this.dtCols;
          this.products = this.dataSection.products
         
          break;
        case `${this.mainSec}specials`:
          this.cols = this.specialsCols;
          this.section = "getSpecials";
          this.sectionName = "Especiales activos";
          this.specials = true;
          this.products = this.dataSection.specials

         

          break;
        case `${this.mainSec}lowstock`:
          this.cols = this.lowStock;
          this.section = "getLowStock";
          this.sectionName = "Pocas unidades";
          this.products = this.dataSection.lowStock
         
          break;
        case `${this.mainSec}descontinued`:
          this.section = "getProductsDescontinued";
          this.sectionName = "Productos descontinuados";
          this.cols = this.dtCols;
          this.products = this.dataSection.discontinued
         
          break;
        case `${this.mainSec}expiresoon`:
          this.cols = this.dtCols;
          this.section = "getExpireSoon";
          this.sectionName = "Expiran pronto";
          this.products = this.dataSection.expireSoon
         
          break;
      }

      
    },
    checkPreload() {
      //if (localStorage.getItem("refresh") == "true" || localStorage.getItem("preload") == "undefined") return false;
      var info = JSON.parse(localStorage.getItem("preload"))
      var res = info[0]
     // console.log(res.data);
      this.dataSection = res.data;
      this.unds = res.data.length;
      localStorage.setItem("pu", this.encode(this.unds));
      return true;
    },

    getInfo(section) {
      section
      this.http.get(`${this.url}/loadInventary`).then((res) => {
         console.log(res.data);
        this.products = res.data;
        this.unds = res.data.length;
          /* 
        localStorage.setItem("pu", this.encode(this.unds)); */
      });


    },
    editItem: function (item) {
      this.$router.push({ name: 'EditProd', params: { id: item } });
    },
    deleteItem: function (item) {
      this.$swal
        .fire({
          title: "Seguro que deseas eliminarlo?",
          text: "También se eliminará la información relacionada",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminarlo",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.http.get(`${this.url}/deleteItem/${item}`).then((res) => {
              this.$router.push(`/inventary`);
            });
            this.$swal.fire("Hecho", "Se ha borrado el articulo", "success");
            this.getInfo();
          }
        });
    },
  }, //methods end

  beforeMount() {
    //this.checkPreload()
     this.checkCurrentSection(this.$route.path);
  },
  mounted() {

  

    /* 1- El boton al que se está accediendo se encuentra ubicado en el render de la tabla 
    que se usa para el inventario, ahí se le pasan el id y supplierId en una propiedad data-.
    La información es guardada en el localstorage de forma temporal para poder leer la información desde
    la sección de edición el producto.

    2- el otro botón es el de eliminación del producto

     */
    // this.auth(this.$store.state.token)

    document.addEventListener("click", (e) => {

      if (e.target.classList.contains("editItem")) {
        let item = e.target.dataset.id;
        let spId = e.target.dataset.supplierid;
        localStorage.setItem("spId", spId);
        this.$router.push(`/inventary/edit/${item}`);
      }

      if (e.target.classList.contains("show_special_product")) {
        let item = e.target.dataset.id;
        // this.deleteItem(item)
        this.$router.push({ name: 'EditSpecial', params: { id: item } });
      }

      if (e.target.classList.contains("addItems")) {
        let item = e.target.dataset.id;
        // this.deleteItem(item)
        this.$router.push({ name: 'AddLowStock', params: { id: item } });
      }

      if (e.target.classList.contains("deleteItem")) {
        let item = e.target.dataset.id;
        this.deleteItem(item);
      }
    });
  },
};
</script>

 