<template>
  <div class="modal">
    <OverLay  />
    <div class="startCheckout">
      <button @click="this.$router.push({ name: 'Home' })"   class="close btn">
                Salir de caja
            </button>
      <h2>Efectivo inicial</h2>
      <div class="removeItem">
        <div class="item__info">
          <p>Ingrese el monto con el cual se abrirá.</p>
      
            <input
              id="startCash"
              v-model="startCash"
      
               
              autocomplete="off"
              class="input"
              type="number"
              placeholder="RD $0.00"
            />
         
       
        </div>
 
 
          <button class="btn process" :disabled="this.processing"  @click="addStartInfo"> Guardar información <LoadSpin v-if="processing" :color="'#FFF'"  :size="'30px'"/></button>

 
 
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import OverLay from "../overlay";
export default {
  name: "startCheckout",
  components: {
    OverLay,
  },
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  data() {
    return {
      processing:false,
      startCash: "",
    };
  },
  methods: {
    addStartInfo() {
      this.processing = true;
      this.http
        .post(`${this.url}/addStartInfo`, {
          params: {
           startCash: this.startCash
          },
        })

        .then((res) => {
          //console.log(res);
          if(res.status ){
            this.$router.push({name:'Checkout'})
          //  localStorage.setItem('chkSt',true)
          }
          
           
        });
    },

  
  },

};
</script>

 