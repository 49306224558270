<template>
    <div class="modal">
        <OverLay @click="goBack" />
        <div class="returnItem ">
            <h2>Retornar</h2>
            <form onsubmit='return false' class='form'>
                <div class="form__control">
                    <label for="valueF">Motivo de la Devolución</label>
                    <div class="inputAddons">
                        <span>
                            <font-awesome-icon :icon="['fas', 'question']" />
                        </span>

                        <input type="text" class="input" v-model="reason" placeholder="...." />
                    </div>
                </div>
                <div class="form__control">
                    <label for="valueF">Unidades</label>
                    <div class="inputAddons">
                        <span>
                            <font-awesome-icon :icon="['fas', 'question']" />
                        </span>

                        <input type="text" class="input" v-model="unds" placeholder="...." />
                    </div>
                </div>

                <div class="form__control">
                    <label for="transferType">Estado del producto</label>
                    <div class="inputAddons">
                        <span>
                            <font-awesome-icon :icon="['fas', 'star-of-life']" />
                        </span>

                        <select v-model="state" name="transferType">

                            <option value="1">Intacto</option>
                            <option value="2">Aperturado</option>
                            <option value="3">Mal estado</option>
                        </select>
                    </div>
                </div>
                <div class="controls">
                    <button class="btn" @click="process">Procesar Devolución</button>
                    <button class="btn btn-outline-red" @click="goBack">Cancelar</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification"
import OverLay from "../overlay";
export default {

    name: "ReturnItem",

    components: {
        OverLay,
    },
    setup() {
        // Get toast interface
        const toast = useToast()

        // Make it available inside methods
        return { toast }
    },
    data() {
        return {
            reason: "",
            state: "1",
            unds:1

        };
    },

    mounted() {
        this.total = this.$router.options.history.state.amount
        // console.log(this.total);
    },



    methods: {
        process() {
            var params = this.$router.currentRoute.value.params

            this.$emit("processReturn", {
                reason: this.reason,
                state: this.state,
                unds: this.unds,
                barcode: params.barcode,
                fact_id: params.id
            })

        },
        goBack() {
            this.$router.go(-1);
        },



    },
}

</script>
 