<template>
  <div class="modal">
    <OverLay @click="goBack" />
    <div class="paymethod">
      <button @click="this.$router.push({ name: 'Checkout' })" :disabled="this.processing" class="close btn">
        Cerrar
      </button>
      <h2>{{ selected && paymentMethod != "" ? selected : 'Seleccione el método de pago' }}</h2>

      <div class="paymethod_select" v-if="paymentMethod == ''">
        <div class="option" @click="cash">
          <img src="../icons/dollar.png" />
          <span>Efectivo</span>
        </div>
        <div class="option" @click="card">
          <img src="../icons/atm-card.png" />
          <span>Tarjeta</span>
        </div>
        <div class="option" @click="transfer">
          <img src="../icons/transfer.png" />
          <span>Transferencia</span>
        </div>
      </div>

      <div class="finish_sale" v-if="paymentMethod != ''">
        <div class="total">
          <span>Total: </span>
          <span>{{ this.currency(total) }}</span>
        </div>
        <div class="cals" v-if="paymentMethod == '1'">


          <div class="form__control">
            <label for="">Monto recibido</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fa', 'percent']" />
              </span>
              <input id="recived" @keyup="calculate" type="number" v-model="recived" placeholder="RD $"
                class="form__input" />
            </div>
          </div>
          <div class="form__control">
            <label for="">Cambio</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fa', 'percent']" />
              </span>
              <input disabled type="number" v-model="refound" placeholder="RD $" class="form__input" />
            </div>
          </div>

        </div>

        <div class="payment_controls">
          <button class="btn changeMethod" :disabled="this.processing" @click="changeMethod">Elegir otro método</button>
          <button class="btn process" :disabled="this.processing"
            @click="prepareInfo">{{ procesingLabel != "" ? procesingLabel : "Procesar" }}
            <LoadSpin v-if="processing" :color="'#FFF'" :size="'30px'" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification"
import OverLay from "../overlay";
export default {
  name: "PayMethod",
  components: {
    OverLay,
  },
  setup() {
    // Get toast interface
    const toast = useToast()

    // Make it available inside methods
    return { toast }
  },
  data() {
    return {
      paymentMethod: "",
      total: "",
      recived: "",
      refound: 0,
      selected: "",
      processing: false,
      procesingLabel: ""
    };
  },
 

  mounted() {
    this.total = this.$router.options.history.state.amount
    // console.log(this.total);
  },



  methods: {
    goBack() {
      this.$router.push("/checkout");
    },
    cash() {
      //document.getElementById("recived").focus();
      this.paymentMethod = "1";
      this.selected = "Efectivo";

    },
    card() {
      this.paymentMethod = "2";
      this.selected = "Tarjeta";
    },
    transfer() {
      this.paymentMethod = "3";
      this.selected = "Transferencia bancaria";
    },

    changeMethod() {
      this.paymentMethod = "";
      this.processing = false;
      this.procesingLabel = ""

    },

    calculate() {




      this.refound = this.recived > this.total ? parseFloat(this.recived) - parseFloat(this.total) : 0

    },
    //Se encarga de tomar la información necesaria para pasarla al componente padre
    prepareInfo() {
      this.processPayment(this.paymentMethod, this.recived, this.refound, this.total)
    },
    //Aquí se ejecuta el evento que le envia la información al componente padre
    processPayment(paymentMethod, recived, refound, total) {
      if (recived == "" && paymentMethod < 2) { this.toast.info("Debe ingresar un monto igual o superior al total", this.toastOptions); return false }
      if (recived < total && paymentMethod < 2) { this.toast.info("El monto recibido es menor al total", this.toastOptions); return false }
      this.processing = true;
      this.procesingLabel = "Procesando venta..."

      this.$emit('process', { paymentMethod, recived, refound, total })
    }


  },
};
</script>

 