<template> 
  <div class="RegisterNew">
    <div class="modalNav">
      <h1>REGISTRAR NUEVO SUPLIDOR</h1>

      <div class="modalControl">
  
          <button @click="this.$router.go(-1)" class="btn btn-outline-blue">
            <font-awesome-icon :icon="['fas', 'arrow-left-long']" /> Regresar
          </button>
   
      </div>
    </div>
    <h3>{{ name + " " + lastName }}</h3>
    <form class="form" @submit.prevent="onSubmit">
      <div class="form__cols l-l-l">
        <div class="form__control">
          <label for="">Nombre</label>

          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fas', 'signature']" />
            </span>
            <input
              type="text"
              v-model="name"
              placeholder="..."
              class="form__input"
            />
          </div>
        </div>
        <!-- end form__control -->

        <div class="form__control">
          <label for="">Apellido</label>

          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fas', 'signature']" />
            </span>
            <input
              type="text"
              v-model="lastName"
              placeholder="..."
              class="form__input"
            />
          </div>
        </div>
        <!-- end form__control -->

        <div class="form__control">
          <label for="">Número de contacto</label>

          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fas', 'mobile']" />
            </span>
            <input
              type="tel"
              v-model="contact"
              placeholder="xxx-xxx-xxxx"
              class="form__input"
            />
          </div>
        </div>
        <!-- end form__control -->
      </div>
      <!-- end form__cols -->

      <div class="form__cols l-l-l">
        <div class="form__control">
          <label for="">Nombre de la empresa</label>

          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fa', 'building']" />
            </span>
            <input
              type="text"
              v-model="company"
              placeholder="..."
              class="form__input"
            />
          </div>
        </div>
        <!-- end form__control -->
        <div class="form__control">
          <label for="">Ubicación</label>

          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fas', 'location-dot']" />
            </span>
            <input
              type="text"
              v-model="location"
              placeholder="..."
              class="form__input"
            />
          </div>
        </div>
        <!-- end form__control -->
        <div class="form__control">
          <label for="">Correo Electrónico</label>

          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fa', 'envelope']" />
            </span>
            <input
              type="email"
              v-model="email"
              placeholder="example@mail.com"
              class="form__input"
            />
          </div>
        </div>
        <!-- end form__control -->
      </div>
      <!-- end form__cols -->

      <button class="btn btn-blue" @click="addNewSupplier">Agregar</button>
    </form>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
  name: "newProduct",
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  data() {
    return {
      name: "",
      lastName: "",
      contact: "",
      company: "",
      location: "",
      email: "",
    };
  },
  methods: {
    newSupplier() {
      this.http
        .post(`${this.url}/newSupplier`, {
          params: {
            name: this.name,
            lastName: this.lastName,
            contact: this.contact,
            company: this.company,
            location: this.location,
            email: this.email,
          },
        },{
          headers:{
              'Refresh-state':'true'
          }

        })

        .then((res) => {
         // console.log(res);
          if (res.data.error[1] == 1062) {
            this.toast.info("Este correo ya existe", this.toastOptions);
          }

          if (res.data.status == true) {
            this.toast.success("Suplidor registrado", this.toastOptions);
            this.name = "";
            this.lastName = "";
            this.contact = "";
            this.company = "";
            this.location = "";
            this.email = "";
          }
        });
    },
    addNewSupplier() {
      if (this.name != "" && this.company != "") {
        this.newSupplier();
      } else {
        this.toast.info(
          "Los campos: Nombre y empresa, son requeridos.",
          this.toastOptions
        );
      }
    },
  },
};
</script>

 
 