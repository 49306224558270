<template>
  <div @click="goBack" class="overlay">
    <!-- <LoadSpin :color="'#4F88AE'"/> -->
  </div>
</template>

<script>
export default {
  name: "overLay",
   
   
};
</script>

 