<template>
    <div class="clients">
        <router-view v-slot="{ Component }">
            <transition name="slide-right">
                <component :is="Component" @save="getClients" />
            </transition>
        </router-view>
        <div class="control_nav">
            <div class="checkout__title">
                <h2>Clientes</h2>
            </div>

            <div class="control_nav_options">
                <button class="btn btn-blue-light" @click="this.$router.push({ name: 'AddInfo' })">Nuevo cliente</button>

                <button class="btn  btn-outline-blue" @click="leftClients">
                    <font-awesome-icon :icon="['fa', 'arrow-left']" />  Salir
                </button>
            </div>
            <!-- control_nav_options -->
        </div>
        <!-- control_nav -->

        <div class="clients__info">
        <!--     <div class="panel">
                <h2> Información general</h2>
                <div class="panel__info">
                    <div class="info">
                        <span>Clientes totales</span>
                    </div>
                </div>
            </div> -->
            <div class="clientsContainer">
                <DataTable id="datatables" :data="clients" :columns="this.clientsCols" :options="newDtOptions"
                    class="display">
                </DataTable>
            </div>
        </div>
    </div>
</template>


<script>




export default {
    name: "ClientsInfo",
    data() {
        return {
            clients: [],
            newDtOptions: {}
        }
    },

    beforeMount() {
        //console.log(this.dtOptions);

        this.dtOptions.scrollY = "40vh"
        this.newDtOptions = this.dtOptions
    }, 

    mounted() {

       
       
        document.addEventListener("click", (e) => {
            if (e.target.classList.contains("viewClient")) {
                var cid = e.target.dataset.cid;

                this.$router.push({ name: 'Edit', params: { cid: `${cid}` } })
            }
        })

        this.getClients()

    },
  
    methods: {
        getClients() {
            this.http.get(`${this.url}/getClients`).then((res) => {
                this.clients = res.data
                this.$emit("refresh")
            })

            
        },
        leftClients() {
            this.$router.push({ name: "Home" })
        }

    },


}


</script>
