<template>
  <div class="bills">
    <router-view v-slot="{ Component }">
      <transition id="slide-right">
        <component :is="Component" />
      </transition>
    </router-view>
    <div class="control_nav">
      <div class="checkout__title">
        <h2>Facturas</h2>
      </div>

      <div class="control_nav_options">
        <button class="btn btn-blue-light" @click="searchAll">Quitar filtro</button>

        <button class="btn   btn-outline-blue " @click="leftInvoices">
          <font-awesome-icon :icon="['fa', 'arrow-left']" /> Salir
        </button>
      </div>
      <!-- control_nav_options -->
    </div>
    <!-- control_nav -->
    <div class="registredBills">
      <h2><font-awesome-icon :icon="['fas', 'filter']" /> Filtrar</h2>

      <form onsubmit='return false' class='form'>
        <div class="filter">
          <div class="form__control">
            <label for="fFrom">Desde</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fas', 'calendar-days']" />
              </span>

              <DatePicker placeholder="dd-mm-yyyy" :enable-time-picker="false" v-model="date_from" :format="format"
                id="fFrom" />
            </div>
          </div>

          <div class="form__control">
            <label for="fUntil">Hasta</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fas', 'calendar-days']" />
              </span>

              <DatePicker placeholder="dd-mm-yyyy" :enable-time-picker="false" v-model="date_until" :format="format"
                id="fUntil" />
            </div>
          </div>

          <div class="form__control">
            <label for="transferType">Tipo de transacción</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fas', 'right-left']" />
              </span>

              <select v-model="type" id="transferType">
                <option value="">Todo</option>
                <option value="1">Efectivo</option>
                <option value="2">Tarjeta</option>
                <option value="3">Transferencia bancaria</option>
              </select>
            </div>
          </div>

          <div class="form__control">
            <label for="billState">Estado</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fas', 'right-left']" />
              </span>

              <select v-model="status" id="billState">
                <option value="">Todo</option>
                <option value="1">Pagadas</option>
                <option value="2">Pendientes</option>
                <option value="3">Anuladas</option>
                <option value="4">Vencidas</option>
              </select>
            </div>
          </div>

          <div class="form__control">
            <label for="seller">Vendedor</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fas', 'right-left']" />
              </span>

              <select v-model="seller" id="seller">
                <option disabled value="">Todo</option>
              </select>
            </div>
          </div>

          <div class="form__control">
            <label for="valueF">Valor desde</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fas', 'dollar-sign']" />
              </span>

              <input type="text" class="input" v-model="valueF" placeholder=">= $9999999" id="valueF" />
            </div>
          </div>
          <div class="form__control">
            <label for="valueU">Hasta</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fas', 'dollar-sign']" />
              </span>

              <input type="text" class="input" v-model="valueU" placeholder="<= $9999999" id="valueU" />
            </div>
          </div>
          <button class="btn btn-blue-light" :disabled="finding" @click="filter">
            <font-awesome-icon v-if="!finding" :icon="['fas', 'magnifying-glass']" />
            <LoadSpin v-if="finding" :color="'#FFF'" :size="'15px'" />
          </button>
        </div>
      </form>

      <div class="bills__info">
        <DataTable id="datatables" :data="bills" :columns="this.dtInvoices" :options="dtInvoiceOpt" class="display">
        </DataTable>
      </div>
    </div>
    <!-- registredBills -->
  </div>
  <!-- bills -->
</template>

<script>
export default {
  name: "RegistredInvoices",

  data() {
    return {
      date_from: "",
      date_until: "",
      type: "",
      status: "",
      seller: "",
      valueF: "",
      valueU: "",
      bills: [],
      finding: false,
      format: (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
      },
      dtInvoiceOpt: {
        select: false,
        scrollY: "265px",

        stateSave: true,
        responsive: true,
      },
    };
  },

  beforeMount() {
    this.searchAll();
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (e.target.classList.contains("viewInvoice")) {
        let fact_id = e.target.dataset.fact_id;
        this.$router.push({ name: "ViewInvoice", params: { id: fact_id } });
      }

      if (e.target.classList.contains("viewClient")) {
        let cid = e.target.dataset.cid;
        this.$router.push({ name: "ViewClient", params: { cid: cid }, meta: { from: "invoices" } });
      }
    });
  },
  methods: {
    filter() {
      this.finding = true;
      this.http
        .get(`${this.url}/filterInvoices`, {
          params: {
            date_from: this.date_from,
            date_until: this.date_until,
            type: this.type,
            status: this.status,
            seller: this.seller,
            valueF: this.valueF,
            valueU: this.valueU,
          },
        })
        .then((res) => {
          this.bills = res.data;
          this.finding = false;
          //  console.log(res.data);
        });
    },

    searchAll() {
      this.http.get(`${this.url}/searchInvoices`).then((res) => {
        this.bills = res.data;


      });
    },

    viewInvoice() { },
    leftInvoices() {
      this.$router.push({ name: 'Home' });
    },
  },
};
</script>

 