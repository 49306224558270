<template>
    <div class="startConfig">
        <div class="welcome">
            <h1>Bienvenido {{ fullName }}</h1>
            <p>Te agredecemos por haber elegido <b>Dotsell Pos</b></p>
        </div>
        <div class="welcome welcome--beforego">
            
            <p>Antes de acceder al sistema, hagámos algunas configuraciones iniciales</p>
            <br>
            <button class="btn btn-outline-blue" @click="this.$router.push({name:'Start'})">Empezar</button>
        </div>
       


    </div>
</template>

<script>
export default {
    name:"WelcomPage",
    data() {
        return {
            fullName: ""
        }
    },
    methods: {

    },
    mounted() {
        this.fullName = this.$store.state.user.name;

    }
}
</script>

 