<template>
    <div class="register__form">
        <div class="clouds__container">
            <div class="  banner__img--clouds">
                <img src="../../icons/cloud2.png" alt="">
            </div>
            <div class="  banner__img--clouds">
                <img src="../../icons/cloud2.png" alt="">
            </div>
            <div class="  banner__img--clouds">
                <img src="../../icons/cloud2.png" alt="">
            </div>
            <div class="  banner__img--clouds">
                <img src="../../icons/cloud2.png" alt="">
            </div>
        </div>
        <div class="banner">
            <span class="banner__text">Dale un toque de productividad a tu negocio</span>
        </div>
        <div class="banner__img">
            <img src="../../icons/boost.png" alt="">
        </div>


        <div class="form__container">
            <div class="brand">
                <img src="../../icons/logo.png" alt="">
            </div>
            <form onsubmit='return false' class='form' >
                <div class="form__cols" v-if="register">
                    <div class="form__control">
                        <label class="form__label" for="">Nombre</label>
                        <input placeholder="..." v-model="name" type="text" class="input" @keyup="saveDraftForm">
                    </div>

                    <div class="form__control">
                        <label class="form__label" for="">Apellido</label>
                        <input placeholder="..." v-model="lastname" type="text" class="input" @keyup="saveDraftForm">
                    </div>
                </div>

                <div class="form__control">
                    <label class="form__label" for="">Correo electrónico</label>
                    <input placeholder="example@mail.com" v-model="email" type="email" class="input" @keyup="saveDraftForm">
                </div>

                <div class="form__cols">
                    <div class="form__control">
                        <label class="form__label" for="">Contraseña</label>
                        <input placeholder="..." v-model="password" type="password" class="input">
                    </div>

                    <div class="form__control" v-if="register">
                        <label class="form__label" for="">Confirme su contraseña</label>
                        <input placeholder="..." v-model="confirmPass" type="password" class="input">
                    </div>
                </div>

                <p>Al hacer click en crear cuenta, aceptas los <span class="terms"
                        @click="this.$router.push({ name: 'Terms' })">terminos y condiciones</span> de uso</p>
                <div class="form__control">
                    <button class="btn btn-ui" :disabled="sending" @click="signup">
                        <LoadSpin v-if="sending" :color="'#FFF'" :size="'30px'" /> <span v-if="!sending">Crear cuenta</span>
                    </button>
                </div>
                <div class="form__control">
                    <button @click="this.$router.push({ name: 'Login' })" class="btn btn-outline-ui">o Iniciar
                        sesión</button>
                </div>
               <!--  <AuthBanner @successLogin="googleAccess" /> -->
            </form>

        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
/* import AuthBanner from './AuthBanner.vue' */
export default {
    name: "RegisterForm",
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    data() {
        return {
            register: true,
            name: "",
            lastname: "",
            email: "",
            password: "",
            confirmPass: "",
            acceptedTerms: false,
            sending: false,
            uid:""



        }

    },

    components: {
    /*     AuthBanner */
    },
    methods: {
        googleAccess(e) {
            console.log(e);
            console.log(e.displayName);
            console.log(e.email);
            console.log(e.photoURL);

        },
        saveDraftForm() {
            localStorage.setItem("draft_name",this.name)
            localStorage.setItem("draft_lastname",this.lastname)
            localStorage.setItem("draft_email",this.email)
        },
        loadDraftForm() {
           
            this.name = localStorage.getItem("draft_name")
            this.lastname= localStorage.getItem("draft_lastname")
            this.email= localStorage.getItem("draft_email")
        },
        clearDraftForm(){
            localStorage.setItem("draft_name","")
            localStorage.setItem("draft_lastname","")
            localStorage.setItem("draft_email","")
        },
        verifyFields() {



            if (!this.name || !this.lastname) {
                this.toast.info("Debe ingresar su nombre y apellido", this.toastOptions)
                return false;
            }

            var regex = /\w+@\w+\.\w+/
            if (!regex.test(this.email)) {
                this.toast.info("Debe ingresar un correo válido", this.toastOptions)
                return false;
            }

            if (this.confirmPass != this.password) {
                this.toast.info("Las contraseñas ingresadas no coinciden", this.toastOptions)
                return false;
            }
        },

        async sendConfirmMail(code) {
            var subj = "Confirmacion de cuenta"
            

            var body = `<div style='font-family: Arial, sans-serif; background-color: #f3f3f3; margin: 0; padding: 0;'>
  <div style='max-width: 600px; margin: 30px auto; background-color: #ffffff; padding: 20px; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);'>
    <h1 style='color: #555555; text-align: center;'>Bienvenido(a) a Nuestra Plataforma</h1>
    <p style='color: #777777; line-height: 1.6;'>
      Estimado(a) ${this.name} ${this.lastname},
    </p>
    <p style='color: #777777; line-height: 1.6;'>
      ¡Gracias por utilizar Dotsell Pos!
    </p>
    <p style='color: #777777; line-height: 1.6;'>
     Este es su código de verificación:  ${code}
    </p>
    <p style='color: #777777; line-height: 1.6;'>
      Este código expirará en 30 minutos
    </p>
    <p style='color: #777777; line-height: 1.6;'>
      Atentamente,<br>
      El equipo de Dotsell Solutions
    </p>
    <br>
    <br>
    <br>
    <br>
    <p style='color: #777777; line-height: 1.6;'>
     Si usted no solicitó está creación de cuenta, presione el botón de inferior.
    </p>
    <a style='display: block; width: 150px; margin: 20px auto; padding: 10px; background-color: #007bff; color: #ffffff; text-align: center; text-decoration: none; border-radius: 5px;' href='${this.domain}'>No fuí yo</a>

  </div>
</div>
`

            var res = await this.sendMail(this.email, this.name, subj, body)
            if (res.data.substr(0, 4) == 'true') {
                this.clearDraftForm()
                this.$router.push({ name: 'ConfirmMail',params:{id:this.encode(this.uid)} })
            }
        },

        signup() {
            if (this.verifyFields() != false) {
                this.http
                    .post(`${this.url}/newUser`, {
                        params: {

                            name: this.name,
                            lastName: this.lastname,
                            email: this.email,
                            password: this.password,

                        },
                    })

                    .then((res) => {
                        console.log(res.data);
                        /* this.toast.info("Este correo ya existe", this.toastOptions); */
                        if (res.data.error[1] == 1062) {
                            this.toast.info("Este correo ya está registrado", this.toastOptions);
                            this.email = ""
                        }
                        if (res.data.status == true) {
                            
                            this.uid = res.data['lastId']
                            this.Cookies.set('dspuid',res.data[0]['token'])
                            
                            this.sendConfirmMail(res.data[0]['confirmation'])


                        }


                    });

            }
        },
      




    },
    mounted() {
        this.loadDraftForm()
    }
}
</script>

 