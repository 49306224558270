<template>
  <div class="checkout">
    <router-view
      v-slot="{ Component }"
      @clientSaved="searchClient"
      @save_credit="save_credit"
    >
      <transition name="slide-right">
        <component :is="Component" @process="save_sale" />
      </transition>
    </router-view>

    <div class="control_nav">
      <div class="checkout__title">
        <h2>Vender</h2>
      </div>

      <div class="control_nav_options">
        <button class="btn bg-blue-blue">RNC</button>
        <button
          class="btn bg-blue-blue"
          @click="
            this.$router.push({
              name: 'CreditInvoice',
              state: { total: total },
            })
          "
        >
          Factura a crédito
        </button>
        <button class="btn bg-blue-blue">Descuento de factura</button>

        <button class="btn bg-blue-blue" @click="removeItem">
          Quitar producto
        </button>
        <button class="btn bg-blue-blue" @click="clearCheckout">
          Cancelar venta
        </button>
        <button class="btn bg-blue-blue" @click="cashBalancing">
          Cuadre de caja
        </button>
        <button class="btn bg-blue-blue">
          <font-awesome-icon :icon="['fa', 'keyboard']" />
        </button>
        <button class="btn btn-outline-blue" @click="leftCheckout">
          Salir
        </button>
      </div>
      <!-- checkout__controls -->
    </div>
    <!-- checkout__nav -->

    <div class="checkout__rows">
      <div class="prod__info">
        <div class="search__prod">
          <div class="form__control">
            <label for="">Ingresar producto </label>

            <div class="search__input">
              <input
                id="barcode"
                v-model="barcode"
                @keyup.enter="searchItem"
                @keyup.ctrl.alt.exact="addUnds"
                @keypress.ctrl.alt.exact="addUnds"
                autocomplete="off"
                class="input"
                type="text"
                placeholder="A0000001"
              />
              <!--  <input  id="unds" @keyup.enter="searchItem" class="input" type="number"  /> -->
              <button @click="searchItem" class="search bg-blue-light">
                <i class="fi fi-rr-search"></i>
              </button>
            </div>
            <div class="unds">Cantidad: {{ unds }}</div>
            <!--  <span>Cantidad: 7</span> -->
          </div>

          <div class="added__item" v-if="client != null">
            <h3>Información del cliente</h3>
            <ul>
              <li>{{ `${client.name} ${client.lastname}` }}</li>
              <li>Tiene {{ client.points }} puntos acumulados</li>
            </ul>
          </div>
        </div>

        <div class="prod__list">
          <DataTable
            id="datatables"
            :columns="this.checkoutCols"
            :data="prods"
            :options="this.checkoutOpt"
          />
        </div>
      </div>
      <div class="checkout__sumary">
        <div class="checkout__cols">
          <div class="checkout__cols__sections">
            <h3>&Uacute;ltimo c&oacute;digo agregado</h3>
            <span>{{ lastAddedCode }}</span>
          </div>
          <div class="checkout__cols__sections">
            <h3>Total de productos</h3>
            <span>{{ totalUnds }}</span>
          </div>
        </div>
        <!-- checkout__cols -->

        <div class="checkout__cols">
          <div class="checkout__cols__sections">
            <div class="checkout__totals">
              <div class="subtotal">
                <span>Subtotal: </span>
                <span>{{ this.currency(subTotal) }}</span>
              </div>

              <div class="subtotal">
                <span>Itbis: </span>
                <span>{{ this.currency(itbis) }}</span>
              </div>
              <div class="subtotal">
                <span>Descuento </span>
                <span>-{{ this.currency(discount) }}</span>
              </div>
              <div class="total">
                <span>Total: </span>
                <span>{{ this.currency(total) }}</span>
              </div>
            </div>
            <!-- checkout__totals -->
          </div>
          <!-- checkout__rows -->
        </div>
        <!-- checkout__cols -->

        <div class="checkout__cols">
          <div class="checkout__cols__sections">
            <button @click="paymentMethodSelector" class="btn">
              Cobrar
              <small>F10</small>
            </button>
          </div>
        </div>
        <!-- checkout__cols -->
      </div>
    </div>
  </div>
</template>

<script>
//import { jsPDF } from "jspdf";
import { useToast } from "vue-toastification";

export default {
  name: "CheckOut",
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },

  data() {
    return {
      barcode: "",
      unds: 1,
      prods: [],
      specialActivated: false,
      lastAdded: "",
      lastAddedCode: "",
      totalUnds: 0,
      subTotal: 0,
      itbis: 0,
      discount: 0,
      total: 0,
      recived: 0,
      payment_method: "",
      reciptData: "",
      status: 1,
      startCash: 0,
      remainCash: 0,
      limitDate: "",
      remove: false,
      client: {},
      needUpdate: false,
    };
  },

  mounted() {
    this.focus();
    this.emitter.on("cinf", this.getClientInfo);
    this.emitter.on("refresh", this.getItems);
    this.getItems();
    this.getClientInfo();
    if (localStorage.getItem("chkSt") != "true") {
      this.checkStartInfo();
    }
    this.emitter.on("checkout-done", (type, e) => console.log(type, e));
  },

  updated() {
    //this.focus();
    //    this.getItems();
  },

  methods: {
    focus() {
      switch (this.$router.currentRoute.value.name) {
        case "PayMethod":
          document.getElementById("barcode").focus();
          break;
        case "Checkout":
          document.getElementById("barcode").focus();
          break;
        case "Remove":
          document.getElementById("barcodeToRemove").focus();
          break;
        case "StartCheckout":
          document.getElementById("startCash").focus();
          break;
      }
    },

    checkStartInfo() {
      this.http.post(`${this.url}/checkStartInfo`).then((res) => {
        // console.log(res.data);
        if (res.data == false) {
          this.$router.push({ name: "StartCheckout" });
        }

        if (typeof res.data == "object") {
          this.$router.push({ name: "Checkout" });
        }
      });
    },
    addUnds(e) {
      //console.log(e);

      if (
        e.key == "0" ||
        e.key == "1" ||
        e.key == "2" ||
        e.key == "3" ||
        e.key == "4" ||
        e.key == "5" ||
        e.key == "6" ||
        e.key == "7" ||
        e.key == "8" ||
        e.key == "9"
      ) {
        if (this.unds === 1) {
          this.unds = "";
        }
        this.unds += e.key;
      }
      if (e.key == "Backspace") {
        this.unds = "";
      }

      switch (e.key) {
        case "+":
          this.unds += 1;
          break;

        case "-":
          this.unds != 1 ? (this.unds -= 1) : 1;

          break;
      }
    },
    searchItem() {
      if (this.barcode === "") {
        this.toast.info("Debe ingresar un código de barras", this.toastOptions);

        return false;
      } else {
        this.unds = this.unds == "" ? 1 : this.unds;
        this.http
          .post(
            `${this.url}/addToCheckout`,
            {
              params: {
                barcode: this.barcode,
                unds: this.unds,
              },
            } 
          )
          .then((res) => {
            //console.log(res.data);

            if (res.data == "") {
              this.toast.info(
                `El código "${this.barcode}" ingresado no existe `,
                this.toastOptions
              );

              this.barcode = "";
            }
            if (res.data.status == "CA") {
              //Código de cliente agregado
              //console.log(res.data);
              this.toast.success(
                `El cliente fue verificado`,
                this.toastOptions
              );
              this.storeClientInfo(res.data.clientInfo);

              this.barcode = "";
              this.unds = 1;
            }
            if (res.data.status == "noen") {
              this.toast.info(
                `No hay suficientes unidades para la cantidad requerida, por favor revise su inventario.`,
                this.toastOptions
              );
            }

            if (res.data.status == "PD") {
              this.toast.info(
                `El producto se encuentra descontinuado, por favor revise su inventario.`,
                this.toastOptions
              );
            }
            if (res.data.status == "EP") {
              this.toast.info(
                `El producto ha expirado, no es recomendable venderlo así.`,
                this.toastOptions
              );
            }
            if (res.data.info && res.data.info.status == true) {
              localStorage.setItem("lastAddedCode", this.barcode);
              this.barcode = "";
              this.unds = 1;
              this.getItems();
            }
            this.lastAdded = res.data.name;
            this.emitter.emit("refresh");
          });
      }
    },
    getItems() {
      this.http.get(`${this.url}/getCheckoutProducts`).then((res) => {
        //console.log(res.data);
        this.reciptData = res.data;
        this.prods = res.data.prods;

        this.totalUnds = res.data.sumary.totalUnds;
        this.subTotal = res.data.sumary.subTotal;
        this.itbis = res.data.sumary.totalItbis;
        this.discount = res.data.sumary.discount;
        this.total =
          this.prods.length > 0
            ? parseFloat(this.subTotal) -
              parseFloat(this.discount) +
              parseFloat(this.itbis)
            : 0;

        this.lastAddedCode = localStorage.getItem("lastAddedCode");
      });
    },
    storeClientInfo(client) {
      var cinf = {
        id: client.id,
        name: client.name,
        lastname: client.lastname,
        points: client.points,
      };
      localStorage.setItem("cinf", JSON.stringify(cinf));
      this.emitter.emit("cinf");
    },

    getClientInfo() {
      this.client = JSON.parse(localStorage.getItem("cinf"));
    },
    paymentMethodSelector() {
      if (this.prods.length != 0) {
        this.$router.push({
          name: "PayMethod",
          state: { amount: this.total },
        });
      } else {
        this.toast.info("No hay productos para facturar", this.toastOptions);
      }
    },
    save_sale(e) {
      //Se lee el evento que se emite desde el componente que se usa para elegir el método de pago
      //En caso de que esta función no se ejecute con ese evento, se le pasa paramatro tipo objeto desde la función --save_credit--
      //Para que al información sea enviada
      /*   var print = new jsPDF({unit:"mm", format: [80, 297]})
     
        print.text("text",10,10)
        print.stroke()
        print.save("a4.pdf");
   */

      this.http
        .post(`${this.url}/processPayment`, {
          totalUnds: this.totalUnds,
          total: this.total,
          recived: e.recived,
          refound: e.refound,
          payment_method: e.paymentMethod,
          status: e.status == 2 ? e.status : this.status,
          startCash: e.recived,
          remainCash: e.remainCash,
          limitDate: e.limitDate,

          cid: this.client != null ? this.client.id : "",
        },{
          headers:{
              'Refresh-state':'true'
          }

        })
        .then((res) => {
          //console.log(res.data);

          if (res.data.status) {
            //   this.emitter.emit("checkout-done",{data: this.reciptData})
            
            
            this.getItems();
            this.prods = [];
            this.totalUnds = 0;
            this.subTotal = 0;
            this.itbis = 0;
            this.discount = 0;
            this.total = 0;
            this.$router.push({ name: "Checkout" });
            localStorage.setItem("cinf", null);
            this.getClientInfo();
            this.$emit("refresh")
             //this.refreshInfo();
          }
        });
    },
    removeItem() {
      this.$router.push({ name: "Remove" });
    },
    cashBalancing() {
      this.$router.push({ name: "cashBalancing" });
    },
    save_credit(e) {
      this.client.id = e.cId;

      this.save_sale({
        recived: e.startCash,
        remainCash: e.remainCash,
        paymentMethod: e.paymentMethod,
        limitDate: e.limitDate,
        status: e.status,
      });
    },
    searchClient(e) {
      this.barcode = e.cid;
      this.searchItem();
    },
    clearCheckout() {
      this.$swal
        .fire({
          title: "¿Deseas continuar?",
          text: `Se eliminarán ${this.prods.length} producto(s) de caja`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          cancelButtonText: "No",
          confirmButtonText: "Cancelar venta",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.http.get(`${this.url}/clearCheckout`).then((res) => {
              if (res.data.status) this.getItems();
              localStorage.setItem("cinf", null);
              this.getClientInfo();
              this.barcode = "";
            });
          }
        });
    },
    leftCheckout() {
      if (this.prods.length > 0) {
        this.$swal
          .fire({
            title: "Hay productos en caja",
            text: `Termine de facturar los productos actuales o cancele la venta`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            cancelButtonText: "Continuar",
            confirmButtonText: "Cancelar venta y salir",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.http.get(`${this.url}/clearCheckout`).then(() => {
                this.$router.push({ name: "Home" });
                localStorage.setItem("cinf", null);
              });
            } else if (result.dimissed) {
              return false;
            }
          });
      } else if (this.prods.length == 0) {
        this.$router.push("/home");
      }
    },
  },
};
</script>
