<template>
    <div>
        <span>Modificar contraseña</span>
        <form onsubmit='return false' class='form'>
            <div class="form__cols form__cols--cards">

                <div class="form__control">
                    <label class="form__label" for="">Actual</label>
                    <input placeholder="..." v-model="oldPass" :autocomplete="false" type="password" class="input">
                </div>
                <div class="form__control">
                    <label class="form__label" for="">Nueva contraseña</label>
                    <input placeholder="..." v-model="newPass" :autocomplete="false" type="password" class="input">
                </div>
                <button class="btn btn-blue  " @click="changePass">Guardar
                    <LoadSpin :color="'#FFF'" v-if="saving" :size="'20px'" />
                </button>
            </div>


        </form>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
    name: "ChangePass",
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    data() {
        return {
            oldPass: "",
            newPass: "",
            saving:false
        }
    },
    methods:{
       async changePass() {
            this.saving = true
           await this.http.post(`${this.url}/changePass`,
            {
                params:{
                    oldPass:this.oldPass,
                    newPass:this.newPass
                }
            }).then((res) => {
               // console.log(res.data);
                this.saving = false
                if(res.data.status == true)  this.toast.success("La contraseña ha sido modificada. ", this.toastOptions);
                if(res.data == false)  this.toast.danger("La contraseña anterior es inválida", this.toastOptions);
              
            }).catch(( ) => {
                this.saving = false
                this.toast.info("Ha ocurrido un error al realizar la operación", this.toastOptions);



            })
        },
    
    }
}
</script>

 