<template>
    <div class="returns">
        <div class="control_nav">
            <div class="checkout__title">
                <h2>Devoluciones</h2>
            </div>

            <div class="control_nav_options">
               
                <button class="btn  btn-outline-blue btn--exit" @click="leftReturns">
                    <font-awesome-icon :icon="['fa', 'arrow-left']" />   Salir
                </button>
            </div>
            <!-- control_nav_options -->
        </div>

        <div class="return__container">

            <DataTable id="datatables" :data="products" :columns="this.returnCols" :options="this.dtOptions"
                class="display">
            </DataTable>


        </div>
    </div>
</template>

<script>
export default {
    name: "ReturnedItems",

    data() {
        return {
            products: [],


        };
    },

    methods: {
        getReturnedItems() {
            this.http.get(`${this.url}/getReturned`).then((res) => {
                this.products = res.data;


            });
        },
        leftReturns(){
            this.$router.push({name:"Home"})
        }


    },
    mounted() {

        this.getReturnedItems()
        document.addEventListener("click", (e) => {
            var target = e.target
            target.classList.contains("viewFact")?this.$router.push({name:"ViewInvoice",params:{id:target.dataset.fid}}):""
            target.classList.contains("viewProd")
        })
    }
}
</script>

 