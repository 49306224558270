<template>
  <div class="home">
    <nav class="top_nav">
      <div class="top_nav__user">
    
        <div class="businessInfo">
          <img :src="`${this.staticUrl}/uploads/${logo}`" alt="">
          <div> {{  this.businessName }}</div>
          <!-- <div> {{ this.$store.state.business.bi.business_name }}</div> -->
        </div>
      </div>
     <!--  <div class="top_nav__date">{{ time }}</div> -->
     <span>{{ name}}</span>
     <!-- <span>{{ this.$store.state.user.name }} {{ this.$store.state.user.lastName }}</span> -->
      <div class="notification">Notificación</div>
    </nav>

    <div class="panelContainer">
      <div class="panelInfo">Panel de funciones y herramientas</div>

      <div class="widget_container">
        <h3 class="widget_container_title">Funciones</h3>

        <div class="widgets">
          <div class="widget" @click="this.$router.push({ name: 'Checkout' })">
            <img src="../icons/checkout.png" class="widget_icon" alt="" />
            <span>Vender</span>
          </div>

          <div class="widget" @click="this.$router.push({ name: 'General' })">
            <img src="../icons/inventario.png" class="widget_icon" alt="" />
            <span>Inventario</span>
          </div>

          <div class="widget" @click="this.$router.push({ name: 'Invoices' })">
            <img src="../icons/cuenta.png" class="widget_icon" alt="" />
            <span>Facturas</span>
          </div>

          <div class="widget" @click="this.$router.push({ name: 'Suppliers' })">
            <img src="../icons/repartidor.png" class="widget_icon" alt="" />
            <span>Suplidores</span>
          </div>

          <div class="widget" @click="this.$router.push({ name: 'Returns' })">
            <img src="../icons/exchange.png" class="widget_icon" alt="" />
            <span>Devoluciones</span>
          </div>
          <div class="widget" @click="this.$router.push({ name: 'ClientInfo' })">
            <img src="../icons/client.png" class="widget_icon" alt="" />
            <span>Clientes</span>
          </div>
          <div class="widget" @click="this.$router.push({ name: 'Sold' })">
            <img src="../icons/report.png" class="widget_icon" alt="" />
            <span>Reporte de ventas</span>
          </div>

        </div>
      </div>
      <!-- widget_container -->
      <div class="widget_container">
        <h3 class="widget_container_title">Herramientas</h3>

        <div class="widgets">
          <!--         <div class="widget">
            <img src="../icons/catalogo.png" class="widget_icon" alt="" />
            <span>Catálogo online</span>
          </div>
 -->
          <!--  <div class="widget">
            <img src="../icons/website.png" class="widget_icon" alt="" />
            <span>Tienda online</span>
          </div> -->
          <!--     <div class="widget">
            <img src="../icons/comprobante.png" class="widget_icon" alt="" />
            <span>Comprobante fiscal</span>
          </div> -->
          <!--  <div class="widget">
            <img src="../icons/reporte-de-negocios.png" class="widget_icon" alt="" />
            <span>Reportes</span>
          </div> -->
          <div class="widget" @click="this.$router.push({ name: 'Config' })">
            <img src="../icons/configuraciones.png" class="widget_icon" />
            <span>Configuración</span>
          </div>
          <!--     <div class="widget">
            <img src="../icons/soporte-tecnico.png" class="widget_icon" alt="" />
            <span>Soporte</span>
          </div> -->
          <div class="widget" @click="logout">
            <img src="../icons/cerrar-sesion.png" class="widget_icon" alt="" />
            <span>Cerrar sesión</span>
          </div>
        </div>
      </div>
      <!-- widget_container -->
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
  name: "HomePage",
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  data() {
    return {
      d: "",
      m: "",
      y: "",
      h: "",
      mn: "",
      s: "",
      time: "",
      name:"",
      businessName:"",
      logo:"",
 
    }

  },
  methods: {
    getHour() {
      var date = new Date();
      this.d = date.getDate()
      this.m = date.getMonth()
      this.y = date.getFullYear()
      this.h = date.getHours()
      this.mn = date.getMinutes()
      this.s = date.getSeconds()
      return this.d + "/" + (this.m + 1) + "/" + this.y + "    " + `${this.h > 13 ? this.h - 12 : this.h}` + ":" + `${this.mn < 10 ? `0${this.mn}` : this.mn}` + ":" + `${this.s < 10 ? `0${this.s}` : this.s}`
    }
    , logout() {
      this.Cookies.set("dspuid", null)
 
      localStorage.setItem('chkSt', null)
      localStorage.clear()
      this.$router.push({ name: 'Login' })
    },
    checkBusinessInfo() {
      this.http.get(`${this.url}/checkBusinessInfo`,).then((res) => {

        if (res.data == false) {
          this.$router.push({ name: 'Start' })
        }

      }).catch(() => {
        //console.log(e);
        this.toast.error("Hemos detectado un error, por favor intente iniciar sesión nuevamente.", this.toastOptions);
 

      })
    },
    getUserInfo() {
      //this.http.defaults.headers.common['Authorization'] = this.Cookies.get("dspuid");
      this.http.get(`${this.url}/getUserInfo`,).then((res) => {
        //console.log(res);
        var info = res.data 
        this.name = info.name + " " + info.lastName
        this.businessName = info.business_name
        this.logo = info.photoURL
       /*  if (res.data == false) {
           
        } */

      }).catch(() => {
         ///console.log(e);
        this.toast.error("Hemos detectado un error, por favor intente iniciar sesión nuevamente.", this.toastOptions);


      })
    }
  },
  mounted() {
    this. getUserInfo() 
    //this.checkBusinessInfo()
   // this.time = this.getHour()
  /*   setInterval(() => {
      this.time = this.getHour()
    }, 1000) */

 
    if (this.Cookies.get("dspuid") == 'null') {
      this.$router.push({ name: "Login" })
   
    }

  }
};
</script>
 

 