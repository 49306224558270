<template>
    <div class="modal">
        <OverLay />

        <div class="paymethod">
            <button @click="this.$router.push({ name: 'Checkout' })" :disabled="this.processing" class="close btn">
                Cerrar
            </button>
            <h2>Factura a crédito</h2>
            <div class="cashBalancing">

                <div class="sold__info">
                    <div class="client_info">
                        <span>Cliente: </span>
                        <p>{{ `${cName} ${cLastName}` }}</p>
                    </div>
                    <div class="info__block">


                        <div class="form__control">
                            <label for="valueF">Efectivo inicial</label>
                            <div class="inputAddons">
                                <span>
                                    <font-awesome-icon :icon="['fas', 'dollar-sign']" />
                                </span>

                                <input type="text" class="input" @keyup="cal_remain" v-model="startCash" placeholder="$0"
                                    name="valueF" />
                            </div>
                        </div>

                        <div class="form__control">
                            <label for="transferType">Forma de pago</label>
                            <div class="inputAddons">
                                <span>
                                    <font-awesome-icon :icon="['fas', 'right-left']" />
                                </span>

                                <select v-model="paymentMethod" name="transferType">
                                    <option value="0">Ninguna</option>
                                    <option value="1">Efectivo</option>
                                    <option value="2">Tarjeta</option>
                                    <option value="3">Transferencia bancaria</option>
                                </select>
                            </div>
                        </div>

                        <div class="form__control">
                            <label for="valueF">Efectivo restante</label>
                            <div class="inputAddons">
                                <span>
                                    <font-awesome-icon :icon="['fas', 'dollar-sign']" />
                                </span>

                                <input type="text" class="input" v-model="total" placeholder="$0" name="valueF" />
                            </div>
                        </div>

                        <div class="form__control">
                            <label for="">Límite de pago</label>
                            <div class="inputAddons">
                                <span>
                                    <font-awesome-icon :icon="['fas', 'calendar-days']" />
                                </span>

                                <DatePicker placeholder="dd-mm-yyyy" :format="this.dateFormater" :enable-time-picker="false" v-model="limitDate" />
                            </div>
                        </div>


                    </div>



                </div>

                <div class="cashBalancing_controls">
                    <button class="btn process" :disabled="this.processing" @click="save_credit">
                        Guardar
                        <LoadSpin v-if="processing" :color="'#FFF'" :size="'30px'" />
                    </button>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { useToast } from "vue-toastification";
import OverLay from "../overlay";

export default {
    name: "PayMethod",
    components: {
        OverLay,
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    data() {
        return {
            processing: false,
            startCash: "",
            remainCash: this.$router.options.history.state.total,
            total: "",
            paymentMethod:"0",
            limitDate: "",
            cName: "",
            cLastName: "",
            cId: "",
        };
    },
    beforeMount() {
        var cinf = JSON.parse(localStorage.getItem("cinf"));
        if (cinf != null) {
            this.cName = cinf.name
            this.cLastName = cinf.lastname
            this.cId = cinf.id
        } else {
            this.$swal
                .fire({
                    title: "Esta factura no tiene un cliente agregado",
                    text: `Qué le gustaría hacer?`,
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "#3085d6",
                    cancelButtonText: "Buscar cliente",
                    confirmButtonText: "Crear cliente",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$router.push({ name: 'NewClientCheckout' })

                    }

                    if (result.isDismissed) {
                        this.$router.push({ name: 'Checkout' })
                    }
                });
        }

        this.cal_remain()


    },

    methods: {
    /* se encarga de emitir el evento para guardar la información de crédito del cliente al componente padre */
        save_credit() {
            this.$emit("save_credit", {
                startCash: this.startCash?this.startCash:0,
                remainCash: this.total,
                limitDate: this.limitDate,
                paymentMethod: this.paymentMethod,
                cId: this.cId,
                status:2,
            })
        },
        cal_remain() {
            this.total = this.remainCash - this.startCash
        }
    },
};
</script>
  
   