<template>
  <div class="invoice">
    <router-view v-slot="{ Component }">
      <transition name="slide-right">
        <component :is="Component" @processReturn="saveReturn" />
      </transition>
    </router-view>
    <div class="control_nav">
      <div class="checkout__title">
        <h2>Factura </h2> <small>{{ fact_id }}</small>
      </div>

      <div class="control_nav_options">


        <button v-if="returnAction" class="btn btn-outline-red btn--exit" @click="removeSelection">
          Quitar selección
        </button>

        <button class="btn bg-blue-light btn--exit" @click="this.$router.push({ name: 'Invoices' })">
          Salir
        </button>
      </div>
      <!-- control_nav_options -->
    </div>
    <div class="invoice__info">
      <div class="invoice__header">
        <div class="invoice__data">
          <h4>Número:</h4>
          <span>{{ this.info.fact_id }}</span>
        </div>
        <div class="invoice__data">
          <h4>Vendido por:</h4>
          <span>{{ this.info.seller }}</span>
        </div>
        <div class="invoice__data">
          <h4>Fecha:</h4>
          <span>{{ this.info.date }}</span>
        </div>
      </div>
      <!-- invoice__header -->

      <div class="invoice_body_container">
        <div class="invoice__body">
          <div class="invoice__item">
            <span class="name">Código</span>
            <span class="name">Producto</span>

            <span class="value">Precio</span>
            <span class="unds">Unidades</span>

            <span class="unds">itbis</span>
            <span class="desc">Descuento</span>
            <span class="desc">Total</span>
            <span class="desc">Devolver</span>
          </div>
          <div class="invoice__item" v-for="item in items" v-bind:key="item.id">
            <span class="name">{{ item.barcode }}</span>
            <span class="name">{{ item.prod_name }}</span>

            <span class="value">{{ this.currency(item.sold_price) }}</span>

            <span class="unds">{{ item.unds }}</span>
            <span class="unds">{{ this.currency(item.itbis) }}</span>
            <span class="desc">{{ item.discount }}%</span>
            <span class="desc">{{ this.currency((item.sold_price * item.unds) - (item.sold_price * item.unds) *
              (item.discount / 100)) }}</span>
            <span><input type="radio" class="chooseItem" :data-barcode="item.barcode" @change="prepareReturn"
                name="return"></span>
          </div>
        </div>

        <form onsubmit='return false' class='form'>
          <div class="invoice__doubt">
            <h2>Deuda de la factura</h2>
            <div class="invoice__doubt__info">
              <div class="info">
                <h4>Total</h4>
                <span> {{ currency(info.total) }}</span>
              </div>
              <div class="info">
                <h4>Pagado</h4>
                <span>{{ currency(pendient.amount_paid) }}</span>
              </div>
              <div class="info">
                <h4>Restante</h4>
                <span>{{ currency(info.total - pendient.amount_paid) }}</span>
              </div>
            </div>


            <div class="form__control">
              <label for="valueF">Aplicar pago</label>
              <div class="inputAddons">
                <span>
                  <font-awesome-icon :icon="['fas', 'dollar-sign']" />
                </span>

                <input type="text" class="input" v-model="amount" placeholder="$0" id="valueF" />
              </div>
            </div>
            <div class="form__control">
              <label for="transferType">Forma de pago</label>
              <div class="inputAddons">
                <span>
                  <font-awesome-icon :icon="['fas', 'right-left']" />
                </span>

                <select v-model="paymentType" id="transferType">

                  <option value="1">Efectivo</option>
                  <option value="2">Tarjeta</option>
                  <option value="3">Transferencia bancaria</option>
                </select>
              </div>
            </div>
            <button class="btn" @click="applyPayment">Aplicar pago</button>
          </div>
        </form>

      </div>

      <!-- invoice__body -->
      <div class="invoice__footer">
        <div class="invoice__data">
          <h4>Valor total</h4>
          <span> {{ this.currency(this.info.total) }}</span>
        </div>
        <div class="invoice__data">
          <h4>Articulos totales</h4>
          <span>{{ this.items.length }}</span>
        </div>
        <div class="invoice__data">
          <h4>Método de pago en venta</h4>
          <span>{{ this.payment_method }}</span>
        </div>
        <div class="invoice__data">
          <h4>Estado</h4>
          <span class="invoice_status"
            :class="[{ 'inv_paid': info.status == 1 }, { 'inv_pendient': info.status == 2 }, { 'inv_expired': info.status == 3 }]">{{
              this.status }}</span>
        </div>
      </div>
      <!-- Invoices footer -->

      <div class="invoice__controls">
        <button class="btn">
          <font-awesome-icon :icon="['fa', 'print']" /> Imprimir
        </button>
        <button v-if="returnAction" @click="completeReturn" class="btn">
          <font-awesome-icon :icon="['fa', 'rotate-left']" /> Devolución
        </button>
        <button class="btn">
          Más información
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
  name: "ViewInvoice",
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  data() {
    return {
      fact_id: (this.fact_id = this.$router.currentRoute._value.params.id),
      info: [],
      items: [],
      client_info: {},
      pendient: {},
      payment_method: "",
      status: "",
      amount: "",
      paymentType: "1",
      returnAction: false,
      returnItem: "",

    };
  },
  beforeMount() {
    this.getItems();

  },

  methods: {
    getItems() {
      this.http.get(`${this.url}/getItems/${this.fact_id}`).then((res) => {

        this.items = res.data.items;
        this.info = res.data.info;
        this.client_info = res.data.client
        this.pendient = res.data.pendient
        switch (this.info.payment_method) {
          case "0":
            this.payment_method = "Sin pago inicial";

            break;
          case "1":
            this.payment_method = "Efectivo";

            break;
          case "2":
            this.payment_method = "Tarjeta ";
            break;
          case "3":
            this.payment_method = "Transferencia";
            break;
        }

        switch (this.info.status) {
          case "1":
            this.status = "Pagada";
            break;
          case "2":
            this.status = "Pendiente ";
            break;
          case "3":
            this.status = "Anulada";
            break;
          case "4":
            this.status = "Vencida";
            break;
        }
      });
    },

    applyPayment() {
      this.http.post(`${this.url}/payInvoice`, {

        fact_id: this.fact_id,
        value: eval(this.pendient.value - this.amount),
        totalValue: this.info.total,
        totalPaid: eval(parseFloat(this.pendient.amount_paid) + parseFloat(this.amount)),
        amount_paid: this.amount,
        payment_type: this.paymentType,
        limitDate: this.pendient.limitDate,
        cid: this.client_info.id,

      },{
          headers:{
              'Refresh-state':'true'
          }

        }).then((res) => {
          this.$emit("refresh")
        var state = res.data
        if (state.applied.status == true && state.soldout == false) {
          this.toast.success("Págo aplicado con éxito", this.toastOptions);
        } else {
          this.toast.success("El pago fue aplicado con éxito y la deuda fue saldada", this.toastOptions);
        }
        this.amount = ""
        this.getItems()
      });
    },
    prepareReturn(e) {
      this.returnItem = e.target.dataset.barcode;
      this.returnAction = true

    },
    completeReturn() {
      this.$router.push({ name: "ReturnItem", params: { barcode: this.returnItem } })
    },
    saveReturn(e) {

      this.$swal({
        title: "Está a punto de realizar un retorno a este producto",
        text: "¿Desea continuar?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
      }).then((result) => {
        if (result.isConfirmed) {

          this.http.post(`${this.url}/saveReturn`, {
            params: {
              fact_id: e.fact_id,
              barcode: e.barcode,
              reason: e.reason,
              state: e.state,
              unds: e.unds,
            }
          },{
          headers:{
              'Refresh-state':'true'
          }

        }).then((res) => {

            // console.log(res.data);
            if (res.data.status) {
              this.toast.success("El producto ha sido retornado", this.toastOptions);
              this.$router.push({ name: 'ViewInvoice', params: { id: e.fact_id } })
            }
            this.getItems()
          });
        }
      });

    },
    removeSelection() {
      var radio = document.querySelectorAll(".chooseItem")
      radio.forEach(r => {
        r.checked = false;

      });

      this.returnAction = false

    },
    goBack() {
      this.$router.push({ name: "Invoices" });
    },
  },
};
</script>

<style></style>