<template>
  <div class="RegisterNew">
    <div class="modalNav">
      <h1>{{ title }}</h1>

      <div class="modalControl">
        <button
          v-if="!registerNew"
          class="btn bg-red-light"
          @click="deleteProd"
        >
          Eliminar producto
        </button>

        <button @click="this.$router.go(-1)" class="btn btn-outline-blue">
          <font-awesome-icon :icon="['fas', 'arrow-left-long']" /> Regresar
        </button>
      </div>
    </div>

    <form class="form" @submit.prevent="onSubmit" id="prodActions">
      <div class="prodInfo">
        <div class="form__cols l-ll">
          <div class="form__control">
            <label for="">Código del producto</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fas', 'barcode']" />
              </span>
              <input
                type="text"
                v-model="barcode"
                placeholder="..."
                class="form__input"
                :disabled="addUnds"
              />
            </div>
          </div>
          <div class="form__control">
            <label for="">Nombre del Producto</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fas', 'boxes-packing']" />
              </span>
              <input
                type="text"
                v-model="prodName"
                placeholder="..."
                class="form__input"
                :disabled="addUnds"
              />
            </div>
          </div>
          <div class="form__control">
            <label for="">Tipo item</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fa', 'box']" />
              </span>
              <select v-model="itemType">
                <option value="" disabled>Seleccione un elemento</option>
                <option value="1">Producto</option>
                <option value="2">Comida preparada</option>
              </select>
            </div>
          </div>
        </div>

        <div class="form__cols l-l-l-l">
          <div class="form__control" v-if="itemType == 1">
            <label for="">Precio de compra</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fa', 'tag']" />
              </span>
              <input
                type="number"
                v-model="buyPrice"
                placeholder="RD $"
                class="form__input"
                :disabled="addUnds"
              />
            </div>
          </div>
          <div class="form__control">
            <label for="">Precio de venta</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fa', 'tag']" />
              </span>
              <input
                type="number"
                v-model="sellPrice"
                placeholder="RD $"
                class="form__input"
                :disabled="addUnds"
              />
            </div>
          </div>
          <div class="form__control" v-if="itemType == 1">
            <label for="">Precio por mayor</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fa', 'tags']" />
              </span>
              <input
                type="number"
                v-model="wholesale_price"
                placeholder="RD $"
                class="form__input"
                :disabled="addUnds"
              />
            </div>
          </div>
          <div class="form__control" v-if="itemType == 1">
            <label for="">itbis</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fa', 'percent']" />
              </span>
              <input
                type="number"
                v-model="itbis"
                placeholder="RD $"
                class="form__input"
                :disabled="addUnds"
                @focus="$event.target.select()"
              />
            </div>
          </div>
        </div>

        <div class="form__cols l-l-l-l" v-if="itemType == 1">
          <SupplierSelect
            v-if="!edition"
            :productSupplier="true"
            @change="selected"
          />
          <div class="form__control">
            <label for="">Tipo de entrada</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fa', 'box']" />
              </span>
              <select v-model="entranceType" :disabled="addUnds">
                <option disabled vaddUnds>Seleccione un elemento</option>
                <option value="1">Unidades</option>
                <option value="2">Fardo</option>
                <option value="3">Saco</option>
                <option value="4">Caja</option>
              </select>
            </div>
          </div>

          <div class="form__control" v-if="entranceType != '1'">
            <label for="">{{ getEntranceType() }}</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon
                  v-if="entranceType == '3'"
                  :icon="['fas', 'weight-scale']"
                />
                <font-awesome-icon v-else :icon="['fa', 'box']" />
              </span>
              <input
                type="number"
                v-model.number="wholesale"
                placeholder="..."
                class="form__input"
                :disabled="addUnds"
              />
            </div>
          </div>
          <div class="form__control" v-if="entranceType != '1'">
            <label for="">Cantidad de {{ entranceLabel }} </label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fas', 'boxes-stacked']" />
              </span>
              <input
                type="number"
                @keyup="calWholesaleCant"
                v-model.number="cantWholesale"
                placeholder="..."
                class="form__input"
              />
            </div>
          </div>
          <div class="form__control">
            <label for="">{{ undsLeft }}</label>
            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fas', 'boxes-stacked']" />
              </span>
              <input
                type="number"
                v-model.number="unds"
                placeholder="..."
                class="form__input"
                :disabled="addUnds == true && entranceType != '1'"
              />
            </div>
          </div>
        </div>

        <div class="form__control">
          <label for="">Descripción</label>
          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fas', 'signature']" />
            </span>
            <input
              type="text"
              v-model="description"
              placeholder="..."
              class="form__input"
              :disabled="addUnds"
            />
          </div>
        </div>

        <div class="form__cols expire" v-if="itemType == 1">
          <div class="form__control">
            <label for="">¿El producto expira?</label>
            <div class="inputAddons">
              <input
                type="checkbox"
                v-model="expire"
                placeholder="..."
                class="form__input"
              />
              <p v-if="expire">Si</p>
              <p v-else>No</p>
            </div>
          </div>

          <div class="form__control expire_option" v-if="expire">
            <label for="">Fecha de expiración</label>

            <div class="inputAddons">
              <span>
                <font-awesome-icon :icon="['fas', 'calendar-days']" />
              </span>

              <DatePicker
                locale="do"
                placeholder="dd-mm-yyyy"
                :enable-time-picker="false"
                v-model="expire_date"
              />
            </div>
          </div>
        </div>
      </div>
      <!--   <div class="prodImages">
        
        <h2 v-if="meta!='edit' ">Agregar {{ imagesInfo.length > 0 ? imagesInfo.length : "" }} imagen{{ imagesInfo.length > 1 ? "es" : "" }}
         
        </h2>
        <h2 class="titles"> <LoadSpin v-if="loading"   :color="'#000'" :size="'30px'" />   Imagenes </h2>
        <input type="file" @change="takeImage" name="uploadprodImg" id="uploadprodImg">
        <label class="addImg btn btn-outline-blue " for="uploadprodImg"><font-awesome-icon :icon="['fa', 'image']" />
          Agregar imagen</label>
      
        <div class="imgs__container">
        
          <div class="img_preview" v-for='(img, index) in meta!="edit"?imagesInfo:uploadedImages' :key="img.id">

            <img   :src="img.url" class="img_prov">
          
   
            
            <span  class="deletePreview" :data-id="index" @click="deleteImg">Eliminar</span>
          </div>



        </div>
      </div> -->
    </form>

    <div class="actions_btn">
      <button
        v-if="registerNew"
        class="btn btn-blue-light"
        @click="addNewProduct"
      >
        <LoadSpin
          v-if="saving"
          :disabled="saving"
          :color="'#FFF'"
          :size="'30px'"
        />
        {{ saveBtnTxt }}
      </button>

      <button
        v-if="!registerNew && addUnds == false"
        class="btn btn-blue-light"
        @click="applyChanges"
      >
        Guardar cambios
      </button>

      <button
        v-if="!descontinued && !registerNew && addUnds == false"
        class="btn bg-orange-light"
        @click="descontinue"
      >
        Descontinuar
      </button>
      <button
        v-if="!registerNew && descontinued && addUnds == false"
        class="btn bg-green-light"
        @click="returnToInventary"
      >
        Mostrar en el inventario
      </button>
      <button
        v-if="!registerNew && !descontinued && unds > 0 && addUnds == true"
        class="btn bg-green-light"
        @click="addNewUnds"
      >
        Agregar {{ unds }} al inventario
      </button>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
  name: "prodsActions",
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  data() {
    return {
      barcode: "",
      prodName: "",
      description: "",
      buyPrice: 0,
      sellPrice: 0,
      wholesale_price: 0, //precio por mayor
      wholesale: 0, //Indica la cantidad de unidades de las entradas por lotes
      cantWholesale: 0,
      entranceType: "1",
      entranceLabel: "fardos",
      unds: 0,
      totalWholesale: 0,
      itbis: 18,
      suppliersList: [],
      supplier: "Sin suplidor",
      imagesInfo: [],
      uploads: [],
      uploadedImages: [],
      loading: false,
      id: "",
      title: "Editar producto",
      edition: false,
      addUnds: false,
      registerNew: false,
      viewSpecial: false,
      undsLeft: "Unidades nuevas",
      meta: this.$router.currentRoute.value.meta.action, //ese meta se encuentra registrado en las rutas del router
      expire: false,
      expire_date: "",
      descontinued: false,
      business: 1,
      product: true,
      itemType: 1,
      saving: false,
      saveBtnTxt: "Agregar producto",
      format: (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `Expira el: ${day}/${month}/${year}`;
      },
    };
  },

  async mounted() {
    //Tipo de negocio
    this.business = localStorage.getItem("btp");

    this.setAction();
    if (this.meta == "addUnds") {
      this.addUnds = true;
      this.getProdInfo();
    }
    if (this.meta == "edit") {
      this.editing = true;
      this.getProdInfo();
    }
  },
  watch: {
    wholesale(newVal, oldVal) {
      if (oldVal != 0) this.unds = this.cantWholesale * this.wholesale;
      //console.log(newVal, oldVal);
    },
    cantWholesale(newVal, oldVal) {
      if (oldVal != 0) this.unds = this.cantWholesale * this.wholesale;
      // console.log(newVal, oldVal);
    },

    unds(newVal) {
      this.unds = newVal;
    },
  },
  methods: {
    setAction() {
      //adapta la vista al tipo de acción que se va a realizar

      switch (this.meta) {
        case "registerNew":
          this.title = "Registrar producto";
          this.registerNew = true;
          // this.unds = this.cantWholesale ;

          break;
        case "addUnds":
          this.title = "Añadir nuevas unidades";
          this.edition = true;
          this.addUnds = true;
          // this.unds = this.cantWholesale ;

          break;
        case "edit":
          this.undsLeft = "Unidades actuales";
          break;
      }
    },
    /* Funciones para agregar productos */

    async newProductInfo() {
      this.saving = true;
      if (this.imagesInfo.length > 0) {
        for (let index = 0; index < this.imagesInfo.length; index++) {
          const img = this.imagesInfo[index].file;
          this.saveBtnTxt = `Subiendo imagen ${index + 1}...`;

          var imgInfo = await this.uploadFiles(img);

          var toUpload = {
            id: index,
            name: imgInfo.metadata.name,
          };
          this.uploads.push(toUpload);
          console.log(this.uploads);
        }
      }

      this.saveBtnTxt = "Guardando producto...";
      this.http
        .post(
          `${this.url}/newProduct`,
          {
            params: {
              barcode: this.barcode,
              prodName: this.prodName,
              description: this.description,
              buyPrice: this.buyPrice,
              sellPrice: this.sellPrice,
              wholesale_price: this.wholesale_price,
              wholesale: this.wholesale,
              lotCant: this.cantWholesale,
              entranceType: this.entranceType,
              unds: this.unds,
              itbis: this.itbis,
              supplier: this.supplier,
              expire: this.expire,
              expire_date: this.expire_date,
              itemType: this.itemType,

              images: JSON.stringify(this.uploads),
            },
          },
          {
            headers: {
              "Refresh-state": "true",
            },
          }
        )

        .then((res) => {
          //console.log(res);
          if (res.data.status == true) {
            this.saveBtnTxt = "Agregar producto";
            this.saving = false;
            this.toast.success("Producto insertado", this.toastOptions);
            this.barcode = "";
            this.prodName = "";
            this.description = "";
            this.buyPrice = "";
            this.sellPrice = "";
            this.wholesale_price = "";
            this.wholesale = "";
            this.cantWholesale = "";
            this.entranceType = "";
            this.unds = "";
            this.supplier = "";
            this.refresh();
          }
          if (res.data.error[1] == 1062) {
            this.toast.info(
              "Este código de barras ya existe",
              this.toastOptions
            );
          }
          this.$emit("refresh");
        });
    },

    addNewProduct() {
      //Validaciones
      if (this.barcode != "" && this.prodName != "") {
        if (this.item == 1 && this.unds == 0) {
          this.toast.info(
            "Debes agregar al menos una unidad",
            this.toastOptions
          );
        } else {
          this.newProductInfo();
        }
      } else {
        this.toast.info(
          "Los campos: código de barras y nombre del producto, son requeridos.",
          this.toastOptions
        );
      }
    },
    deleteProd() {
      var id = this.$router.currentRoute.value.params.id[0];

      this.$swal({
        title: "Seguro que desea eliminar el producto?",
        text: " ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.http
            .get(`${this.url}/deleteItem/${id}`, {
              headers: {
                "Refresh-state": "true",
              },
            })
            .then((res) => {
              this.$emit("refresh");
              if (res.data.status == true) {
                // this.$router.push({ name: "All" });
                this.toast.info(
                  `El producto con el código ${this.barcode} fue eliminado del inventario`,
                  this.toastOptions
                );
              }
            });
        }
      });
    },

    /* Funciones para editar productos  */
    getProdInfo() {
      //carga la lista de suplidores
      this.http.get(`${this.url}/getSuppliers`).then((res) => {
        this.suppliersList = res.data;
      });

      var id = this.$router.currentRoute._value.params.id; //Lee los parametros de la ruta actual
      this.id = id[0];
      this.loading = true;
      this.http.get(`${this.url}/getProductInfo/${id}`).then(async (res) => {
        this.barcode = res.data.barcode;
        this.prodName = res.data.name;
        this.description = res.data.description;
        this.buyPrice = res.data.buyPrice;
        this.sellPrice = res.data.sellPrice;
        this.wholesale_price = res.data.wholesale_price;
        this.wholesale = res.data.wholesale;
        this.cantWholesale = res.data.lotCant;
        this.entranceType = res.data.entranceType;
        this.unds = this.meta == "addUnds" ? 0 : res.data.unds;
        this.itbis = res.data.itbis;
        this.suppliersList = res.data.suppliersList;
        this.supplier = res.data.supplier;
        this.expire_date = res.data.expire_date;
        this.expire = res.data.expire == 1 ? true : false;
        this.descontinued = res.data.descontinued == 1 ? true : false;
        this.itemType = res.data.product;
        this.setAction();
        this.imagesInfo = JSON.parse(res.data.images);
        /*     
        for (let index = 0; index < this.imagesInfo.length; index++) {
          
          this.uploadedImages.push({
              id:index,
              url:await this.loadFiles( this.imagesInfo[index].name)
          })
        }
        this.loading = false */
      });
    },

    applyChanges() {
      switch (this.meta) {
        case "addUnds":
          this.addNewUnds();
          break;
        case "edit":
          this.editProduct();
          break;
      }
      this.$emit("refresh");
    },
    //es la funcion que se encarga de guardar los datos editados
    save() {
      this.http
        .post(
          `${this.url}/editItem`,
          {
            params: {
              barcode: this.barcode,
              prodName: this.prodName,
              description: this.description,
              buyPrice: this.buyPrice,
              sellPrice: this.sellPrice,
              wholesale_price: this.wholesale_price,
              wholesale: this.wholesale,
              lotCant: this.cantWholesale,
              entranceType: this.entranceType,
              unds: this.unds,
              itbis: this.itbis,
              // suppliersList: this.suppliersLis,
              supplier: this.supplier,
              expire: this.expire,
              expire_date: this.expire_date,
              id: this.id,
              images: JSON.stringify(this.imagesInfo),
            },
          },
          {
            headers: {
              "Refresh-state": "true",
            },
          }
        )

        .then((res) => {
          console.log(res.data);
          if (res.data.status == true) {
            this.toast.success("Se ha editado el producto", this.toastOptions);
            //this.$router.go(-1);
            localStorage.setItem("spId", "");
          }

          if (res.data.error[1] == 1062) {
            this.toast.info(
              "Este código de barras ya está registrado",
              this.toastOptions
            );
          }
        });
    },
    descontinue() {
      this.$swal("¿Seguro", "que quiere descontinuar el producto?", "info");
      this.$swal({
        title: "Realmente quiere descontinuar el producto?",
        text: "Esto lo eliminará de los especiales (si es el caso), y el valor del inventario disminuirá.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, descontinuar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.http
            .post(
              `${this.url}/decontinueProd`,
              {
                params: {
                  id: this.id,
                },
              },
              {
                headers: {
                  "Refresh-state": "true",
                },
              }
            )

            .then((res) => {
              if (res.data.status == true) {
                this.toast.info(
                  "Se ha descontinuado el producto",
                  this.toastOptions
                );
                //this.$router.push({ name: "Discontinued" });
                localStorage.setItem("spId", "");
                this.descontinued = true;
              }
              this.$emit("refresh");
            });
        }
      });
    },
    returnToInventary() {
      this.http
        .post(
          `${this.url}/returnToInventary`,
          {
            params: {
              id: this.id,
            },
          },
          {
            headers: {
              "Refresh-state": "true",
            },
          }
        )

        .then((res) => {
          if (res.data.status == true) {
            this.toast.success(
              "El producto se ha vuelto a mostrar en el inventario",
              this.toastOptions
            );
            //this.$router.push({ name: "All" });
            this.descontinued = false;
          }
          this.$emit("refresh");
        });
    },
    addNewUnds() {
      //console.log(this.unds);
      this.http
        .post(
          `${this.url}/addUnds`,
          {
            params: {
              unds: this.unds,
              id: this.id
            },
          },
          {
            headers: {
              "Refresh-state": "true",
            },
          }
        )

        .then((res) => {
          console.log(res);
          if (res.data.status == true) {
            this.toast.success(
              `Se han agregado ${this.unds} nuevas unidades`,
              this.toastOptions
            );
            // this.$router.go(-1);
            localStorage.setItem("spId", "");
          }
          this.$emit("refresh");
        });
    },
    editProduct() {
      if (this.barcode != "" && this.prodName != "") {
        if (this.meta != "edit" && this.unds == 0) {
          this.toast.info(
            "Debes agregar al menos una unidad",
            this.toastOptions
          );
        } else {
          this.save();
        }
      } else {
        this.toast.info(
          "Los campos: código de barras y nombre del producto, son requeridos.",
          this.toastOptions
        );
      }

      this.$emit("refresh");
    },
    /* Este es una funcion simple que sirve para cambiar el texto del siguiente label 
    al momento de seleccionar un tipo de entrada */
    getEntranceType() {
      var type;
      /*    if(this.entranceType != "1"){
    
      } */
      switch (this.entranceType) {
        case "1":
          type = "Unidades";
          break;
        case "2":
          type = "Paquetes del Fardo";

          break;
        case "3":
          type = "Libras del Saco";
          this.entranceLabel = "sacos";
          break;
        case "4":
          type = "Unidades de la caja";
          this.entranceLabel = "cajas";
          break;
      }
      return type;
    },
    selected(s) {
      this.supplier = s.target.value;
    },
    calWholesaleCant() {
      this.unds = this.wholesale * this.cantWholesale;
    },

    deleteImg(e) {
      if (e.target.classList.contains("deletePreview")) {
        var id = e.target.dataset.id;
        /* console.log(this.imagesInfo); */
        this.imagesInfo.splice(id, 1);
        /*  console.log(this.imagesInfo); */
      }
    },

    uploadImg(img) {
      this.uploadFiles(img);
    },

    previewImg(file, id) {
      if (file) {
        var reader = new FileReader();
        reader.onload = (e) => {
          var img_info = {
            url: e.target.result,
            file: file.target.files[0].name,
            id,
          };

          if (this.meta == "edit") {
            this.uploadedImages.push(img_info);
            console.log(this.uploadedImages);
          } else {
            this.imagesInfo.push(img_info);
          }

          console.log(this.imagesInfo);
        };
      }
      reader.readAsDataURL(file.target.files[0]);
    },
    takeImage(e) {
      this.previewImg(e, this.imagesInfo.length);
    },
  },
};
</script>
