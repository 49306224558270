<template>
  <div class="RegisterNew">
    <div class="modalNav">
      <h1>Editar información del suplidor</h1>

      <div class="modalControl">
   
        <button
          v-if="goBack"
          @click="this.$router.push({ name: 'Suppliers' })"
          class="btn btn-outline-blue"
        >
          Ver
          suplidores
        </button>

        <button @click="this.$router.go(-1)" class="btn btn-outline-blue">
          <font-awesome-icon :icon="['fas', 'arrow-left-long']" /> Regresar
          {{ goBack }}
        </button>
      </div>
    </div>
    <h3>{{ name + " " + lastName }}</h3>
    <form class="form" @submit.prevent="onSubmit">
      <div class="form__cols l-l-l">
        <div class="form__control">
          <label for="">Nombre</label>

          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fas', 'signature']" />
            </span>
            <input
              type="text"
              v-model="name"
              placeholder="..."
              class="form__input"
            />
          </div>
        </div>
        <!-- end form__control -->

        <div class="form__control">
          <label for="">Apellido</label>

          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fas', 'signature']" />
            </span>
            <input
              type="text"
              v-model="lastName"
              placeholder="..."
              class="form__input"
            />
          </div>
        </div>
        <!-- end form__control -->

        <div class="form__control">
          <label for="">Número de contacto</label>

          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fas', 'mobile']" />
            </span>
            <input
              type="tel"
              v-model="contact"
              placeholder="xxx-xxx-xxxx"
              class="form__input"
            />
          </div>
        </div>
        <!-- end form__control -->
      </div>
      <!-- end form__cols -->

      <div class="form__cols l-l-l">
        <div class="form__control">
          <label for="">Nombre de la empresa</label>

          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fa', 'building']" />
            </span>
            <input
              type="text"
              v-model="company"
              placeholder="..."
              class="form__input"
            />
          </div>
        </div>
        <!-- end form__control -->
        <div class="form__control">
          <label for="">Ubicación</label>

          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fas', 'location-dot']" />
            </span>
            <input
              type="text"
              v-model="location"
              placeholder="..."
              class="form__input"
            />
          </div>
        </div>
        <!-- end form__control -->
        <div class="form__control">
          <label for="">Correo Electrónico</label>

          <div class="inputAddons">
            <span>
              <font-awesome-icon :icon="['fa', 'envelope']" />
            </span>
            <input
              type="email"
              v-model="email"
              placeholder="example@mail.com"
              class="form__input"
            />
          </div>
        </div>
        <!-- end form__control -->
      </div>
      <!-- end form__cols -->
      <div class="btn_controls">
        <button class="btn btn-red" @click="deleteSupplier">Eliminar</button>
        <button class="btn btn-blue" @click="save">
          <font-awesome-icon :icon="['fa', 'floppy-disk']" /> Guardar cambios
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
  name: "EditSupplier",
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  data() {
    return {
      name: "",
      lastName: "",
      contact: "",
      company: "",
      location: "",
      email: "",
      id: "",
      meta: this.$router.options.history.state,
      goBack: "",
    };
  },
  beforeMount() {
    var id = this.$router.currentRoute._value.params.id;
    this.goBack = this.meta.goBack;
    this.http.get(`${this.url}/getSupplierInfo/${id}`).then((res) => {
      this.id = id;
      this.name = res.data.name;
      this.lastName = res.data.lastName;
      this.contact = res.data.contact;
      this.company = res.data.company;
      this.location = res.data.location;
      this.email = res.data.email;
    });
  },
  methods: {
    editSupplier() {
      this.http
        .post(`${this.url}/editSupplier`, {
          params: {
            name: this.name,
            lastName: this.lastName,
            contact: this.contact,
            company: this.company,
            location: this.location,
            email: this.email,
            id: this.id,
          },
        },{
          headers:{
              'Refresh-state':'true'
          }

        })

        .then((res) => {
          console.log(res.data);
          if (res.data.error[1] == 1062) {
            this.toast.info("Este correo ya existe", this.toastOptions);
          }

          if (res.data.status == true) {
            this.toast.success(
              "La información del suplidor ha sido editada",
              this.toastOptions
            );
            this.name = "";
            this.lastName = "";
            this.contact = "";
            this.company = "";
            this.location = "";
            this.email = "";
            this.$router.push("/suppliers");
          }
        });
    },
    save() {
      if (this.name != "" && this.company != "") {
        this.editSupplier();
      } else {
        this.toast.info(
          "Los campos: Nombre y empresa, son requeridos.",
          this.toastOptions
        );
      }
    },
    deleteSupplier() {
      this.$swal
        .fire({
          title: "Se eliminará el suplidor",
          text: "Seguro que quiere eliminarlo?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminarlo",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.http.get(`${this.url}/deleteSupplier/${this.id}`,{
          headers:{
              'Refresh-state':'true'
          }

        }).then(() => {
              this.$router.push("/suppliers");
              this.toast.success(
                "El suplidor ha sido eliminado",
                this.toastOptions
              );
            });
          }
        });
    },
  },
};
</script>

 
 