<template>
  <div class="policies">

    <h1>Términos y condiciones de uso</h1>

    <p class="highlight">Última actualización: July 2023</p>

    <p>Agradecemos que utilices nuestro sistema. En esta Política de Privacidad, explicamos cómo recopilamos, utilizamos y
      protegemos la información personal que recabamos a través de nuestro sistema. Al utilizar nuestro sistema, aceptas
      los términos de esta Política de Privacidad.</p>

    <h4>1. Información que Recopilamos</h4>

    <p>Cuando te registras o utilizas nuestro sistema, podemos recopilar la siguiente información personal:</p>

    1.1. Datos de Registro: Nombre, dirección de correo electrónico, contraseña, información de contacto y otros datos
    necesarios para crear y mantener tu cuenta.

    1.2. Datos de Facturación: Información de pago, como datos de tarjeta de crédito, para procesar los pagos relacionados
    con nuestros servicios.

    1.3. Datos de Uso: Información sobre cómo utilizas nuestro sistema, incluidas las acciones realizadas, las funciones
    utilizadas, la interacción con el contenido y otra información relacionada con el uso de nuestro sistema.

    1.4. Datos de Dispositivos: Información sobre el dispositivo que utilizas para acceder a nuestro sistema, como la
    dirección IP, el tipo de dispositivo, el sistema operativo y la configuración del dispositivo.

    1.5. Datos de Ubicación: Información sobre la ubicación de tu dispositivo, si has dado permiso para acceder a la
    ubicación.
    <h4> 2. Uso de la Información:</h4>
    <p>Utilizamos la información personal recopilada para los siguientes propósitos:</p>

    2.1. Prestación del Servicio: Proporcionar y mantener nuestro sistema, procesar transacciones y enviar información
    relacionada con tu cuenta.

    2.2. Mejora del Servicio: Analizar cómo utilizas nuestro sistema para mejorar la experiencia del usuario, personalizar
    el contenido y desarrollar nuevas funciones.

    2.3. Comunicación: Enviarte comunicaciones relacionadas con el servicio, actualizaciones, alertas de seguridad y otros
    mensajes importantes.

    2.4. Cumplimiento Legal: Cumplir con nuestras obligaciones legales y responder a solicitudes legales y
    gubernamentales.

    2.5. Marketing: Enviarte información sobre nuestros servicios, promociones y noticias, cuando hayas dado tu
    consentimiento para recibir dichas comunicaciones.

    <h4> 3. Seguridad de los Datos:</h4>
    <p> Nos comprometemos a proteger tus datos personales y mantener su confidencialidad. Implementamos medidas de
      seguridad
      para proteger tu información contra el acceso no autorizado, la divulgación o alteración no autorizada. Sin embargo,
      es importante tener en cuenta que ninguna transmisión por Internet o almacenamiento electrónico es completamente
      segura. Por lo tanto, aunque nos esforzamos por proteger tus datos, no podemos garantizar la seguridad absoluta de
      la
      información transmitida o almacenada en nuestro sistema.</p>

    <h4> 4. Costo del Servicio:</h4>
    <p> El costo de nuestro sistema es fijo y se establece claramente en nuestros términos y condiciones. Algunas de las
      condiciones relacionadas con el costo del servicio son las siguientes:</p>

    4.1. Prueba Gratuita:
    Ofrecemos una prueba gratuita de nuestro sistema durante un período específico. Para acceder a la prueba gratuita, te
    solicitamos que proporciones un método de pago válido. Si no cancelas la prueba antes de que finalice el período de
    prueba, se realizará un cargo en el método de pago utilizado al final de la prueba, y la membresía se convertirá en
    una suscripción de pago recurrente.

    4.2. Suscripciones:
    Ofrecemos diferentes tipos de suscripciones a nuestro sistema con características y precios variados. Si decides
    cambiar tu suscripción o agregar funciones adicionales a tu membresía actual, es posible que los precios varíen. Sin
    embargo, cualquier cambio en el costo se te informará con anticipación y no afectará el costo actual de tu membresía
    hasta que el cambio entre en vigencia.

    4.3. Cargos no Reembolsables:
    Los cargos realizados por el uso de nuestro sistema, incluidos los cargos por la prueba gratuita y las suscripciones,
    son no reembolsables. Una vez realizado el cargo, no se realizarán reembolsos, a menos que se indique lo contrario en
    nuestros términos y condiciones.

    <h4> 5. Almacenamiento en la Nube:</h4>
    <p> Tus datos se almacenan en la nube en servidores seguros proporcionados por un proveedor de servicios confiable. El
      almacenamiento en la nube se refiere al uso de servidores en línea para almacenar y administrar datos en lugar de
      almacenarlos localmente en tu dispositivo. Utilizamos el almacenamiento en la nube para garantizar la accesibilidad
      y
      seguridad de tus datos desde cualquier ubicación.</p>

    <h4> 6. Requerimientos de Conexión a Internet:</h4>
    <p> Para garantizar el correcto funcionamiento y acceso a nuestro sistema, es necesario contar con una conexión a
      Internet
      estable. Sin una conexión a Internet, es posible que no puedas acceder o utilizar todas las funciones y
      características de nuestro sistema.</p>

    <h4> 7. Uso de Cookies y Tecnologías Similares:</h4>
    <p>Utilizamos cookies y tecnologías similares para mejorar la experiencia del usuario, personalizar el contenido,
      analizar el uso del sistema y proporcionar publicidad personalizada. Puedes controlar el uso de cookies a través de
      la
      configuración de tu navegador. Consulta nuestra Política de Cookies para obtener más información.</p>

    <h4> 8. Compartir Información con Terceros:</h4>
    <p> No vendemos ni compartimos tu información personal con terceros con fines de marketing directo. Podemos compartir
      información con proveedores de servicios de confianza que nos ayudan a operar nuestro sistema y brindar servicios.
      Nos
      aseguramos de que estos proveedores cumplan con los estándares adecuados de protección de datos.</p>

    <h4>9. Derechos del Usuario:</h4>
    <p> Tienes ciertos derechos sobre tus datos personales, que incluyen el acceso, rectificación, eliminación,
      restricción y
      portabilidad de tus datos. Si deseas ejercer estos derechos o tienes alguna pregunta sobre nuestra Política de
      Privacidad, contáctanos a través de los canales proporcionados en nuestro sistema.</p>

    <h4> 10. Cambios en la Política de Privacidad:</h4>
    <p> Nos reservamos el derecho de modificar o actualizar esta Política de Privacidad en cualquier momento. Cualquier
      cambio
      será efectivo desde el momento en que se publiquen en nuestro sistema. Te recomendamos revisar periódicamente esta
      Política de Privacidad para estar al tanto de las actualizaciones.</p>

    <h4>11. Menores de Edad:</h4>
    <p> Nuestro sistema no está dirigido a menores de edad. No recopilamos intencionalmente información personal de niños
      menores de 18 años. Si eres padre o tutor y crees que tu hijo ha proporcionado información personal en nuestro
      sistema, contáctanos y eliminaremos la información de manera adecuada y oportuna.</p>


    ...

    <p> Gracias por confiar en nosotros y utilizar nuestro sistema.</p>

  Atentamente,
  El Equipo de Dotsell Solutions
</div></template>

<script>
export default {
  name: "TermsConditions"
}
</script>

 