<template>
    <div class="modal">
        <OverLay @click="goBack" />
        <div class="clientInfo modal_data">
            <h2>{{ title }}</h2>
            <button @click="goBack" class="close btn">
                Cerrar
            </button>

            <div class="form__control">
                <label for="fidelityCard">Tarjeta de fidelidad</label>
                <div class="inputAddons">
                    <span>
                        <font-awesome-icon :icon="['fas', 'i-cursor']" />
                    </span>

                    <input type="text" class="input" v-model="fidelityCard" placeholder="...." name="fidelityCard" />
                </div>
            </div>


            <div class="infoContainer">
                <div class="form_cols">
                    <div class="form__control">
                        <label for="cName">Contacto</label>
                        <div class="inputAddons">
                            <span>
                                <font-awesome-icon :icon="['fas', 'i-cursor']" />
                            </span>

                            <input type="text" class="input" v-model="cName" placeholder="...." name="cName" />
                        </div>
                    </div>
                    <div class="form__control">
                        <label for="clName">Apellido(s)</label>
                        <div class="inputAddons">
                            <span>
                                <font-awesome-icon :icon="['fas', 'i-cursor']" />
                            </span>

                            <input type="text" class="input" v-model="clName" placeholder="...." name="clName" />
                        </div>
                    </div>
                </div>
                <div class="form_cols">
                    <div class="form__control">
                        <label for="cId">Cédula o pasaporte</label>
                        <div class="inputAddons">
                            <span>
                                <font-awesome-icon :icon="['fas', 'address-card']" />
                            </span>

                            <input type="text" class="input" v-model="cId" placeholder="...." name="cId" />
                        </div>
                    </div>
                    <div class="form__control">
                        <label for="cBirthdate">Fecha de nacimiento</label>
                        <div class="inputAddons">
                            <span>
                                <font-awesome-icon :icon="['fa', 'calendar-days']" />
                            </span>

                            <DatePicker name="cBirthdate" placeholder="dd-mm-yyyy" :enable-time-picker="false"
                                v-model="cBirthdate" :teleport="true" position="left" />
                        </div>
                    </div>


                </div>
                <div class="form_cols">

                    <div class="form__control">
                        <label for="cPhone">Contacto</label>
                        <div class="inputAddons">
                            <span>
                                <font-awesome-icon :icon="['fas', 'phone']" />
                            </span>

                            <input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" class="input" v-model="cPhone"
                                placeholder="...." name="cPhone" />
                        </div>
                    </div>
                    <div class="form__control">
                        <label for="cDir">Dirección</label>
                        <div class="inputAddons">
                            <span>
                                <font-awesome-icon :icon="['fas', 'location-dot']" />
                            </span>

                            <input type="text" class="input" v-model="cDir" placeholder="...." name="cDir" />
                        </div>
                    </div>
                </div>
                <div class="controls">
                    <button class="btn" @click="action">Guardar información
                        <LoadSpin v-if="saving" :color="'#FFF'" :size="'25px'" />
                    </button>
                    <button v-if="editMode" class="btn btn-red delete" @click="deleteClient">Eliminar cliente
                        <LoadSpin v-if="saving" :color="'#FFF'" :size="'25px'" />
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>
  
<script>
import { useToast } from "vue-toastification";
export default {
    name: "AddInfo",
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    data() {
        return {
            cName: '',
            clName: '',
            cId: '',
            cBirthdate: '',
            cPhone: '',
            cDir: '',
            id: '',
            fidelityCard: '',
            saving: false,
            editMode: false,
            title: "Información del cliente",
            setAction:this.$router.currentRoute.value.meta.action


        };
    },

    beforeMount() {
        var cid = this.$router.currentRoute.value.params.cid;
        /* it is used to check if in route is the cid params for execute the method below */
        if (cid != undefined) {
            this.cId = cid;
            this.editMode = true
            this.getInfo()
        }

        if (this.editMode) {
            this.title = "Editar información"
        }

       

    },
  
        
    methods: {

        save() {
            if (this.cName == '' ||
                this.clName == '' ||
                this.cId == '' ||
                this.cBirthdate == '' ||
                this.cPhone == '' ||
                this.cDir == '' ||
                this.fidelityCard == '') {
                this.toast.info('Todos los campos son requeridos', this.toastOptions)
                return false
            }
            this.saving = true
            this.http.post(`${this.url}/newClient`, {
                params: {
                    cName: this.cName,
                    clName: this.clName,
                    cId: this.cId,
                    cBirthdate: this.cBirthdate,
                    cDir: this.cDir,
                    cPhone: this.cPhone,
                    fidelityCard: this.fidelityCard
                }
            }).then((res) => {
                this.saving = false
                //console.log(res);
                this.goBack()
                if (res.data.status == true) this.toast.success("El cliente ha sido registrado", this.toastOptions);
                if (res.data.error[1] == 1062) this.toast.info("Este cliente ya existe registrado", this.toastOptions);
                this.$emit("clientSaved",{cid:this.cId})
                this.$emit("refresh")
            })
        },
        edit() {
            if (this.cName == '' ||
                this.clName == '' ||
                this.cId == '' ||
                this.cBirthdate == '' ||
                this.cPhone == '' ||
                this.cDir == '' ||
                this.fidelityCard == '') {
                this.toast.info('Todos los campos son requeridos', this.toastOptions)
                return false
            }
            this.saving = true
            this.http.post(`${this.url}/editClient`, {
                params: {
                    cName: this.cName,
                    clName: this.clName,
                    cId: this.cId,
                    cBirthdate: this.cBirthdate,
                    cDir: this.cDir,
                    cPhone: this.cPhone,
                    id: this.id,
                    fidelityCard: this.fidelityCard
                }
            }).then((res) => {
                this.saving = false
                this.editMode = false;
                //console.log(res);
                this.goBack()
                if (res.data.status == true) this.toast.success("La información ha sido editada", this.toastOptions);
                this.$emit("save") //when execution ends, emit an event to ClientsInfo.vue to execute refresh client method

            })
        },
        action() {
            switch (this.editMode) {
                case true: this.edit(); break;
                case false: this.save(); break;
            }
        },
        deleteClient() {
            this.saving = true

            this.$swal({
                title: 'Deseas continuar?',
                text: "Se eliminará el cliente del sistema",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Eliminar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.http.get(`${this.url}/deleteClient/${this.id}`,
                    ).then((res) => {
                        this.saving = false
                        this.editMode = false;
                       console.log(res);
                        this.goBack()
                        if (res.data.status == true) this.toast.success("El cliente fue eliminado del sistema", this.toastOptions);
                        this.$emit("save") //when execution ends, emit an event to ClientsInfo.vue to execute refresh client method

                    })
                }
            })


        },
        getInfo() {
            this.http.get(`${this.url}/getClientInfo/${this.cId}`, {
            }).then((res) => {
                // console.log(res.data.id);
                this.cName = res.data.name
                this.clName = res.data.lastname
                this.cId = res.data.cid
                this.cBirthdate = res.data.birthdate
                this.cPhone = res.data.cel
                this.cDir = res.data.location
                this.fidelityCard = res.data.fidelitycard
                this.id = res.data.id

            })

        },
        goBack() {
          
            switch(this.setAction){
                case "viewFromInvoices": this.$router.push({ name: "Invoices" });break;
                case "viewFromClients": this.$router.push({ name: "ClientInfo" });break;
                case "viewFromCheckout": this.$router.push({ name: "Checkout" });break;
            }
           
        },
    },
};
</script>
  
<style></style>