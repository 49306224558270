<template>
  <div class="RegisterNew modal">
      <div class="modalNav"><h2>Registrar salida</h2>
        <div class="modalControl  ">
           <router-link to="/register">
            <button class="btn btn-outline-blue  ">Cerrar</button>
           </router-link>
        </div>
      </div>
    <form class="form" @submit.prevent="onSubmit">
      <div class="form__control">
        <label for="">Nombre del registro</label>
        <input type="text" placeholder="..." class="form__input" />
      </div>
      <div class="form__control">
        <label for="">Producto extraido</label>
        <select class="form__input" name="" id="">
          <option value="">Value</option>
          <option value="">Value</option>
          <option value="">Value</option>
        </select>
      </div>
      <div class="form__control">
        <label for="">Cantidad extraida</label>
        <input type="number" placeholder="..." class="form__input" />
      </div>
        <button class="btn btn-blue">Guardar</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "RegisterNew",
};
</script>

 
 