<template>
  <div class="col-row generalView">
    <div class="section__nav">
      <div class="section__nav__title">Vista general</div>

      <div class="section__nav__controls">
        <router-link to="/home">
          <button class="btn btn-outline-blue">
            <font-awesome-icon :icon="['fa', 'arrow-left']" />

            Salir del inventario
          </button>
        </router-link>
      </div>
    </div>

    <div class="widgets_container">

      <div class="widget_cols">
        <div class="widget_info   main_widget">
          <div class="widget__title">
            <div class="widget__title__icon">
              <font-awesome-icon :icon="['fas', 'dollar-sign']" />
            </div>

            Valor
          </div>
          <p>Del inventario</p>
          <div class="widget__data">RD$ {{ totalValue }}</div>
          <div class="widget__footer"></div>
        </div>
        <!-- widget_info bg-auto  -->
        <div class="widget_info bg-auto  ">
          <div class="widget__title">
            <div class="widget__title__icon">
              <font-awesome-icon :icon="['fas', 'boxes-stacked']" />
            </div>
            Productos
            <span class="viewMore">Ver más</span>
          </div>
          <div class="widget__data">{{ totalProds }}</div>
          <div class="widget__footer"></div>
        </div>
        <!-- widget_info bg-auto  -->
        <div class="widget_info bg-auto ">
          <div class="widget__title">
            <div class="widget__title__icon">
              <font-awesome-icon :icon="['fas', 'box']" />
            </div>
            Unidades
          </div>
          <div class="widget__data">{{ totalUnds }}</div>
          <div class="widget__footer"></div>
        </div>
        <!-- widget_info bg-auto  -->
        <div class="widget_info bg-auto ">
          <div class="widget__title">
            <div class="widget__title__icon">
              <font-awesome-icon :icon="['fas', 'tags']" />
            </div>
            Ofertas
            <span class="viewMore">Ver más</span>
          </div>
          <div class="widget__data">{{ inspecial }}</div>
          <div class="widget__footer"></div>
        </div>
        <!-- widget_info bg-auto  -->

      </div>

      <div class="widget_cols">
        <div class="widget_divider   ">
          <div class="widget_info bg-auto   bg-red-light">
            <div class="widget__title">
              <div class="widget__title__icon">
                <font-awesome-icon :icon="['fas', 'caret-down']" />
              </div>
              Pocas
              <span class="viewMore">Ver más</span>
            </div>
            <p>Unidades</p>
            <div class="widget__data">{{ lowstock }}</div>
            <div class="widget__footer"></div>
          </div>
          <!-- widget_info bg-auto  -->

          <div class="widget_info bg-auto  bg-green-light">
            <div class="widget__title">
              <div class="widget__title__icon">
                <font-awesome-icon :icon="['fas', 'caret-up']" />
              </div>
              Último
            </div>
            <p>valor agregado</p>
            <div class="widget__data">RD$ {{ lastUndsUpdate }}</div>
            <div class="widget__footer"></div>
          </div>
          <!-- widget_info bg-auto  -->
        </div>
        <!-- widgetd_divider -->

        <div class="widget_info bg-auto ">
          <div class="widget__title">
            <div class="widget__title__icon">
              <font-awesome-icon :icon="['fas', 'truck-ramp-box']" />
            </div>
            Suplidores

            <span @click="
              this.$router.push({
                name: 'Suppliers',

                state: { goBack: 'al panel' },
              })
              " class="viewMore">Ver más</span>
          </div>

          <p>Favoritos</p>
          <div class="widget__data">
            <ul class="fav__supplier__container">
              <li v-for="supplier in suppliers" v-bind:key="supplier.ID">
                <div class="fav__supplier">
                  <span class="fav_icon sm-10">
                    <font-awesome-icon :icon="['fas', 'star']" />
                  </span>
                  <div class="fav__supplier__info sm-80">
                    <div class="fav__supplier__info--name">
                      {{ supplier.name + " " + supplier.lastName }}
                    </div>
                    <div class="fav__supplier__info--company">
                      {{ supplier.company }}
                    </div>
                  </div>

                  <span
                    @click="this.$router.push({ name: 'ViewSupplier', params: { id: supplier.ID }, state: { goBack: 'al panel' } })"
                    class="viewSupplier sm-10"><font-awesome-icon :icon="['fas', 'arrow-right']" />
                  </span>

                </div>
              </li>
              <ul v-if="favCant == 0">
                Sin favoritos
              </ul>
            </ul>
          </div>
          <div class="widget__footer"></div>
        </div>
        <!-- widget_info bg-auto  -->

        <div class="widget_divider ">
          <div class="widget_info bg-auto sm-100 sh-45 bg-dark-light">
            <div class="widget__title">
              <div class="widget__title__icon">
                <font-awesome-icon :icon="['fas', 'burst']" />
              </div>
              Productos
              <span class="viewMore">Ver más</span>
            </div>
            <p>Descontinuados</p>
            <div class="widget__data">{{ descontinued }}</div>
            <div class="widget__footer"></div>
          </div>


          <!-- widget_info bg-auto  -->
          <div class="widget_info bg-auto sm-100 sh-45 bg-orange-light">
            <div class="widget__title">
              <div class="widget__title__icon">
                <font-awesome-icon :icon="['fa', 'clock']" />
              </div>
              Expira
              <span class="viewMore">Ver</span>
            </div>
            <p>Pronto</p>
            <div class="widget__data">{{ expireSoon }}</div>
            <div class="widget__footer"></div>
          </div>
        </div>

      </div>


      <!-- widget_info bg-auto  -->
    </div>
    <!-- widgets_container -->
  </div>
</template>

<script>
export default {
  name: "GeneralInfo",
  data() {
    return {
      totalValue: 0,
      totalUnds: 0,
      totalProds: 0,
      lastUndsUpdate: 0,
      inspecial: 0,
      lowstock: 0,
      suppliers: [],
      descontinued: 0,
      expireSoon: 0,
      favCant: 0,
      series: [{
        name: 'Unidades para esta fecha',
        data: []
      }],


    }; //return
  },

  beforeMount() {

    /* if (localStorage.getItem("refresh") == "true") {
      this.getInventaryInfo();
    } else {
      this.checkPreload();
    } */
    this.checkPreload();



  },

  methods: {
    checkPreload() {

      var info = JSON.parse(localStorage.getItem("preload"))
      var data = info[0].data.info
      console.log(data);

        this.totalValue = data.totalValue;
      this.totalUnds = data.totalUnds;
      this.totalProds = data.totalProds;
      this.lowstock = data.lowStock;
      this.lastUndsUpdate = data.lastUndsUpdate; // obtiene el total del valor de los ultimos articulos insertados
      this.inspecial = data.inspecial;
      this.suppliers = data.supplier;
      this.descontinued = data.descontinued;
      this.expireSoon = data.expireSoon;
      this.favCant = this.suppliers ? this.suppliers.length : 0 

    },
    getInventaryInfo() {

      this.http.get(`${this.url}/getGeneralInfo`).then((res) => {
        // console.log(res.data);
        if (res != undefined) {
          this.totalValue = res.data.totalValue;
          this.totalUnds = res.data.totalUnds;
          this.totalProds = res.data.totalProds;
          this.lowstock = res.data.lowStock;
          this.lastUndsUpdate = res.data.lastUndsUpdate; // obtiene el total del valor de los ultimos articulos insertados
          this.inspecial = res.data.inspecial;
          this.suppliers = res.data.supplier;
          this.descontinued = res.data.descontinued;
          this.expireSoon = res.data.expireSoon;
          this.favCant = this.suppliers ? this.suppliers.length : 0

          //Con esto se extrae la información del gráfico, es transformada a un JSON para guardarla en el LocalStorage.
          /* De está forma es conveniente porque la información se elimina al salir del sistema
          Algo a tomar en cuenta, es que la información se cifra aquí y se descifra  en el hook BeforeMount.
            SCI = state chart info
          
          */

          this.series[0].data = Object.values(res.data.stateChartInfo)

        }


      });
    },
  },
};
</script>
 