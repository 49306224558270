<template>
  <nav id="nav" class="nav">
<!--     <div class="nav__brand">Inventario</div> -->

    <ul class="nav__wrapper">
      <router-link class="nav__link" to="/inventary/new">
        <li class="nav__item nav__item__first">
          <div class="nav__icons">
            <i class="fi fi-tr-books-medical"></i>
          </div>
          Registrar productos
        </li>
      </router-link>

      <router-link class="nav__link" to="/inventary/general">
        <li class="nav__item">
          <div class="nav__icons">
            <i class="fi fi-rr-layout-fluid"></i>

          </div>
          Vista general
        </li>
      </router-link>

      <router-link class="nav__link" :to="{ name: 'All' }">
        <li class="nav__item">
          <div class="nav__icons">
            <i class="fi fi-rr-boxes"></i>
            <!--   <font-awesome-icon :icon="['fas', 'list']" /> -->
          </div>
          Todos los productos
          <span v-if="products" class="badged" id="prodSpecials">{{ products }}</span>
        </li>
      </router-link>

      <router-link class="nav__link" :to="{ name: 'LowStock' }">
        <li class="nav__item">
          <div class="nav__icons">
            <i class="fi fi-rr-arrow-down-small-big"></i>
            <!--   <font-awesome-icon :icon="['fa', 'circle-exclamation']" /> -->
          </div>
          Pocas unidades
          <span v-if="lowStock" class="badged" id="prodSpecials">{{ lowStock }}</span>
        </li>
      </router-link>

      <router-link class="nav__link" :to="{ name: 'Discontinued' }">
        <li class="nav__item">
          <div class="nav__icons">
            <i class="fi fi-rr-nfc-trash"></i>
          </div>
          Descontinuados
          <span v-if="discontinued" class="badged" id="prodSpecials">{{ discontinued }}</span>
        </li>
      </router-link>
      <router-link class="nav__link" :to="{ name: 'ExpireSoon' }">
        <li class="nav__item">
          <div class="nav__icons">
            <i class="fi fi-rr-calendar-day"></i>

          </div>
          Expira pronto
          <span v-if="expire" class="badged" id="prodSpecials">{{ expire }}</span>
        </li>
      </router-link>

      <router-link class="nav__link" :to="{ name: 'Especials' }">
        <li class="nav__item">
          <div class="nav__icons">
            <i class="fi fi-rr-badge-percent"></i>
            <!-- <font-awesome-icon :icon="['fas', 'tags']" /> -->
          </div>
          Especiales
          <span v-if="specials" class="badged" id="prodSpecials">{{ specials }}</span>
        </li>
      </router-link>
    </ul>
  </nav>
</template>

<script>
/* import FontAwesomeIcon from "../../App.vue"; */
export default {
  name: "InventaryNavBar",
  data() {
    return {
      lowStock: "",
      products: "",
      discontinued: "",
      expire: "",
      specials: "",
    };
  },
  methods: {

    goTo() {
      this.route.push("/inventary/new");
    },
    refreshBadges() {
      var info = JSON.parse(localStorage.getItem("preload"));
      if (info) {
        //console.log(info);
        var data = info[0].data
        this.products = data.products.length;
        this.specials = data.specials.length;
        this.lowStock = data.lowStock.length;
        this.discontinued = data.discontinued.length;
        this.expire = data.expireSoon.length;
      }

    }
  },
  mounted() {
    /* this.emitter.on("refresh", ); */
    setInterval(() => {
      this.refreshBadges()
    }, 500)


  },

};
</script>

 