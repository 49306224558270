<template>
  <div class="modal">
    <OverLay @click="goBack" />
    <div class="paymethod">
      <button
        @click="this.$router.push({ name: 'Checkout' })"
        :disabled="this.processing"
        class="close btn"
      >
        Cerrar
      </button>
      <h2>Quitar producto</h2>
      <div class="removeItem">
        <div class="item__info">
          <p>Ingrese el producto que desea quitar</p>
          <div class="search__input">
            <input
              id="barcodeToRemove"
              v-model="barcode"
              @keyup.enter="searchItem"
              @keyup.ctrl.alt.exact="addUnds"
              @keypress.ctrl.alt.exact="addUnds"
               
              autocomplete="off"
              class="input"
              type="text"
              placeholder="A0000001"
            />
            <!--  <input  id="unds" @keyup.enter="searchItem" class="input" type="number"  /> -->
            <button @click="searchItem" class="search bg-blue-light">
              <i class="fi fi-rr-search"></i>
            </button>
          </div>
        </div>

        <div class="item__info">
          <p>Cantidad registrada</p>
          <p>{{ registredQuantity }}</p>
        </div>
        <div class="item__info">
          <p>Ingrese la cantidad a remover</p>
          <input
            type="number"
            @keyup="checkQuantity"
            v-model="quantity"
            id="quantity"
            class="input quantity"
            placeholder="---"
          />
        </div>
        <div class="remove__controls">
          <button class="btn" v-if="registredQuantity > 1 && quantity != registredQuantity"  @click="removeItemUnds">Eliminar solo {{ quantity }}</button>
          <button class="btn btn-red" @click="removeItem" >
            Eliminar {{ registredQuantity > 1?"todo":"" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import OverLay from "../overlay";
export default {
  name: "RemoveItem",
  components: {
    OverLay,
  },
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  data() {
    return {
      paymentMethod: "",
      total: "",
      recived: "",
      change: 0,
      selected: "",
      processing: false,
      procesingLabel: "",
      quantity: "",
      registredQuantity: "0",
      barcode: "",
    };
  },

  

  methods: {
    goBack() {
      this.$router.push("/checkout");
    },
    //Busca el producto que se desea eliminar, verificando a su vez de que esté en caja.
    searchItem() {
      if (this.barcode === "") {
        this.toast.info("Debe ingresar un código de barras", this.toastOptions);

        return false;
      } else {
        this.http
          .get(`${this.url}/getCheckoutProduct/${this.barcode}`)

          .then((res) => {
            //console.log(res.data[0].unds);

            if (res.data == "") {
              this.toast.info(
                `Este producto no se encuentra en caja `,
                this.toastOptions
              );

              this.barcode = "";
            }

            if (res.data) {
              document.getElementById("quantity").focus();
              this.registredQuantity = res.data[0].unds;
            }
          });
      }
    },
    //remueve las cantidades requeridas de un productos
    removeItemUnds() {
      this.http
        .post(`${this.url}/removeItemUnds`, {
          params: {
            barcode: this.barcode,
            unds: this.quantity,
          },
        })

        .then((res) => {
          //console.log(res.data);
          if (res.data.status == true) {
            this.$router.back(-1);
          }
        });
    },
    //Elimina todos los item de un producto
    removeItem() {
      this.http
        .post(`${this.url}/removeItem`, {
          params: {
            barcode: this.barcode,
          },
        })

        .then((res) => {
          //console.log(res.data);
          if (res.data.status == true) {
            this.$router.back(-1);
          }
        });


    },

  },
};
</script>

 