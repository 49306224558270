<template>
  <div class="register__form">
    <div class="clouds__container">
      <div class="banner__img--clouds">
        <img src="../../icons/cloud2.png" alt="" />
      </div>
      <div class="banner__img--clouds">
        <img src="../../icons/cloud2.png" alt="" />
      </div>
      <div class="banner__img--clouds">
        <img src="../../icons/cloud2.png" alt="" />
      </div>
      <div class="banner__img--clouds">
        <img src="../../icons/cloud2.png" alt="" />
      </div>
    </div>
    <div class="banner">
      <span class="banner__text"
        >Dale un toque de productividad a tu negocio</span
      >
    </div>
    <div class="banner__img">
      <img src="../../icons/boost.png" alt="" />
    </div>

    <div class="form__container">
      <div class="brand">
        <img src="../../icons/logo.png" alt="" />
      </div>
      <form onsubmit="return false" class="form">
        <div class="form__control">
          <label class="form__label" for="">Correo electrónico</label>
          <input
            placeholder="example@mail.com"
            v-model="email"
            type="text"
            class="input"
          />
        </div>

        <div class="form__cols">
          <div class="form__control">
            <label class="form__label" for="">Contraseña</label>
            <input
              placeholder="..."
              v-model="password"
              type="password"
              class="input"
            />
          </div>
        </div>

        <div class="form__control">
          <button @click="login" class="btn btn-ui">
            <span v-if="!sending">Iniciar sesión</span>
          </button>
        </div>
        <div class="form__control">
          <button
            @click="this.$router.push({ name: 'Register' })"
            class="btn btn-outline-ui"
          >
            o Crear cuenta
          </button>
        </div>

        <!--   <AuthBanner @successLogin="googleAccess" /> -->
      </form>
    </div>
  </div>
</template>

<script>
//const axios = require('axios').default;
/* import AuthBanner from './AuthBanner.vue' */
import { useToast } from "vue-toastification";
export default {
  name: "LoginForm",
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  data() {
    return {
      email: "",
      password: "",
      sending: false,
    };
  },
  components: {
    /* AuthBanner */
  },

  methods: {
    googleAccess(e) {
      console.log(e);
    },
    checkSessionStatus() {
      if (this.Cookies.get("dspuid") != "null") {
        this.$router.push({ name: "Home" });
      }
    },

    login() {
      this.sending = true;
      this.http
        .post(`${this.url}/signin`, {
          params: {
            email: this.email,
            password: this.password,
          },
        })

        .then((res) => {
          //console.log(res.data);
         

          if (res.data.logged == true) {
            localStorage.setItem("btp", res.data.info.type);
            this.Cookies.set("dspuid", res.data.token);
            this.http.defaults.headers.common['Authorization'] =  res.data.token
            // this.$store.state.token = res.data.token
            /*   this.$store.commit("setToken", res.data.token) */
 

            this.$router.push({ name: "StartSystem" });
            this.sending = false;
          }
          if (res.data == "wa001") {
            this.sending = false;
            this.toast.error(
              "La combinación de usuario y contraseña, es incorrecta",
              this.toastOptions
            );
          }

          if (!res.data) {
            this.sending = false;
            this.toast.info(
              "Lamentablemente, no hemos encontrado ninguna cuenta asociada con esa dirección de correo electrónico en nuestro sistema",
              this.toastOptions
            );
          }
        });
    },
  },
  mounted() {
    this.checkSessionStatus();
  },
};
</script>
