<template>
    <div class="config">
        <div class="control_nav">
            <div class="checkout__title">
                <h2>Configuración </h2>
            </div>

            <div class="control_nav_options">



                <button class="btn btn-outline-blue btn--exit" @click="this.$router.push({ name: 'Home' })">
                    <font-awesome-icon :icon="['fa', 'arrow-left']" />  Salir
                </button>
                <button class="btn btn-outline-blue btn--exit" @click="this.$router.push({ name: 'InvoiceTemplate' })">
                    <font-awesome-icon :icon="['fa', 'arrow-left']" />  Factura
                </button>
            </div>
            <!-- control_nav_options -->
        </div>

        <div class="config__cards">
            <div class="card--config sm-40  ">
                <div class="card__title">
                    <h3>Información del comercio</h3>
                </div>

                <div class="card__body">


                    <div class="card__body__info">
                        <span>Logo</span>
                        <div class="logo__container">

                            <img :src="photoURL?this.staticUrl+'/uploads/'+photoURL:''" class="logoPreview" alt="">
                        </div>

                        <div class="uploadActions">
                            
                                <input type="file" name="upload" id="upload" @change="uploadLogo">
                                <label for="upload" class="btn btn-outline-blue"><font-awesome-icon
                                        :icon="['fas', 'upload']" />
                                    Cargar logo

                                </label>
                                <button v-if="logo" class=" btn btn-blue" :disabled="loading" @click="saveLogo">Guardar
                                    <LoadSpin :color="'#FFF'" v-if="loading" :size="'20px'" />
                                </button>
                                <button v-if="photoURL" class=" btn btn-red"  >Eliminar 
                                    <LoadSpin :color="'#FFF'" v-if="loading" :size="'20px'" />
                                </button>
                           
                        </div>
                    </div>
                    <div class="card__body__info">
                        <span>Negocio</span>
                        <input type="text" v-model="business_name" class="input">
                    </div>
                    <div class="card__body__info">
                        <span>Descripción</span>
                        <input type="text" v-model="description" class="input">

                    </div>
                    <div class="card__body__info">
                        <span>RNC</span>
                        <input type="text" v-model="rnc" class="input">
                    </div>

                    <div class="card__body__info">
                        <span>Contacto</span>
                        <input type="text" v-model="contact" class="input">
                    </div>
                    <div class="card__body__info">
                        <span>Ubicación</span>
                        <input type="text" v-model="location" class="input">

                    </div>
                    <div class="card__body__info">
                        <span>Horario</span>


                        <div class="weekSelectorContainer">
                            <div class="weekSelect">
                                <input type="checkbox" data-index="0" @change="changeSchedule" :checked="schedule[0].open"
                                    :value="schedule[0].value">
                                <label for="">{{ schedule[0].value }}</label>
                            </div>
                            <div class="weekSelect">
                                <input type="checkbox" data-index="1" @change="changeSchedule" :checked="schedule[1].open"
                                    :value="schedule[1].value">
                                <label for="">{{ schedule[1].value }}</label>
                            </div>
                            <div class="weekSelect">
                                <input type="checkbox" data-index="2" @change="changeSchedule" :checked="schedule[2].open"
                                    :value="schedule[2].value">
                                <label for="">{{ schedule[2].value }}</label>
                            </div>
                            <div class="weekSelect">
                                <input type="checkbox" data-index="3" @change="changeSchedule" :checked="schedule[3].open"
                                    :value="schedule[3].value">
                                <label for="">{{ schedule[3].value }}</label>
                            </div>
                            <div class="weekSelect">
                                <input type="checkbox" data-index="4" @change="changeSchedule" :checked="schedule[4].open"
                                    :value="schedule[4].value">
                                <label for="">{{ schedule[4].value }}</label>
                            </div>
                            <div class="weekSelect">
                                <input type="checkbox" data-index="5" @change="changeSchedule" :checked="schedule[5].open"
                                    :value="schedule[5].value">
                                <label for="">{{ schedule[5].value }}</label>
                            </div>
                            <div class="weekSelect">
                                <input type="checkbox" data-index="6" @change="changeSchedule" :checked="schedule[6].open"
                                    :value="schedule[6].value">
                                <label for="">{{ schedule[6].value }}</label>
                            </div>
                        </div>


                        <div class="form__cols l-ll">

                            <div class="form__control">
                                <label for="">Abierto desde:</label>
                                <div class="inputAddons">
                                    <span>
                                        <font-awesome-icon :icon="['fa', 'clock']" />
                                    </span>
                                    <DatePicker locale="do" placeholder="00:00" time-picker disable-time-range-validation
                                        :is24="false" :enable-time-picker="true" v-model="opens" />
                                </div>
                            </div>

                            <div class="form__control">
                                <label for="">Hasta</label>
                                <div class="inputAddons">
                                    <span>
                                        <font-awesome-icon :icon="['fa', 'clock']" />
                                    </span>
                                    <DatePicker locale="do" placeholder="00:00" time-picker disable-time-range-validation
                                        :enable-time-picker="true" v-model="closes" />
                                </div>
                            </div>

                        </div>
                        <button class="btn btn-blue  " @click="changeBusinessInfo">Guardar cambios
                            <LoadSpin :color="'#FFF'" v-if="saving" :size="'20px'" />
                        </button>

                    </div>




                </div>
            </div><!-- card--config -->



            <div class="card--config  sm-30">
                <div class="card__title">
                    <h3>Información personal</h3>
                </div>

                <div class="card__body">

                    <div class="card__body__info">
                        <span>Usuario</span>
                        <span>{{ name }}</span>
                    </div>
                    <div class="card__body__info">
                        <span>Contacto</span>
                        <span>{{ contact }}</span>
                    </div>
                    <div class="card__body__info">
                        <span>Correo electrónico</span>
                        <span>{{ email }}</span>
                    </div>
                    <div class="card__body__info">
                        <ChangePass />

                    </div>
                </div>
            </div><!-- card--config -->



        


            <div class="card--config sm-25 sh-70">
                <div class="card__title">
                    <h3>Sistema</h3>
                </div>

                <div class="card__body">


                    <div class="card__body__info">
                        <span>Tema</span>
                        <select class="input">

                            <option value="1">Claro</option>
                            <option value="2">Oscuro</option>
                            <option value="3">Automático</option>

                        </select>
                    </div>
                    <div class="card__body__info">
                        <span>OS actual</span>
                        <span>{{ platform }}</span>
                    </div>
                    <div class="card__body__info">
                        <span>Ip actual</span>
                        <span>
                            <LoadSpin :color="'#FFF'" v-if="ip == ''" :size="'20px'" />
                            <p v-if="ip != ''">{{ ip }}</p>
                        </span>
                    </div>
                    <div class="card__body__info">
                        <span>Versión</span>
                        <span>1.0</span>
                    </div>

                    <div class="card__body__info">
                        <span><font-awesome-icon :icon="['fas', 'circle-exclamation']" /> Reportar un problema</span>

                    </div>



                </div>
            </div><!-- card--config -->



        </div><!-- config__cards -->


    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import ChangePass from "./ChangePass.vue"

export default {
    name: 'SystemConfig',
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    components: {
        ChangePass
    },
    data() {
        return {
            name: "",
            lastName: "",
            email: "",
            contact: "",
            business_name: "",
            description: "",
            location: "",
            opens: "",
            closes: "",
            rnc: "",
            schedule: {
                0: {
                    value: "lunes",
                    open: true
                },
                1: {
                    value: "martes",
                    open: false
                },
                2: {
                    value: "miercoles",
                    open: false
                },
                3: {
                    value: "jueves",
                    open: false
                },
                4: {
                    value: "viernes",
                    open: false
                },
                5: {
                    value: "sábado",
                    open: false
                },
                6: {
                    value: "domingo",
                    open: false
                },
            },
            saving: false,
            platform: "",
            ip: "",
            loading: false,
            logo: "",
            photoURL:""

        }
    },
    beforeMount() {
        this.platform = window.clientInformation.userAgentData.platform
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                this.ip = data.ip;

            })
            .catch(error => {
                console.error('Error al obtener la dirección IP:', error);
            });
        this.getInfo()
    },
    mounted(){
     /*  this.http.get('http://localhost:8000/test').then(res=>{
        console.log(res);
      }) */
      //this.$emit("test")
    },
    methods: {
        getInfo() {
            this.http.get(`${this.url}/getUserInfo`,).then((res) => {
                var user = res.data
                this.name = user.name
                this.lastName = user.lastName
                this.email = user.email
                this.contact = user.contact
                this.rnc = user.rnc
                this.business_name = user.business_name
                this.description = user.description
                this.location = user.location
                this.schedule = Object.values(JSON.parse(user.schedule))
                this.opens = JSON.parse(user.opens) ? JSON.parse(user.opens) : null
                this.closes = JSON.parse(user.close) ? JSON.parse(user.close) : null
                this.photoURL = user.photoURL
               // console.log(this.photoURL);
                //console.log(Object.values(JSON.parse(user.schedule)));

            }).catch((err) => {
                console.log(err);


            })
        },
        async changeBusinessInfo() {
            this.saving = true
            await this.http.post(`${this.url}/editBusinessInfo`, {
                params: {
                    business_name: this.business_name,
                    description: this.description,
                    location: this.location,
                    contact: this.contact,
                    rnc:this.rnc,
                    opens: JSON.stringify(this.opens),
                    closes: JSON.stringify(this.closes),
                    schedule: JSON.stringify(this.schedule),
                }
            }).then((res) => {
                //console.log(res.data);
                this.saving = false

                if (res.data == true) this.toast.success("La información ha sido modificada.", this.toastOptions);
                if (res.data == false) this.toast.error("La contraseña actual no es correcta.", this.toastOptions);
            }).catch(() => {
                this.saving = false
                this.toast.info("Ha ocurrido un error al realizar la operación", this.toastOptions);



            })
        },

        changeSchedule(e) {
            var index = e.target.dataset.index
            this.schedule[index].open = e.target.checked ? true : false

        },
        async uploadLogo(e) {
            
            this.logo =e.target.files[0] 
            var reader = new FileReader()
            reader.onload = e => {

                document.querySelector(".logoPreview").src = e.currentTarget.result
                
              //  console.log(this.logo);

            }

/* 
            reader.onprogress = e=>{
                console.log(e);
            } */

            reader.readAsDataURL(e.target.files[0])

        },

        async saveLogo() {
            this.loading = true
            await this.http.post(`${this.staticUrl}/uploadFile`, {
                logo: this.logo
            }, {
                headers: {
                    "Content-type": 'multipart/form-data'
                }
            }).then((res) => {
                
                if(res.data === "OVERSIZE") this.toast.error("El archivo no debe pesar más de 5MB", this.toastOptions);
                if(res.data === "UNSOPPORTED") this.toast.error("Archivo no soportado (Solo PNG, JPEG, JPG)", this.toastOptions);
                if(res.data) {
                    this.toast.success("La imagen ha sido subida correctamente", this.toastOptions)
                   // console.log(res.data);
                    this.setLogo(res.data);
                }
               
            })

        },
        async setLogo(logo) {
            this.loading = true
            await this.http.post(`${this.url}/setLogo`,{
                params:{
                    logo
                }
                
            }).then((res) => {
                this.loading = false
                console.log(res.data);
               
            })

        }

    }
}
</script>
 